import {
  Avatar,
  Box,
  Button,
  Collapse,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Radio,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useOnClickOutside } from 'src/hooks/useOnClickOutside';
import { useTranslation } from 'next-i18next';
import { useUpdateSource } from 'src/queries/useUpdateSource';

import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import {
  Card,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
  StripeElementChangeEvent,
  StripeElementStyle,
} from '@stripe/stripe-js';
import { UseFormGetValues, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { ArrowBackIcon, ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { FormInput } from 'src/components/FormInput';
import { FormSchema } from 'src/hooks/useFormValidation';
import { useStore } from 'src/business/store';
import { useSource } from 'src/queries/useSource';
import { useAddSource } from 'src/queries/useAddSource';
import { CreditCardIcon } from 'src/components/CreditCardIcon';
import { IconStatus } from 'src/components/LoginStatusInput';

type CardInputProps = {
  isEmailReady?: boolean;
  inputError?: string;
  register: UseFormRegister<FormSchema>;
  isStripeOpen: boolean;
  onOpenStripe: () => void;
  setValue: UseFormSetValue<FormSchema>;
  token?: string;
  getValues: UseFormGetValues<FormSchema>;
  watch: UseFormWatch<FormSchema>;
};

type CardStateValues = {
  complete: boolean;
  error?: StripeElementChangeEvent['error'] | null;
};

type CardState = {
  cardNumber: CardStateValues | null;
  cardExpiration: CardStateValues | null;
  cardCvc: CardStateValues | null;
};

export const CardInput = ({
  isEmailReady,
  inputError,
  register,
  onOpenStripe,
  isStripeOpen,
  setValue,
  getValues,
  token,
  watch,
}: CardInputProps) => {
  const elements = useElements();
  const stripe = useStripe();
  const { t } = useTranslation('common');
  const { setState, card_token, shouldRegister, shouldResetCard } = useStore();
  const { data, refetch } = useSource();
  const [addNewPayment, setAddNewPayement] = useState(false);
  const [isFocused, setIsFocused] = useState<string | null>(null);
  const { isOpen, onToggle, onClose } = useDisclosure();
  const cardRef = useRef<any>(null);
  const [addSource, { status }] = useAddSource();
  const [updateSource] = useUpdateSource();
  useOnClickOutside(cardRef, onToggle);
  const { firstname, lastname } = watch('user') ?? {};
  const owner = getValues('card.owner');
  const cardNumberLabel = t('inputs.card_number.label');
  const cardExpirationLabel = t('inputs.card_expiration.label');
  const cardCcvLabel = t('inputs.card_cvv.label');
  const ownerLabel = t('inputs.owner.label');
  const ownerPlaceholder = t('inputs.owner.placeholder');
  const commonCardLabel = t('commons.card');
  const backButtonLabel = t('buttons.back');
  const saveButtonLabel = t('buttons.save');
  const addNewCardLabel = t('commons.add_new_card');

  const [cardState, setCardState] = useState<CardState>({
    cardCvc: null,
    cardExpiration: null,
    cardNumber: null,
  });

  const onCardChange = async (event: StripeCardNumberElementChangeEvent) => {
    setCardState({
      ...cardState,
      cardNumber: {
        complete: false,
        error: null,
      },
    });

    setState({
      paymentStatus: undefined,
    });

    if (event.complete) {
      const cardElement = await elements?.getElement(event.elementType);
      if (cardElement) {
        setCardState({
          ...cardState,
          cardNumber: {
            complete: true,
          },
        });
      }
    }
    if (event.error) {
      setCardState({
        ...cardState,
        cardNumber: {
          complete: false,
          error: event.error,
        },
      });
    }
  };

  useEffect(() => {
    if (shouldResetCard) {
      setState({ card_token: undefined, shouldResetCard: false });
      setValue('card.token', '');
    }
  }, [shouldResetCard]);

  useEffect(() => {
    if (!owner && (addNewPayment || isOpen || isStripeOpen) && firstname && lastname) {
      setValue('card.owner', `${firstname} ${lastname}`);
    }
  }, [firstname, getValues, lastname, owner, setValue, isStripeOpen, addNewPayment, isOpen]);

  useEffect(() => {
    if (!owner && card_token) {
      setValue('card.owner', card_token.card.name);
    }
  }, [card_token, owner, setValue]);

  useEffect(() => {
    async function getToken() {
      if (!shouldRegister) {
        return;
      }
      const cardElement = await elements?.getElement('cardNumber');
      if (cardElement) {
        const response = await stripe?.createToken(cardElement);

        if (response?.token) {
          const card = {
            token_id: response?.token.id,
            id: response?.token?.card?.id,
            is_default: true,
            card: {
              ...response.token.card,
            },
          };

          // @ts-expect-error always defined
          setState({ card_token: card });
          setValue('card.token', JSON.stringify(card));
          setAddNewPayement(false);
        }
      }
    }

    if (
      cardState.cardNumber?.complete &&
      cardState.cardExpiration?.complete &&
      cardState.cardCvc?.complete &&
      (!token || token === '')
    ) {
      getToken();
    }
  }, [cardState, token, shouldRegister]);

  const onPaymentAdd = useCallback(async () => {
    const cardElement = await elements?.getElement('cardNumber');
    if (cardElement) {
      const response = await stripe?.createToken(cardElement);
      if (response?.token) {
        await addSource({
          psp_type: 'stripe',
          card: {
            ...((response?.token?.card ?? {}) as Card),
            name: `${getValues('user.firstname')} ${getValues('user.lastname')}`,
            token_id: response?.token?.id as string,
          },
        });

        const card = {
          token_id: response?.token.id,
          id: response?.token.card?.id,
          card: {
            ...response?.token.card,
            last_4: response?.token?.card?.last4,
          },
        };

        setAddNewPayement(false);
        // @ts-expect-error always defined
        setState({ card_token: card });
        setValue('card.token', JSON.stringify(card));
        refetch?.();
      }
    }
  }, [addSource, elements, getValues, refetch, setState, setValue, stripe]);

  useEffect(() => {
    if (!token && card_token) {
      setValue('card.token', JSON.stringify(card_token), { shouldValidate: true });
    }
  }, [card_token, setValue, token]);

  useEffect(() => {
    if (!card_token && data) {
      setState({
        card_token: data.find((card) => card.is_default),
      });
    }
  }, [card_token, data, setState, setValue]);

  useEffect(() => {
    if (
      (!cardState?.cardCvc?.complete ||
        !cardState?.cardExpiration?.complete ||
        !cardState?.cardNumber?.complete) &&
      token &&
      addNewPayment
    ) {
      setValue('card.token', '');
    }
  }, [cardState, token, addNewPayment]);

  const onCardExpirationChange = async (event: StripeCardExpiryElementChangeEvent) => {
    setCardState({
      ...cardState,
      cardExpiration: {
        complete: false,
        error: null,
      },
    });

    if (event.complete) {
      setCardState({
        ...cardState,
        cardExpiration: {
          complete: true,
        },
      });
    }
    if (event.error) {
      setCardState({
        ...cardState,
        cardExpiration: {
          complete: false,
          error: event.error,
        },
      });
    }
  };

  const onCardCvcChange = async (event: StripeCardCvcElementChangeEvent) => {
    setCardState({
      ...cardState,
      cardCvc: {
        complete: false,
        error: null,
      },
    });

    if (event.complete) {
      setCardState({
        ...cardState,
        cardCvc: {
          complete: true,
        },
      });
    }
    if (event.error) {
      setCardState({
        ...cardState,
        cardCvc: {
          complete: false,
          error: event.error,
        },
      });
    }
  };

  const inputStyle: StripeElementStyle = {
    base: {
      color: 'black',
      fontWeight: '700',
      fontSize: '13px',
      fontFamily: 'inherit',
      fontSmoothing: 'antialiased',
      backgroundColor: 'transparent',
      padding: '90px',
      ':focus': {
        color: 'black',
        fontSize: '13px',
        fontWeight: '700',
        fontFamily: 'inherit',
        fontSmoothing: 'antialiased',
        backgroundColor: 'transparent',
        padding: '20px',
      },
      '::placeholder': {
        color: '#AEBBC9',
      },
    },
    empty: {
      color: 'black',
      fontWeight: '700',
      fontSize: '13px',
      fontFamily: 'inherit',
      fontSmoothing: 'antialiased',
      backgroundColor: 'transparent',
      padding: '20px',
    },
  };

  if (addNewPayment) {
    return (
      <div className="overlay show">
        <Button onClick={() => setAddNewPayement(false)} variant="ghost" position="absolute" top={2} left={2}>
          <ArrowBackIcon />
        </Button>
        <div className="overlay-content">
          <Stack spacing={4} p="2">
            <Box bgColor="white" border="2px solid" borderColor="purple.500" borderRadius="30px">
              <FormControl isInvalid={Boolean(cardState.cardNumber?.error)}>
                <Box bgColor="transparent" display="flex" alignItems="center" p="5" fontWeight="bold">
                  <FormLabel
                    color={
                      isFocused === 'card' && !cardState.cardNumber?.error
                        ? 'purple.500'
                        : cardState.cardNumber?.error
                        ? 'red.500'
                        : undefined
                    }
                    m="0px"
                    width="160px"
                    fontWeight="bold"
                    fontSize="small"
                  >
                    {cardNumberLabel.toUpperCase()}
                  </FormLabel>
                  <Box w="90%">
                    <CardNumberElement
                      onFocus={() => setIsFocused('card')}
                      onBlur={() => setIsFocused(null)}
                      options={{
                        showIcon: true,
                        iconStyle: 'solid',
                        disabled: !isEmailReady,
                        style: inputStyle,
                      }}
                      onChange={onCardChange}
                    />
                  </Box>
                </Box>
                {Boolean(cardState.cardNumber?.error) && (
                  <FormErrorMessage fontWeight="bold" pl="5">
                    {cardState.cardNumber?.error?.message}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormInput
                error={inputError}
                type="text"
                inputRegister={{
                  ...register('card.owner', {
                    disabled: !isEmailReady,
                  }),
                }}
                label={ownerLabel}
                placeholder={ownerPlaceholder}
              />
              <FormControl isInvalid={Boolean(cardState.cardExpiration?.error)}>
                <Box bgColor="transparent" p="5" display="flex" alignItems="center" fontWeight="bold">
                  <FormLabel
                    color={
                      isFocused === 'date' && !cardState.cardExpiration?.error
                        ? 'purple.500'
                        : cardState.cardExpiration?.error
                        ? 'red.500'
                        : undefined
                    }
                    m="0px"
                    width="160px"
                    fontWeight="bold"
                    fontSize="small"
                  >
                    {cardExpirationLabel.toUpperCase()}
                  </FormLabel>
                  <Box w="90%">
                    <CardExpiryElement
                      onFocus={() => setIsFocused('date')}
                      onBlur={() => setIsFocused(null)}
                      onChange={onCardExpirationChange}
                      options={{ disabled: !isEmailReady, style: inputStyle }}
                    />
                  </Box>
                </Box>
                {Boolean(cardState.cardExpiration?.error) && (
                  <FormErrorMessage fontWeight="bold" pl="5">
                    {cardState.cardExpiration?.error?.message}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={Boolean(cardState.cardCvc?.error)}>
                <Box bgColor="transparent" p="5" display="flex" alignItems="center" fontWeight="bold">
                  <FormLabel
                    color={
                      isFocused === 'ccv' && !cardState.cardCvc?.error
                        ? 'purple.500'
                        : cardState.cardCvc?.error
                        ? 'red.500'
                        : undefined
                    }
                    m="0px"
                    width="160px"
                    fontWeight="bold"
                    fontSize="small"
                  >
                    {cardCcvLabel.toUpperCase()}
                  </FormLabel>
                  <Box w="90%">
                    <CardCvcElement
                      onFocus={() => setIsFocused('ccv')}
                      onBlur={() => setIsFocused(null)}
                      onChange={onCardCvcChange}
                      options={{ disabled: !isEmailReady, style: inputStyle }}
                    />
                  </Box>
                </Box>
                {Boolean(cardState.cardCvc?.error) && (
                  <FormErrorMessage fontWeight="bold" pl="5">
                    {cardState.cardCvc?.error?.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>
            <HStack display="flex" wrap="nowrap" spacing={2}>
              <Button
                fontWeight="bold"
                fontSize="small"
                disabled={status === 'loading'}
                isLoading={status === 'loading'}
                bgColor="black"
                textColor="white"
                _hover={{
                  bg: 'blackAlpha.800',
                }}
                borderRadius="20px"
                width="100%"
                onClick={() => setAddNewPayement(false)}
              >
                {backButtonLabel}
              </Button>
              <Button
                fontWeight="bold"
                fontSize="small"
                disabled={status === 'loading'}
                isLoading={status === 'loading'}
                onClick={onPaymentAdd}
                colorScheme="purple"
                width="100%"
                borderRadius="20px"
              >
                {saveButtonLabel}
              </Button>
            </HStack>
          </Stack>
        </div>
      </div>
    );
  }

  if (data && data.length !== undefined) {
    const cardLast4 = `**** **** **** ${card_token?.card?.last_4}`;
    const cardExpiration = `${
      (card_token?.card?.exp_month as number) < 10
        ? `0${card_token?.card?.exp_month}`
        : card_token?.card?.exp_month
    }/${card_token?.card?.exp_year}`;

    const firstCard = data[0];
    const firstCardLast4 = `**** **** **** ${firstCard?.card?.last_4}`;
    const firstCadcardExpiration = `${
      (firstCard?.card?.exp_month as number) < 10
        ? `0${firstCard?.card?.exp_month}`
        : firstCard?.card?.exp_month
    }/${firstCard?.card?.exp_year}`;

    return (
      <Stack
        spacing={2}
        borderRadius={isOpen ? '30px' : undefined}
        border={isOpen ? '2px solid' : undefined}
        borderColor="purple.500"
      >
        {data.length === 0 ? (
          <FormControl>
            <Box p="2">
              <HStack bgColor="white" borderRadius="30px" p="2" onClick={onToggle} display="flex" spacing="2">
                <Box
                  minW={{
                    sm: '120px',
                    md: '150px',
                    base: '120px',
                  }}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text pl="4" fontWeight="bold" fontSize="small">
                    {commonCardLabel.toUpperCase()}
                  </Text>
                </Box>
                <Input
                  cursor="pointer"
                  onClick={() => {
                    onToggle();
                    setAddNewPayement(true);
                  }}
                  fontWeight="bold"
                  fontSize="small"
                  variant="unstyled"
                  textDecoration="underline"
                  textAlign="start"
                  type="button"
                  value={addNewCardLabel}
                />
              </HStack>
            </Box>
          </FormControl>
        ) : (
          <FormControl>
            <Box ref={isOpen ? cardRef : undefined} bgColor="transparent">
              <HStack
                bgColor="transparent"
                borderRadius="30px"
                p="2"
                onClick={!isOpen ? onToggle : undefined}
                display="flex"
                spacing="2"
              >
                <Box
                  minW={{
                    sm: '120px',
                    md: '150px',
                    base: '120px',
                  }}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text pl="4" fontWeight="bold" fontSize="small">
                    {commonCardLabel.toUpperCase()}
                  </Text>
                </Box>
                <HStack
                  display="flex"
                  spacing="4"
                  cursor={isOpen ? 'pointer' : undefined}
                  onClick={() => {
                    if (!isOpen) {
                      return;
                    }
                    if (firstCard.id !== card_token?.id) {
                      const nextCard = data.find((cardFound) => firstCard.id === cardFound.id);
                      setState({
                        card_token: nextCard,
                        paymentStatus: undefined,
                      });
                      setValue('card.token', JSON.stringify(nextCard));
                      updateSource({
                        // @ts-expect-error always defined
                        card_id: nextCard?.id,
                        psp_type: 'stripe',
                      });
                    }
                  }}
                >
                  {isOpen && (
                    <Radio isReadOnly colorScheme="purple" isChecked={firstCard.id === card_token?.id} />
                  )}
                  <CreditCardIcon brand={isOpen ? firstCard.card.brand : card_token?.card.brand} />
                  <Box>
                    <Text fontWeight="bold" fontSize="small">
                      {isOpen ? firstCardLast4 : cardLast4}
                    </Text>
                    <Text fontWeight="bold" fontSize="small">
                      {isOpen ? firstCadcardExpiration : cardExpiration}
                    </Text>
                  </Box>
                </HStack>
                {isOpen ? (
                  <Avatar
                    position="absolute"
                    right="8px"
                    top="6px"
                    size="sm"
                    bg="purple.500"
                    onClick={onClose}
                    icon={<ChevronUpIcon fontSize="2em" color="white" />}
                  />
                ) : (
                  <Avatar
                    position="absolute"
                    right="8px"
                    top="10px"
                    size="sm"
                    bg="purple.500"
                    icon={<ChevronDownIcon fontSize="2em" color="white" />}
                  />
                )}
              </HStack>
              <Collapse in={isOpen} animate animateOpacity unmountOnExit={false}>
                <Box bgColor="white" pl="2" pr="2" borderRadius="30px">
                  <Stack spacing={2}>
                    {data.length > 1 &&
                      data.map((card, index) => {
                        const cardLast4Map = `**** **** **** ${card?.card?.last_4}`;
                        const cardExpirationMap = `${
                          (card?.card?.exp_month as number) < 10
                            ? `0${card?.card?.exp_month}`
                            : card?.card?.exp_month
                        }/${card?.card?.exp_year}`;

                        if (index === 0) return null;

                        return (
                          <Box key={card.id} backgroundColor="transparent">
                            <HStack
                              backgroundColor="transparent"
                              borderRadius="30px"
                              p="2"
                              display="flex"
                              spacing="2"
                              justifyContent={index !== 0 ? 'start' : undefined}
                            >
                              <Box width="110px" />
                              <HStack
                                display="flex"
                                spacing="4"
                                cursor="pointer"
                                onClick={() => {
                                  if (card.id !== card_token?.id) {
                                    const nextCard = data.find((cardFound) => card.id === cardFound.id);
                                    setState({
                                      card_token: nextCard,
                                      paymentStatus: undefined,
                                    });
                                    setValue('card.token', JSON.stringify(nextCard));
                                    updateSource({
                                      // @ts-expect-error always defined
                                      card_id: nextCard?.id,
                                      psp_type: 'stripe',
                                    });
                                  }
                                }}
                              >
                                <Radio
                                  isReadOnly
                                  colorScheme="purple"
                                  isChecked={card.id === card_token?.id}
                                />
                                <CreditCardIcon brand={card?.card.brand} />
                                <Box>
                                  <Text fontWeight="bold" fontSize="small">
                                    {cardLast4Map}
                                  </Text>
                                  <Text fontWeight="bold" fontSize="small">
                                    {cardExpirationMap}
                                  </Text>
                                </Box>
                              </HStack>
                            </HStack>
                          </Box>
                        );
                      })}
                  </Stack>
                  <InputGroup bgColor="white" borderRadius="30px" p="2">
                    <InputLeftAddon
                      fontWeight="bold"
                      fontSize="small"
                      bgColor="transparent"
                      border="none"
                      minW={{
                        sm: '120px',
                        md: '150px',
                        base: '120px',
                      }}
                    />
                    <Input
                      cursor="pointer"
                      onClick={() => {
                        onToggle();
                        setAddNewPayement(true);
                      }}
                      fontWeight="bold"
                      fontSize="small"
                      variant="unstyled"
                      textDecoration="underline"
                      textAlign="start"
                      type="button"
                      value={addNewCardLabel}
                    />
                  </InputGroup>
                </Box>
              </Collapse>
            </Box>
          </FormControl>
        )}
      </Stack>
    );
  }

  return (
    <Stack spacing={2} border={isOpen ? '2px solid' : undefined} borderColor="purple.500">
      <FormControl isInvalid={Boolean(cardState.cardNumber?.error)}>
        <Box bgColor="white" borderRadius="30px" p="5" display="flex" alignItems="center" fontWeight="bold">
          <FormLabel
            color={
              isFocused === 'card' && !cardState.cardNumber?.error
                ? 'purple.500'
                : cardState.cardNumber?.error
                ? 'red.500'
                : undefined
            }
            m="0px"
            width="150px"
            fontWeight="bold"
            fontSize="small"
          >
            {cardNumberLabel.toUpperCase()}
          </FormLabel>
          <Box w="90%">
            <CardNumberElement
              options={{
                showIcon: true,
                iconStyle: 'solid',
                disabled: !isEmailReady,
                style: inputStyle,
              }}
              onBlur={() => setIsFocused(null)}
              onFocus={() => {
                setIsFocused('card');
                if (!isStripeOpen) {
                  onOpenStripe();
                }
              }}
              onChange={(event) => {
                if (event.complete) {
                  setState({ card_token: undefined });
                  setValue('card.token', '');
                }
                onCardChange(event);
              }}
            />
          </Box>
          {card_token && !isStripeOpen ? (
            <Box position="absolute" right="9px">
              <Avatar size="sm" bg="purple.500" icon={<IconStatus status="success" />} />
            </Box>
          ) : null}
        </Box>
        {Boolean(cardState.cardNumber?.error) && (
          <FormErrorMessage fontWeight="bold" pl="5">
            {cardState.cardNumber?.error?.message}
          </FormErrorMessage>
        )}
      </FormControl>
      <Collapse in={isStripeOpen} animate animateOpacity unmountOnExit={false}>
        <Stack spacing={2}>
          <FormInput
            ml="-4px"
            error={inputError}
            type="text"
            inputRegister={{
              ...register('card.owner', {
                disabled: !isEmailReady,
              }),
            }}
            label={ownerLabel}
            placeholder={ownerPlaceholder}
          />
          <FormControl isInvalid={Boolean(cardState.cardExpiration?.error)}>
            <Box
              bgColor="white"
              borderRadius="30px"
              p="5"
              display="flex"
              alignItems="center"
              fontWeight="bold"
            >
              <FormLabel
                color={
                  isFocused === 'date' && !cardState.cardExpiration?.error
                    ? 'purple.500'
                    : cardState.cardExpiration?.error
                    ? 'red.500'
                    : undefined
                }
                m="0px"
                width="150px"
                fontWeight="bold"
                fontSize="small"
              >
                {cardExpirationLabel.toUpperCase()}
              </FormLabel>
              <Box w="90%">
                <CardExpiryElement
                  onFocus={() => setIsFocused('date')}
                  onBlur={() => setIsFocused(null)}
                  onChange={onCardExpirationChange}
                  options={{ disabled: !isEmailReady, style: inputStyle }}
                />
              </Box>
            </Box>
            {Boolean(cardState.cardExpiration?.error) && (
              <FormErrorMessage fontWeight="bold" pl="5">
                {cardState.cardExpiration?.error?.message}
              </FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={Boolean(cardState.cardCvc?.error)}>
            <Box
              bgColor="white"
              borderRadius="30px"
              p="5"
              display="flex"
              alignItems="center"
              fontWeight="bold"
            >
              <FormLabel
                color={
                  isFocused === 'ccv' && !cardState.cardCvc?.error
                    ? 'purple.500'
                    : cardState.cardCvc?.error
                    ? 'red.500'
                    : undefined
                }
                m="0px"
                width="150px"
                fontWeight="bold"
                fontSize="small"
              >
                {cardCcvLabel.toUpperCase()}
              </FormLabel>
              <Box w="90%">
                <CardCvcElement
                  onFocus={() => setIsFocused('ccv')}
                  onBlur={() => setIsFocused(null)}
                  onChange={onCardCvcChange}
                  options={{ disabled: !isEmailReady, style: inputStyle }}
                />
              </Box>
            </Box>
            {Boolean(cardState.cardCvc?.error) && (
              <FormErrorMessage fontWeight="bold" pl="5">
                {cardState.cardCvc?.error?.message}
              </FormErrorMessage>
            )}
          </FormControl>
        </Stack>
      </Collapse>
    </Stack>
  );
};
