import { PlacesPrediction, PlacesDetails, Address } from './types';

const PLACES_API_KEY = 'AIzaSyDdZpjNAJy0iUmcF73gVSmVq-cuk9p2U5Q';

export const fetchPredictions = async (
  sessionToken: string,
  query: string,
): Promise<{ id: string; label: string }[]> => {
  const url = `https://h1nz3vqvtf.execute-api.eu-west-1.amazonaws.com/production/autocomplete?key=${encodeURIComponent(
    PLACES_API_KEY,
  )}&input=${encodeURIComponent(query)}&sessiontoken=${encodeURIComponent(
    sessionToken,
  )}&location=48.856614,2.3522219&region=FR&types=address`;

  const res = await fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
  });

  if (res.status !== 200) {
    throw new Error(`Failed to fetch predictions status: ${res.status}`);
  }

  const json = (await res.json()) as { predictions: PlacesPrediction[] };

  return predictionsReshaper(json.predictions);
};

const predictionsReshaper = (predictions: PlacesPrediction[]): { id: string; label: string }[] => {
  return predictions.map((prediction) => ({
    id: prediction.place_id,
    label: prediction.description,
  }));
};

export const fetchDetails = async (placeId: string, sessionToken: string): Promise<Address | null> => {
  const url = `https://h1nz3vqvtf.execute-api.eu-west-1.amazonaws.com/production/details?key=${encodeURIComponent(
    PLACES_API_KEY,
  )}&place_id=${encodeURIComponent(placeId)}&sessiontoken=${encodeURIComponent(
    sessionToken,
  )}&fields=address_components`;

  const res = await fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
  });

  if (res.status !== 200) {
    throw new Error(`Failed to fetch details status: ${res.status}`);
  }

  const json = (await res.json()) as { result: PlacesDetails };
  const { result: details } = json;

  return detailsReshaper(details);
};

const detailsReshaper = (details: PlacesDetails): Address => {
  /**
   * LEGACY CODE
   */
  const get = (type: string) => {
    const addr = details.address_components.find((addr) => addr.types.includes(type));
    return addr ? addr.long_name : '';
  };
  const getShort = (type: string) => {
    const addr = details.address_components.find((addr) => addr.types.includes(type));
    return addr ? addr.short_name : '';
  };

  return {
    address1: [get('street_number'), get('route')].join(' ').trim(),
    address2: '',
    city: get('locality') || get('administrative_area_level_2') || get('postal_town'),
    country: get('country'),
    country_code: getShort('country'),
    province: get('administrative_area_level_1'),
    zip: get('postal_code'),
  };
};
