import axios, { AxiosError, Method } from 'axios';

import { GraphQlRequest, Request as RequestType, RestRequest } from 'src/api/request';
import { fromAxiosError } from 'src/api/queryError';
import { getAxiosInstance } from './axios';
import { print } from 'graphql';

function rejectUnexpectedValue(name: string, value: never): never {
  throw new Error(`Unexpected ${name}: ${value}`);
}

const getRestFetcher = async <TResult>({ request }: { request: RestRequest }): Promise<TResult> => {
  const source = axios.CancelToken.source();
  const options = {
    method: request.method,
    url: typeof request.endpoint === 'function' ? request.endpoint(request.endpointParams) : request.endpoint,
    body: JSON.stringify(request.params ?? {}),
    data: request.data,
    cancelToken: source.token,
  };

  const axiosInstance = getAxiosInstance(request.target);
  const promise = axiosInstance.request(options);
  (promise as any).cancel = source.cancel;
  return promise
    .then((response) => response.data)
    .catch(async (axiosError: AxiosError) => {
      throw await fromAxiosError(axiosError);
    });
};

const getGraphQlFetcher = async <TResult>({ request }: { request: GraphQlRequest }): Promise<TResult> => {
  const source = axios.CancelToken.source();
  const axiosInstance = getAxiosInstance(request.target ?? 'internal');
  const options = {
    method: 'post' as Method,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      query: print(request.query),
      variables: {
        ...(request.variables ?? {}),
      },
    }),
    cancelToken: source.token,
  };

  const promise = axiosInstance.request(options);
  (promise as any).cancel = source.cancel;
  return promise
    .then((response) => response.data)
    .catch(async (axiosError: AxiosError) => {
      throw await fromAxiosError(axiosError);
    });
};

const getFetcherFromRequestType = async <TResult>({
  request,
}: {
  request: RequestType;
}): Promise<TResult> => {
  switch (request.type) {
    case 'rest':
      return getRestFetcher({ request });
    case 'graphQL':
      return getGraphQlFetcher({ request });
    default:
      return rejectUnexpectedValue('request', request);
  }
};

export const getFetcher =
  <TResult>({ getRequest }: { getRequest(): RequestType }) =>
  async (): Promise<TResult> => {
    const request = getRequest();
    return getFetcherFromRequestType({ request });
  };

export const getMutationFetcher =
  <TResult, TVariables extends object & { endpointParams?: object }>({
    request,
  }: {
    request: RequestType<TVariables>;
  }) =>
  (variables: TVariables): Promise<TResult> => {
    const enrichedRequest = {
      ...(request.type === 'rest' && variables ? { data: variables } : {}),
      ...(request.type === 'graphQL' && variables ? { variables } : {}),
      ...request,
    };
    if (enrichedRequest.type === 'rest' && enrichedRequest.data && 'endpointParams' in enrichedRequest.data) {
      enrichedRequest.endpointParams = (enrichedRequest.data as any).endpointParams;
      // Delete to make sure the endpoint parameters do not propagate into the payload packet
      delete (enrichedRequest.data as any).endpointParams;
    }

    return getFetcherFromRequestType({ request: enrichedRequest });
  };
