import { HStack } from '@chakra-ui/react';
import { formatPrice } from 'src/utils/money';
import { Price } from 'src/components/Details/Price';
import { SalePrice } from 'src/components/Details/SalesPrice';

export const PriceTag = (props: any) => {
  const { price, currency, salePrice, rootProps, priceProps, salePriceProps } = props;
  return (
    <HStack spacing="1" {...rootProps}>
      <Price isOnSale={!!salePrice} textProps={priceProps}>
        {formatPrice(price, { currency })}
      </Price>
      {salePrice && <SalePrice {...salePriceProps}>{formatPrice(salePrice, { currency })}</SalePrice>}
    </HStack>
  );
};
