import { QueryState, reportQueryError } from 'src/api';
import { useStore } from 'src/business/store';
import { useQuery } from 'src/hooks/useQuery';
import { CheckoutCreatePayload } from 'src/types/checkout';

export const useFetchSession = (): QueryState<any> => {
  const { setState, checkoutSessionId, api_token, api_domain } = useStore();

  return useQuery<any>({
    key: ['FETCH_SESSION', checkoutSessionId],
    request: {
      type: 'rest',
      endpoint: `/api/checkout/${checkoutSessionId}?token=${api_token}&domain=${api_domain}`,
      target: 'internal',
    },
    isEnabled: Boolean(api_token) && Boolean(checkoutSessionId),
    reshapeData: (data) => data,
    options: {
      onError: ({ error }) => {
        reportQueryError(error);
        setState({
          shouldFetchOrderRequest: false,
        });
      },
      onSuccess: async ({ data }) => {
        const checkoutSession = { checkout: data } as CheckoutCreatePayload;
        setState({
          checkoutSession,
        });
      },
    },
  });
};
