import create from 'zustand';

import type { CommandStatus, FormEvent, JustApiToken } from 'src/types';
import type { CheckoutCreateResponse } from 'src/types/checkout-response';
import type { LoginResponse } from 'src/queries/useLogin';
import { ResponseApiSource } from 'src/queries/useSource';

type StoreExtra = {
  shouldRegister: boolean;
  checkoutSession: CheckoutCreateResponse;
  api_domain: string;
  api_token: string;
  isEmailDomainValid: boolean;
  requireLoginChallenge: boolean;
  email: string;
  loginSession: LoginResponse;
  challengeSession: JustApiToken;
  shouldResetEmail: boolean;
  order: any;
  canAutoFill: boolean;
  shouldFetchUserInfo: boolean;
  shouldFetchOrderPage: boolean;
  shouldFetchOrderRequest: boolean;
  card_token: ResponseApiSource;
  isFormDisabled: boolean;
  showSuccessPage: boolean;
  setLoadingPayment: boolean;
  shouldResetCard: boolean;
  paymentStatus: {
    display: boolean;
    url?: string;
    status?: string;
  };
  sellerInfo: {
    token: string;
    domain: string;
    name: string;
  };
  shouldUpdateAddress: boolean;
  shopName: string;
  discountCode?: string;
  command_status: CommandStatus;
  checkoutSessionId: string;
  isFormEdit: boolean;
  isStateReady: boolean;
  isDiscountOpen: boolean;
};

type Store = Partial<FormEvent & StoreExtra> & {
  setState: (state: Partial<FormEvent & StoreExtra>) => void;
};

export const useStore = create<Store>((set) => ({
  setState: (state: Partial<FormEvent & StoreExtra>) =>
    set((currentState) => ({ ...currentState, ...state })),
  shouldRegister: false,
  shouldFetchUserInfo: false,
  shouldFetchOrderPage: false,
  shouldFetchOrderRequest: false,
  isFormEdit: false,
}));
