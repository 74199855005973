import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { CountriesResponses } from 'src/queries/useCountries';
import { useLoginMutation } from 'src/queries/useLogin';
import { Translations } from 'src/types/translations';
import * as z from 'zod';

export const useFormValidation = () => {
  const { t } = useTranslation<'common', keyof Translations>('common');
  const { onLogin, status } = useLoginMutation();
  const [currentMail, setCurrentMail] = useState<string>('');
  const client = useQueryClient();
  const countries = client.getQueryData<CountriesResponses[]>('QUERY/COUNTRIES');

  const emailError = t('inputs.email.error');
  const firstNameError = t('inputs.firstname.error');
  const lastNameError = t('inputs.lastname.error');
  const address1Error = t('inputs.address1.error');
  const cityError = t('inputs.city.error');
  const countryError = t('inputs.country.error');
  const zipError = t('inputs.zip.error');
  const phoneError = t('inputs.phone_number.error');
  const provinceError = t('inputs.province.error');
  const ownerError = t('inputs.owner.error');
  const shippingMethodError = t('inputs.shipping_method.error');
  const billingAddress1Error = t('inputs.billing_address1.error');

  const schema = z.object({
    email: z
      .string()
      .email({ message: emailError })
      .transform(async (value) => {
        if (currentMail !== value) {
          await onLogin(value);
          setCurrentMail(value);
        }

        return value;
      }),
    user: z.object({
      firstname: z.string().nonempty({ message: firstNameError }),
      lastname: z.string().nonempty({ message: lastNameError }),
      phone_number: z.string().nonempty({ message: phoneError }),
    }),
    card: z.object({
      owner: z.string().nonempty({ message: ownerError }),
      token: z.string(),
    }),
    discountCode: z.string().optional(),
    address: z.object({
      address1: z.string().nonempty({ message: address1Error }),
      address2: z.string().optional(),
      city: z.string().nonempty({ message: cityError }),
      country: z.string().nonempty({ message: countryError }),
      zip: z
        .string()
        .nonempty({ message: zipError })
        .refine(
          (value) => {
            const current = countries?.find((country) => country.name.common === value);
            const isValid = new RegExp(current?.postalCode?.regex ?? '').test(value);
            if (!isValid) {
              return false;
            }
            return true;
          },
          {
            message: zipError,
          },
        ),
      province: z.string().nonempty({ message: provinceError }),
      country_code: z.string(),
    }),
    billing_address: z
      .object({
        address1: z.string().nonempty({ message: billingAddress1Error }),
        address2: z.string().optional(),
        city: z.string().nonempty({ message: cityError }),
        country: z.string().nonempty({ message: countryError }),
        zip: z
          .string()
          .nonempty({ message: zipError })
          .refine(
            (value) => {
              const current = countries?.find((country) => country.name.common === value);
              const isValid = new RegExp(current?.postalCode?.regex ?? '').test(value);
              if (!isValid) {
                return false;
              }
              return true;
            },
            {
              message: zipError,
            },
          ),
        province: z.string().nonempty({ message: provinceError }),
        country_code: z.string(),
      })
      .optional(),
    shipping_method: z.string().nonempty({ message: shippingMethodError }),
  });

  const schemaEdit = z.object({
    email: z.string().email({ message: emailError }),
    user: z.object({
      firstname: z.string().nonempty({ message: firstNameError }),
      lastname: z.string().nonempty({ message: lastNameError }),
      phone_number: z.string().nonempty({ message: phoneError }),
    }),
    card: z.object({
      owner: z.string().nonempty({ message: ownerError }),
      token: z.string(),
    }),
    discountCode: z.string().optional(),
    address: z.object({
      address1: z.string().nonempty({ message: address1Error }),
      address2: z.string().optional(),
      city: z.string().nonempty({ message: cityError }),
      country: z.string().nonempty({ message: countryError }),
      zip: z
        .string()
        .nonempty({ message: zipError })
        .refine(
          (value) => {
            const current = countries?.find((country) => country.name.common === value);
            const isValid = new RegExp(current?.postalCode?.regex ?? '').test(value);
            if (!isValid) {
              return false;
            }
            return true;
          },
          {
            message: zipError,
          },
        ),
      province: z.string().nonempty({ message: provinceError }),
      country_code: z.string(),
    }),
    billing_address: z
      .object({
        address1: z.string().nonempty({ message: address1Error }),
        address2: z.string().optional(),
        city: z.string().nonempty({ message: cityError }),
        country: z.string().nonempty({ message: countryError }),
        zip: z
          .string()
          .nonempty({ message: zipError })
          .refine(
            (value) => {
              const current = countries?.find((country) => country.name.common === value);
              const isValid = new RegExp(current?.postalCode?.regex ?? '').test(value);
              if (!isValid) {
                return false;
              }
              return true;
            },
            {
              message: zipError,
            },
          ),
        province: z.string().nonempty({ message: provinceError }),
        country_code: z.string(),
      })
      .optional(),
    shipping_method: z.string().nonempty({ message: shippingMethodError }),
  });

  return {
    schema,
    status,
    setCurrentMail,
    schemaEdit,
  };
};

export type FormSchema = z.infer<ReturnType<typeof useFormValidation>['schema']>;
