import { Button, Stack, Text, Image, Heading, Flex } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { useStore } from 'src/business/store';
import { _postMessage } from 'src/commons';
import { DEFAULT_EMAIL_ADDRESS } from 'src/constants';
import { useUpdateCheckoutEmail } from 'src/queries/useUpdateCheckoutEmail';

export const Confirmation = () => {
  const {
    orderUrl,
    requestId,
    orderId,
    sessionKey,
    email,
    checkoutSession,
    expiration,
    api_domain,
    api_token,
  } = useStore();
  const [updateEmail] = useUpdateCheckoutEmail();

  const { t } = useTranslation('common');
  const onConfirmationClick = async () => {
    // @ts-expect-error always defined
    window.top.location.replace(orderUrl);

    updateEmail({
      variables: {
        checkoutId: checkoutSession?.checkout?.id as string,
        email: DEFAULT_EMAIL_ADDRESS as string,
      },
      // @ts-expect-error field always defined
      domain: api_domain,
      // @ts-expect-error field always defined
      token: api_token,
    });

    _postMessage({
      command_status: 'CREATED',
      requestId: requestId as string,
      expiration,
      orderUrl: orderUrl as string,
      orderId: orderId as string,
      email: email as string,
      sessionKey: sessionKey as string,
      checkoutSessionId: checkoutSession?.checkout?.id as string,
      emitter: 'JUST_FORM',
      action: 'CLOSE',
    });
  };

  return (
    <div className="overlay show">
      <div className="overlay-content">
        <Flex maxW="400px" mx="auto" py={{ base: '12', md: '16' }} alignItems="center" flexDirection="column">
          {/* <Logo height="5" /> */}
          <Image src="/images/just_logo.png" marginBottom={30} width={212} height={45} alt="Just Logo" />

          <Stack spacing="3" textAlign="center">
            <Heading fontSize="2xl">{t('end.message4')}</Heading>
            <Text fontSize="s">{t('end.message5')}</Text>
            <Text fontSize="m">{t('end.message2')}</Text>
            <Text fontSize="s">{t('end.message3')}</Text>
          </Stack>
          <Button
            borderRadius={40}
            colorScheme="purple"
            marginTop={10}
            color="white"
            onClick={onConfirmationClick}
          >
            {t('end.message6')}
          </Button>
        </Flex>
      </div>
    </div>
  );
};
