import { reportQueryError } from 'src/api';
import { useStore } from 'src/business/store';
import { _postMessage } from 'src/commons';
import { useMutation } from 'src/hooks/useMutation';

export const useCancelOrder = () => {
  const { setState, orderId, sessionKey, requestId } = useStore();

  return useMutation<any, any>({
    request: {
      type: 'rest',
      method: 'post',
      endpoint: `/v1/order/${orderId}/cancel`,
      target: 'just',
    },
    reshapeData: (data) => data,
    options: {
      throwOnError: true,
      // @ts-expect-error property is always defined
      onMutate: () => {
        setState({ setLoadingPayment: true });
      },
      onError: ({ error }) => {
        _postMessage({
          command_status: 'CANCELLED',
          requestId: requestId as string,
          orderUrl: '',
          orderId: '',
          email: '',
          sessionKey: sessionKey as string,
          emitter: 'JUST_FORM',
          action: 'CLOSE',
        });

        reportQueryError(error);
      },
      onSuccess: () => {
        setState({ setLoadingPayment: false });
        _postMessage({
          command_status: 'CANCELLED',
          requestId: requestId as string,
          orderUrl: '',
          orderId: '',
          email: '',
          sessionKey: sessionKey as string,
          emitter: 'JUST_FORM',
          action: 'CLOSE',
        });
      },
    },
  });
};
