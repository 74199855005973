import { ChevronUpIcon } from '@chakra-ui/icons';
import {
  FormControl,
  FormErrorMessage,
  FormControlProps,
  Input,
  InputLeftAddon,
  InputGroup,
  Select,
  Box,
  Text,
  Avatar,
} from '@chakra-ui/react';
import { useState } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { debounce } from 'src/commons';

type FormInputProps = {
  error?: string;
  type?: string;
  htmlFor?: string;
  label?: string;
  placeholder?: string;
  inputRegister: UseFormRegisterReturn;
  disableInputAddonMargin?: boolean;
  showUpIcon?: boolean;
  showError?: boolean;
  upIconOnClick?: () => void;
} & Partial<Omit<FormControlProps, 'isInvalid'>>;

export const FormInput = ({
  error,
  type,
  htmlFor,
  label,
  inputRegister,
  placeholder,
  disableInputAddonMargin,
  showUpIcon,
  showError,
  upIconOnClick,
  ...formControlProps
}: FormInputProps) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <FormControl {...formControlProps} isInvalid={Boolean(error)} display="flex" flexDirection="column">
      <InputGroup bgColor="white" borderRadius="30px" p="2">
        <InputLeftAddon
          fontWeight="bold"
          fontSize="small"
          bgColor="transparent"
          border="none"
          color={isFocused && !error ? 'purple.500' : error ? 'red.500' : undefined}
          minW={{
            sm: !disableInputAddonMargin ? '140px' : undefined,
            md: !disableInputAddonMargin ? '160px' : undefined,
            base: !disableInputAddonMargin ? '140px' : undefined,
          }}
          // eslint-disable-next-line react/no-children-prop
          children={label?.toUpperCase()}
        />
        <Input
          fontWeight="bold"
          fontSize="small"
          variant="unstyled"
          onFocus={(event) => setIsFocused(Boolean(event))}
          id={`input-${label}`}
          placeholder={placeholder}
          type={type ?? 'text'}
          autoComplete="on"
          {...inputRegister}
          onBlur={() => setIsFocused(false)}
          onChange={debounce((event) => {
            inputRegister.onChange(event);
          }, 1000)}
        />
        {showUpIcon ? (
          <Avatar
            size="sm"
            bg={error ? 'red.500' : 'purple.500'}
            icon={<ChevronUpIcon onClick={upIconOnClick} fontSize="2em" color="white" />}
          />
        ) : null}
      </InputGroup>
      {error && showError && (
        <FormErrorMessage fontWeight="bold" ml="20px">
          {error}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

export const FormSelectInput = ({
  error,
  label,
  inputRegister,
  placeholder,
  options,
  showError,
  ...formControlProps
}: FormInputProps & { options?: { label: string; value: string; flag: string }[] }) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <FormControl {...formControlProps} isInvalid={Boolean(error)} display="flex" flexDirection="column">
      <Box bgColor="transparent" borderRadius="30px" p="4" display="flex" alignItems="center" ml="8px">
        <Text
          fontWeight="bold"
          fontSize="small"
          bgColor="transparent"
          border="none"
          color={isFocused && !error ? 'purple.500' : error ? 'red.500' : undefined}
        >
          {label?.toUpperCase()}
        </Text>
        {options?.length ? (
          <Select
            ml={{ sm: '63px', md: '75px', base: '63px' }}
            display="flex"
            placeholder={placeholder}
            {...inputRegister}
            fontWeight="bold"
            fontSize="small"
            variant="unstyled"
            onBlur={() => setIsFocused(false)}
            onFocus={(event) => setIsFocused(Boolean(event))}
          >
            {options.map((option) => (
              <option key={option.value} value={option.label}>
                {option.flag} {option.label}
              </option>
            ))}
          </Select>
        ) : null}
      </Box>
      {error && showError && (
        <FormErrorMessage fontWeight="bold" ml="20px" mb="5px">
          {error}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};
