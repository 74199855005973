import * as Sentry from '@sentry/nextjs';
import type { CaptureContext } from '@sentry/types';

export { Severity } from '@sentry/nextjs';

export const attachTagToFutureErrors = Sentry.setTag;

export function reportError<T extends Error>(error: T, options?: CaptureContext): void {
  Sentry.captureException(error, options);
}
