import { reportQueryError } from 'src/api';
import { useStore } from 'src/business/store';
import { getStorage } from 'src/commons';
import { useQuery } from 'src/hooks/useQuery';

export type ResponseApiSource = {
  id: string;
  card: {
    brand: string;
    exp_year: number;
    exp_month: number;
    last_4: string;
    updated_at: string;
    created_at: string;
    name: string;
  };
  is_default?: 0 | 1;
};

export const useSource = () => {
  const token = getStorage('token');

  return useQuery<ResponseApiSource[], ResponseApiSource[]>({
    key: 'USER_PAYMENT_SOURCES',
    request: {
      type: 'rest',
      endpoint: `/v1/sources`,
      target: 'just',
    },
    reshapeData: (data) => data,
    isEnabled: Boolean(token),
    options: {
      onError: ({ error }) => {
        reportQueryError(error);
      },
    },
  });
};
