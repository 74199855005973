export const COUNTRIES = [
  {
    name: 'Afghanistan',
    code: 'AF',
  },
  {
    name: 'Afrique du Sud',
    code: 'ZA',
  },
  {
    name: 'Åland(les Îles)',
    code: 'AX',
  },
  {
    name: 'Albanie',
    code: 'AL',
  },
  {
    name: 'Algérie',
    code: 'DZ',
  },
  {
    name: 'Allemagne',
    code: 'DE',
  },
  {
    name: 'Andorre',
    code: 'AD',
  },
  {
    name: 'Angola',
    code: 'AO',
  },
  {
    name: 'Anguilla',
    code: 'AI',
  },
  {
    name: 'Antarctique',
    code: 'AQ',
  },
  {
    name: 'Antigua-et-Barbuda',
    code: 'AG',
  },
  {
    name: 'Arabie saoudite',
    code: 'SA',
  },
  {
    name: 'Argentine',
    code: 'AR',
  },
  {
    name: 'Arménie',
    code: 'AM',
  },
  {
    name: 'Aruba',
    code: 'AW',
  },
  {
    name: 'Australie',
    code: 'AU',
  },
  {
    name: 'Autriche',
    code: 'AT',
  },
  {
    name: 'Azerbaïdjan',
    code: 'AZ',
  },
  {
    name: 'Bahamas',
    code: 'BS',
  },
  {
    name: 'Bahreïn',
    code: 'BH',
  },
  {
    name: 'Bangladesh',
    code: 'BD',
  },
  {
    name: 'Barbade',
    code: 'BB',
  },
  {
    name: 'Bélarus',
    code: 'BY',
  },
  {
    name: 'Belgique',
    code: 'BE',
  },
  {
    name: 'Belize',
    code: 'BZ',
  },
  {
    name: 'Bénin',
    code: 'BJ',
  },
  {
    name: 'Bermudes',
    code: 'BM',
  },
  {
    name: 'Bhoutan',
    code: 'BT',
  },
  {
    name: 'Bolivie (État plurinational de)',
    code: 'BO',
  },
  {
    name: 'Bonaire, Saint-Eustache et Saba',
    code: 'BQ',
  },
  {
    name: 'Bosnie-Herzégovine',
    code: 'BA',
  },
  {
    name: 'Botswana',
    code: 'BW',
  },
  {
    name: "Bouvet (l'Île)",
    code: 'BV',
  },
  {
    name: 'Brésil',
    code: 'BR',
  },
  {
    name: 'Brunéi Darussalam',
    code: 'BN',
  },
  {
    name: 'Bulgarie',
    code: 'BG',
  },
  {
    name: 'Burkina Faso',
    code: 'BF',
  },
  {
    name: 'Burundi',
    code: 'BI',
  },
  {
    name: 'Cabo Verde',
    code: 'CV',
  },
  {
    name: 'Caïmans (les Îles)',
    code: 'KY',
  },
  {
    name: 'Cambodge',
    code: 'KH',
  },
  {
    name: 'Cameroun',
    code: 'CM',
  },
  {
    name: 'Canada',
    code: 'CA',
  },
  {
    name: 'Chili',
    code: 'CL',
  },
  {
    name: 'Chine',
    code: 'CN',
  },
  {
    name: "Christmas (l'Île)",
    code: 'CX',
  },
  {
    name: 'Chypre',
    code: 'CY',
  },
  {
    name: 'Cocos (les Îles)/ Keeling (les Îles)',
    code: 'CC',
  },
  {
    name: 'Colombie',
    code: 'CO',
  },
  {
    name: 'Comores',
    code: 'KM',
  },
  {
    name: 'Congo',
    code: 'CG',
  },
  {
    name: 'Congo (la République démocratique du)',
    code: 'CD',
  },
  {
    name: 'Cook (les Îles)',
    code: 'CK',
  },
  {
    name: 'Corée (la République de)',
    code: 'KR',
  },
  {
    name: 'Corée (la République populaire démocratique de)',
    code: 'KP',
  },
  {
    name: 'Costa Rica',
    code: 'CR',
  },
  {
    name: "Côte d'Ivoire",
    code: 'CI',
  },
  {
    name: 'Croatie',
    code: 'HR',
  },
  {
    name: 'Cuba',
    code: 'CU',
  },
  {
    name: 'Curaçao',
    code: 'CW',
  },
  {
    name: 'Danemark',
    code: 'DK',
  },
  {
    name: 'Djibouti',
    code: 'DJ',
  },
  {
    name: 'dominicaine (la République)',
    code: 'DO',
  },
  {
    name: 'Dominique',
    code: 'DM',
  },
  {
    name: 'Égypte',
    code: 'EG',
  },
  {
    name: 'El Salvador',
    code: 'SV',
  },
  {
    name: 'Émirats arabes unis',
    code: 'AE',
  },
  {
    name: 'Équateur',
    code: 'EC',
  },
  {
    name: 'Érythrée',
    code: 'ER',
  },
  {
    name: 'Espagne',
    code: 'ES',
  },
  {
    name: 'Estonie',
    code: 'EE',
  },
  {
    name: 'Eswatini',
    code: 'SZ',
  },
  {
    name: "États-Unis d'Amérique",
    code: 'US',
  },
  {
    name: 'Éthiopie',
    code: 'ET',
  },
  {
    name: 'Falkland (les Îles)/Malouines (les Îles)',
    code: 'FK',
  },
  {
    name: 'Féroé (les Îles)',
    code: 'FO',
  },
  {
    name: 'Fidji',
    code: 'FJ',
  },
  {
    name: 'Finlande',
    code: 'FI',
  },
  {
    name: 'France',
    code: 'FR',
  },
  {
    name: 'Gabon',
    code: 'GA',
  },
  {
    name: 'Gambie',
    code: 'GM',
  },
  {
    name: 'Géorgie',
    code: 'GE',
  },
  {
    name: 'Géorgie du Sud-et-les Îles Sandwich du Sud',
    code: 'GS',
  },
  {
    name: 'Ghana',
    code: 'GH',
  },
  {
    name: 'Gibraltar',
    code: 'GI',
  },
  {
    name: 'Grèce',
    code: 'GR',
  },
  {
    name: 'Grenade',
    code: 'GD',
  },
  {
    name: 'Groenland',
    code: 'GL',
  },
  {
    name: 'Guadeloupe',
    code: 'GP',
  },
  {
    name: 'Guam',
    code: 'GU',
  },
  {
    name: 'Guatemala',
    code: 'GT',
  },
  {
    name: 'Guernesey',
    code: 'GG',
  },
  {
    name: 'Guinée',
    code: 'GN',
  },
  {
    name: 'Guinée équatoriale',
    code: 'GQ',
  },
  {
    name: 'Guinée-Bissau',
    code: 'GW',
  },
  {
    name: 'Guyana',
    code: 'GY',
  },
  {
    name: 'Guyane française (la )',
    code: 'GF',
  },
  {
    name: 'Haïti',
    code: 'HT',
  },
  {
    name: "Heard-et-Îles MacDonald (l'Île)",
    code: 'HM',
  },
  {
    name: 'Honduras',
    code: 'HN',
  },
  {
    name: 'Hong Kong',
    code: 'HK',
  },
  {
    name: 'Hongrie',
    code: 'HU',
  },
  {
    name: 'Île de Man',
    code: 'IM',
  },
  {
    name: 'Îles mineures éloignées des États-Unis',
    code: 'UM',
  },
  {
    name: 'Inde',
    code: 'IN',
  },
  {
    name: "Indien (le Territoire britannique de l'océan)",
    code: 'IO',
  },
  {
    name: 'Indonésie',
    code: 'ID',
  },
  {
    name: "Iran (République Islamique d')",
    code: 'IR',
  },
  {
    name: 'Iraq',
    code: 'IQ',
  },
  {
    name: 'Irlande',
    code: 'IE',
  },
  {
    name: 'Islande',
    code: 'IS',
  },
  {
    name: 'Israël',
    code: 'IL',
  },
  {
    name: 'Italie',
    code: 'IT',
  },
  {
    name: 'Jamaïque',
    code: 'JM',
  },
  {
    name: 'Japon',
    code: 'JP',
  },
  {
    name: 'Jersey',
    code: 'JE',
  },
  {
    name: 'Jordanie',
    code: 'JO',
  },
  {
    name: 'Kazakhstan',
    code: 'KZ',
  },
  {
    name: 'Kenya',
    code: 'KE',
  },
  {
    name: 'Kirghizistan',
    code: 'KG',
  },
  {
    name: 'Kiribati',
    code: 'KI',
  },
  {
    name: 'Koweït',
    code: 'KW',
  },
  {
    name: 'Lao (la République démocratique populaire)',
    code: 'LA',
  },
  {
    name: 'Lesotho',
    code: 'LS',
  },
  {
    name: 'Lettonie',
    code: 'LV',
  },
  {
    name: 'Liban',
    code: 'LB',
  },
  {
    name: 'Libéria',
    code: 'LR',
  },
  {
    name: 'Libye',
    code: 'LY',
  },
  {
    name: 'Liechtenstein',
    code: 'LI',
  },
  {
    name: 'Lituanie',
    code: 'LT',
  },
  {
    name: 'Luxembourg',
    code: 'LU',
  },
  {
    name: 'Macao',
    code: 'MO',
  },
  {
    name: 'Macédoine du Nord',
    code: 'MK',
  },
  {
    name: 'Madagascar',
    code: 'MG',
  },
  {
    name: 'Malaisie',
    code: 'MY',
  },
  {
    name: 'Malawi',
    code: 'MW',
  },
  {
    name: 'Maldives',
    code: 'MV',
  },
  {
    name: 'Mali',
    code: 'ML',
  },
  {
    name: 'Malte',
    code: 'MT',
  },
  {
    name: 'Mariannes du Nord (les Îles)',
    code: 'MP',
  },
  {
    name: 'Maroc',
    code: 'MA',
  },
  {
    name: 'Marshall (les Îles)',
    code: 'MH',
  },
  {
    name: 'Martinique',
    code: 'MQ',
  },
  {
    name: 'Maurice',
    code: 'MU',
  },
  {
    name: 'Mauritanie',
    code: 'MR',
  },
  {
    name: 'Mayotte',
    code: 'YT',
  },
  {
    name: 'Mexique',
    code: 'MX',
  },
  {
    name: 'Micronésie (États fédérés de)',
    code: 'FM',
  },
  {
    name: 'Moldova (la République de)',
    code: 'MD',
  },
  {
    name: 'Monaco',
    code: 'MC',
  },
  {
    name: 'Mongolie',
    code: 'MN',
  },
  {
    name: 'Monténégro',
    code: 'ME',
  },
  {
    name: 'Montserrat',
    code: 'MS',
  },
  {
    name: 'Mozambique',
    code: 'MZ',
  },
  {
    name: 'Myanmar',
    code: 'MM',
  },
  {
    name: 'Namibie',
    code: 'NA',
  },
  {
    name: 'Nauru',
    code: 'NR',
  },
  {
    name: 'Népal',
    code: 'NP',
  },
  {
    name: 'Nicaragua',
    code: 'NI',
  },
  {
    name: 'Niger',
    code: 'NE',
  },
  {
    name: 'Nigéria',
    code: 'NG',
  },
  {
    name: 'Niue',
    code: 'NU',
  },
  {
    name: "Norfolk (l'Île)",
    code: 'NF',
  },
  {
    name: 'Norvège',
    code: 'NO',
  },
  {
    name: 'Nouvelle-Calédonie',
    code: 'NC',
  },
  {
    name: 'Nouvelle-Zélande',
    code: 'NZ',
  },
  {
    name: 'Oman',
    code: 'OM',
  },
  {
    name: 'Ouganda',
    code: 'UG',
  },
  {
    name: 'Ouzbékistan',
    code: 'UZ',
  },
  {
    name: 'Pakistan',
    code: 'PK',
  },
  {
    name: 'Palaos',
    code: 'PW',
  },
  {
    name: 'Palestine, État de',
    code: 'PS',
  },
  {
    name: 'Panama',
    code: 'PA',
  },
  {
    name: 'Papouasie-Nouvelle-Guinée',
    code: 'PG',
  },
  {
    name: 'Paraguay',
    code: 'PY',
  },
  {
    name: 'Pays-Bas',
    code: 'NL',
  },
  {
    name: 'Pérou',
    code: 'PE',
  },
  {
    name: 'Philippines',
    code: 'PH',
  },
  {
    name: 'Pitcairn',
    code: 'PN',
  },
  {
    name: 'Pologne',
    code: 'PL',
  },
  {
    name: 'Polynésie française',
    code: 'PF',
  },
  {
    name: 'Porto Rico',
    code: 'PR',
  },
  {
    name: 'Portugal',
    code: 'PT',
  },
  {
    name: 'Qatar',
    code: 'QA',
  },
  {
    name: 'République arabe syrienne',
    code: 'SY',
  },
  {
    name: 'République centrafricaine',
    code: 'CF',
  },
  {
    name: 'Réunion (La)',
    code: 'RE',
  },
  {
    name: 'Roumanie',
    code: 'RO',
  },
  {
    name: 'Royaume-Uni',
    code: 'GB',
  },
  {
    name: 'Russie (la Fédération de)',
    code: 'RU',
  },
  {
    name: 'Rwanda',
    code: 'RW',
  },
  {
    name: 'Sahara occidental*',
    code: 'EH',
  },
  {
    name: 'Saint-Barthélemy',
    code: 'BL',
  },
  {
    name: 'Saint-Kitts-et-Nevis',
    code: 'KN',
  },
  {
    name: 'Saint-Marin',
    code: 'SM',
  },
  {
    name: 'Saint-Martin (partie française)',
    code: 'MF',
  },
  {
    name: 'Saint-Martin (partie néerlandaise)',
    code: 'SX',
  },
  {
    name: 'Saint-Pierre-et-Miquelon',
    code: 'PM',
  },
  {
    name: 'Saint-Siège',
    code: 'VA',
  },
  {
    name: 'Saint-Vincent-et-les Grenadines',
    code: 'VC',
  },
  {
    name: 'Sainte-Hélène, Ascension et Tristan da Cunha',
    code: 'SH',
  },
  {
    name: 'Sainte-Lucie',
    code: 'LC',
  },
  {
    name: 'Salomon (les Îles)',
    code: 'SB',
  },
  {
    name: 'Samoa',
    code: 'WS',
  },
  {
    name: 'Samoa américaines',
    code: 'AS',
  },
  {
    name: 'Sao Tomé-et-Principe',
    code: 'ST',
  },
  {
    name: 'Sénégal',
    code: 'SN',
  },
  {
    name: 'Serbie',
    code: 'RS',
  },
  {
    name: 'Seychelles',
    code: 'SC',
  },
  {
    name: 'Sierra Leone',
    code: 'SL',
  },
  {
    name: 'Singapour',
    code: 'SG',
  },
  {
    name: 'Slovaquie',
    code: 'SK',
  },
  {
    name: 'Slovénie',
    code: 'SI',
  },
  {
    name: 'Somalie',
    code: 'SO',
  },
  {
    name: 'Soudan',
    code: 'SD',
  },
  {
    name: 'Soudan du Sud',
    code: 'SS',
  },
  {
    name: 'Sri Lanka',
    code: 'LK',
  },
  {
    name: 'Suède',
    code: 'SE',
  },
  {
    name: 'Suisse',
    code: 'CH',
  },
  {
    name: 'Suriname',
    code: 'SR',
  },
  {
    name: "Svalbard et l'Île Jan Mayen",
    code: 'SJ',
  },
  {
    name: 'Tadjikistan',
    code: 'TJ',
  },
  {
    name: 'Taïwan (Province de Chine)',
    code: 'TW',
  },
  {
    name: 'Tanzanie (la République-Unie de)',
    code: 'TZ',
  },
  {
    name: 'Tchad',
    code: 'TD',
  },
  {
    name: 'Tchéquie',
    code: 'CZ',
  },
  {
    name: 'Terres australes françaises',
    code: 'TF',
  },
  {
    name: 'Thaïlande',
    code: 'TH',
  },
  {
    name: 'Timor-Leste',
    code: 'TL',
  },
  {
    name: 'Togo',
    code: 'TG',
  },
  {
    name: 'Tokelau',
    code: 'TK',
  },
  {
    name: 'Tonga',
    code: 'TO',
  },
  {
    name: 'Trinité-et-Tobago',
    code: 'TT',
  },
  {
    name: 'Tunisie',
    code: 'TN',
  },
  {
    name: 'Turkménistan',
    code: 'TM',
  },
  {
    name: 'Turks-et-Caïcos (les Îles)',
    code: 'TC',
  },
  {
    name: 'Turquie',
    code: 'TR',
  },
  {
    name: 'Tuvalu',
    code: 'TV',
  },
  {
    name: 'Ukraine',
    code: 'UA',
  },
  {
    name: 'Uruguay',
    code: 'UY',
  },
  {
    name: 'Vanuatu',
    code: 'VU',
  },
  {
    name: 'Venezuela (République bolivarienne du)',
    code: 'VE',
  },
  {
    name: 'Vierges britanniques (les Îles)',
    code: 'VG',
  },
  {
    name: 'Vierges des États-Unis (les Îles)',
    code: 'VI',
  },
  {
    name: 'Viet Nam',
    code: 'VN',
  },
  {
    name: 'Wallis-et-Futuna',
    code: 'WF',
  },
  {
    name: 'Yémen',
    code: 'YE',
  },
  {
    name: 'Zambie',
    code: 'ZM',
  },
  {
    name: 'Zimbabwe',
    code: 'ZW',
  },
];
