import PhoneInputWithCountry from 'react-phone-number-input';
import {
  FormControl,
  FormErrorMessage,
  FormControlProps,
  InputGroup,
  InputLeftAddon,
} from '@chakra-ui/react';
import { useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';

import { FormSchema } from 'src/hooks/useFormValidation';
import 'react-phone-number-input/style.css';

type FormInputProps = {
  error?: string;
  htmlFor?: string;
  label: string;
  setValue: UseFormSetValue<FormSchema>;
  value?: string;
  showError?: boolean;
} & Partial<Omit<FormControlProps, 'isInvalid'>>;

export const PhoneInput = ({
  error,
  htmlFor,
  label,
  setValue,
  value,
  showError,
  ...formControlProps
}: FormInputProps) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <FormControl {...formControlProps} isInvalid={Boolean(error)}>
      <InputGroup>
        <InputLeftAddon
          bgColor="transparent"
          border="none"
          minW={{
            sm: '140px',
            md: '160px',
            base: '140px',
          }}
          color={isFocused && !error ? 'purple.500' : error ? 'red.500' : undefined}
          ml="8px"
          fontWeight="bold"
          fontSize="small"
          // eslint-disable-next-line react/no-children-prop
          children={label.toUpperCase()}
        />
        {/* @ts-expect-error typescript is in vacation */}
        <PhoneInputWithCountry
          defaultCountry="FR"
          withCountryCallingCode
          international
          placeholder="+33 00 00 00 00"
          smartCaret
          onFocus={(event) => setIsFocused(Boolean(event))}
          onBlur={() => setIsFocused(false)}
          value={value}
          onChange={(value: string) => {
            setValue('user.phone_number', value);
          }}
        />
      </InputGroup>
      {error && showError && (
        <FormErrorMessage fontWeight="bold" ml="20px" mb="5px">
          {error}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};
