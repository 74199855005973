import {
  Box,
  Button,
  Text,
  Stack,
  useDisclosure,
  useToast,
  RadioGroup,
  Radio,
  Container,
} from '@chakra-ui/react';
import { Elements } from '@stripe/react-stripe-js';
import { empty, is, isEmpty } from 'ramda';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'next-i18next';

import { CardInput } from 'src/components/CardInput';
import { _postMessage } from 'src/commons';
import { useFormValidation, FormSchema } from 'src/hooks/useFormValidation';
import { useOnClickOutside } from 'src/hooks/useOnClickOutside';
import { RegisterPayloadAddress, useRegister } from 'src/queries/useRegister';
import { useStore } from 'src/business/store';
import { reshapeCurrentUserData, UserInfoResponse } from 'src/queries/useUserInfo';
import { useCreateOrder } from 'src/queries/useCreateOrder';
import { useAddSource } from 'src/queries/useAddSource';
import { Address } from 'src/components/Address';
import { UserInput } from 'src/components/UserInput';
import { ShippingRates } from 'src/components/ShippingRates';
import { Email } from 'src/components/Email';
import { usePolicy } from 'src/queries/usePolicy';
import { Details } from 'src/components/Details/OrderDetail';

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_KEY as string);

const Form = (props: { token: string; domain: string; toggleDiscount: () => void }) => {
  const { t } = useTranslation('common');
  const { schema, status: loginStatus, setCurrentMail } = useFormValidation();
  const [canSubmit, setCanSubmit] = useState(false);
  const resolver = zodResolver(schema, { async: true });
  const [createOrder] = useCreateOrder();
  const { data: policyData } = usePolicy();
  const [addSource] = useAddSource();
  const { isOpen: isStripeOpen, onToggle: onOpenStripe } = useDisclosure();
  const [billingChoice, setBillingChoice] = useState<number>(1);
  const stripeRef = useRef<any>();
  const client = useQueryClient();
  const currentUser = client.getQueryData('USER_INFO') as UserInfoResponse | undefined;
  const {
    sessionKey,
    requestId,
    language,
    checkoutSession,
    setState,
    shouldRegister,
    command_status,
    isEmailDomainValid,
    email: freezedEmail,
    canAutoFill,
    card_token,
    app_id,
    shopName,
    paymentStatus,
    requireLoginChallenge,
  } = useStore();
  // @ts-expect-error property always defined
  const [registerUser, { error: registerError }] = useRegister();
  useOnClickOutside(stripeRef, onOpenStripe);
  const onBillingChoiceChange = (id: number) => {
    if (currentUser) {
      const {
        user: { billing_address },
      } = reshapeCurrentUserData(currentUser);
      for (const key in billing_address) {
        // @ts-expect-error always defined
        setValue(`address.${key}`, billing_address[key]);
      }
      return setBillingChoice(id);
    }

    return setBillingChoice(id);
  };
  const {
    handleSubmit,
    control,
    register,
    formState: { errors, isSubmitting },
    setFocus,
    setValue,
    trigger,
    getValues,
    watch,
    setError,
    clearErrors,
  } = useForm<FormSchema>({
    resolver,
    reValidateMode: 'onChange',
    mode: 'onChange',
    shouldFocusError: true,
  });
  const toast = useToast();
  const cancelLabel = t('buttons.continue');
  const confirmLabel = t('buttons.confirm');
  const sameDelivveryAddressLabel = t('info.same_delivery_address');
  const differentDeliveryAddress = t('info.different_delivery_address');
  const addDiscountButtonLabel = t('buttons.add_discount');
  const editDiscountButtonLabel = t('buttons.edit_discount');
  const addressValues = getValues('address') ?? {};
  const email = watch('email');
  const billingAddress = watch('billing_address.address1');
  const token = getValues('card.token');

  const shippingRates = useMemo(() => {
    const shipping = checkoutSession?.checkout?.availableShippingRates;
    if (shipping?.shippingRates) {
      return shipping?.shippingRates;
    }
    return [];
  }, [checkoutSession]);

  const storedShippingLine = useMemo(() => {
    const shippingLine = checkoutSession?.checkout?.shippingLine;
    if (shippingLine) {
      return shippingLine;
    }
    return null;
  }, [checkoutSession?.checkout?.shippingLine]);

  const isShippingAvailable = useMemo(() => {
    if (Object.values(addressValues).length < 5 || !storedShippingLine?.handle || !shippingRates.length) {
      return false;
    }

    return true;
  }, [checkoutSession, addressValues]);

  const billingAddressChoice = useMemo(() => {
    return [
      {
        id: 1,
        label: sameDelivveryAddressLabel,
      },
      {
        id: 2,
        label: differentDeliveryAddress,
      },
    ];
  }, [differentDeliveryAddress, sameDelivveryAddressLabel]);

  const isEmailReady = useMemo(() => {
    if (Boolean(email?.trim()) && loginStatus !== 'loading' && !errors.email && isEmailDomainValid) {
      return true;
    }
    return false;
  }, [email, errors, loginStatus, isEmailDomainValid]);

  useEffect(() => {
    const hasCheckoutEmail = checkoutSession?.checkout?.email;
    if (!hasCheckoutEmail || !storedShippingLine) {
      return setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [checkoutSession?.checkout?.email, storedShippingLine]);

  useEffect(() => {
    if (registerError) {
      if (registerError.data?.param === 'phone') {
        setError(
          'user.phone_number',
          { message: registerError.data.msg, type: 'required' },
          { shouldFocus: true },
        );
      }
    }
  }, [registerError, setError]);

  useEffect(() => {
    if (canAutoFill && currentUser) {
      setCurrentMail(currentUser.email);
      const {
        user: { billing_address, address, ...user },
      } = reshapeCurrentUserData(currentUser);

      for (const key in user) {
        // @ts-expect-error always defined
        if (is(Object, user[key])) {
          // @ts-expect-error always defined
          for (const subKey in user[key]) {
            // @ts-expect-error always defined
            setValue(`${key}.${subKey}`, user[key][subKey]);
          }
        }
        // @ts-expect-error always defined
        setValue(key, user[key]);
      }

      for (const key in billing_address) {
        // @ts-expect-error always defined
        setValue(`billing_address.${key}`, billing_address[key]);
      }
      for (const key in address) {
        // @ts-expect-error always defined
        setValue(`address.${key}`, address[key]);
      }
      setState({ canAutoFill: false, setLoadingPayment: false });
    }
  }, [canAutoFill, currentUser, setCurrentMail, setState, setValue]);

  useEffect(() => {
    if (freezedEmail && email && !errors.email && email !== freezedEmail) {
      const values = getValues();
      if (currentUser) {
        const {
          user: { billing_address, address, email, ...user },
        } = reshapeCurrentUserData(currentUser);
        for (const key in user) {
          // @ts-expect-error always defined
          if (is(Object, user[key])) {
            // @ts-expect-error always defined
            for (const subKey in user[key]) {
              // @ts-expect-error always defined
              setValue(`${key}.${subKey}`, '');
            }
          }

          if (key) {
            // @ts-expect-error always defined
            setValue(key, '');
          }
        }
        for (const key in address) {
          // @ts-expect-error always defined
          setValue(`address.${key}`, '');
        }
      } else if (values && !empty(values)) {
        const { user, address } = values;
        for (const key in user) {
          // @ts-expect-error always defined
          if (is(Object, user[key])) {
            // @ts-expect-error always defined
            for (const subKey in user[key]) {
              // @ts-expect-error always defined
              setValue(`${key}.${subKey}`, '');
            }
          }

          if (key) {
            // @ts-expect-error always defined
            setValue(key, '');
          }
        }

        for (const key in address) {
          // @ts-expect-error always defined
          setValue(`address.${key}`, '');
        }
      }

      setValue('billing_address', undefined);

      client.clear();
      setState({ email: email, setLoadingPayment: false });
      setValue('email', email);
    }
  }, [client, currentUser, email, errors.email, freezedEmail, setState, setValue]);

  const onSubmit = async (values: FormSchema) => {
    setState({ setLoadingPayment: true });
    if (shouldRegister) {
      // @ts-expect-error always defined
      await registerUser(values);
      await addSource({
        psp_type: 'stripe',
        // @ts-expect-error property is always defined
        card: {
          ...card_token?.card,
          // @ts-expect-error added_manually
          token_id: card_token?.token_id as string,
          name: `${values.user?.firstname} ${values.user?.lastname}`,
        },
        // take the second one wich is the billing address
        address: (values.billing_address ?? values.address) as RegisterPayloadAddress,
      });
      const {
        user: { firstname, lastname, phone_number },
      } = getValues();

      await createOrder({
        request_id: requestId as string,
        app_id: app_id as string,
        order: {
          payment_source_id: card_token?.id as string,
          checkoutId: checkoutSession?.checkout?.id,
          billing_address: {
            ...(values.billing_address ?? values.address),
            first_name: firstname,
            last_name: lastname,
            phone: phone_number,
          },
        },
      });
    } else {
      const {
        user: { firstname, lastname, phone_number },
      } = getValues();
      await createOrder({
        request_id: requestId as string,
        app_id: app_id as string,
        order: {
          payment_source_id: card_token?.id as string,
          checkoutId: checkoutSession?.checkout?.id,
          billing_address: {
            ...(values.billing_address ?? values.address),
            first_name: firstname,
            last_name: lastname,
            phone: phone_number,
          },
        },
      });
    }
  };

  const onInvalid = (errors: any) => {
    if (!isEmpty(errors)) {
      const missingFields = t('errors.commons.missing_fields');

      toast({
        description: (
          <Box display="flex" flexDirection="column">
            {Object.keys(errors)?.map((key) => {
              if (is(Object, errors[key])) {
                return Object.keys(errors[key]).map((subKey) => (
                  <Text key={subKey} fontSize="small">
                    {t(`inputs.${subKey}.label`)}
                  </Text>
                ));
              }

              return (
                <Text key={key} fontSize="small">
                  {t(`inputs.${key}.label`)}
                </Text>
              );
            })}
          </Box>
        ),
        title: missingFields,
        status: 'error',
        position: 'top',
        isClosable: true,
        duration: 10000,
      });
    }
  };
  const discount = checkoutSession?.checkout?.discountApplications?.edges?.[0]?.node;

  return (
    <>
      <Container
        as="main"
        marginTop="10px"
        flex="1 1 auto"
        overflow="auto"
        paddingLeft="16px"
        display="flex"
        flexDirection="column"
      >
        {!requireLoginChallenge ? <Details /> : null}
        <form name="just-checkout" id="just-checkout" noValidate>
          <Stack spacing={4}>
            {discount ? (
              <Box display="flex" justifyContent="center">
                <Button
                  fontWeight="bold"
                  fontSize="small"
                  borderRadius="30px"
                  colorScheme="purple"
                  width="95%"
                  onClick={props.toggleDiscount}
                >
                  {editDiscountButtonLabel}
                </Button>
              </Box>
            ) : (
              <Box display="flex" justifyContent="center" width="100%">
                <Button
                  fontWeight="bold"
                  fontSize="small"
                  borderRadius="30px"
                  colorScheme="purple"
                  width="97%"
                  onClick={props.toggleDiscount}
                >
                  {addDiscountButtonLabel}
                </Button>
              </Box>
            )}
            <Email
              setError={setError}
              clearErrors={clearErrors}
              trigger={trigger}
              setCurrentMail={setCurrentMail}
              setFocus={setFocus}
              setValue={setValue}
              register={register}
              error={errors?.email?.message}
              getValues={getValues}
            />
            <UserInput
              watch={watch}
              errors={errors.user}
              register={register}
              isEmailReady={isEmailReady}
              setValue={setValue}
              setFocus={setFocus}
            />
            <Address
              trigger={trigger}
              watch={watch}
              selector="address"
              control={control}
              billingChoice={billingChoice}
              // @ts-expect-error field always defined
              errors={errors.address}
              register={register}
              setValue={setValue}
              disabled={!isEmailReady}
              getValues={getValues}
            />
            <Box
              ref={isStripeOpen ? stripeRef : undefined}
              border={isStripeOpen || paymentStatus?.status == 'failed' ? '2px solid' : undefined}
              bgColor="white"
              borderColor={paymentStatus?.status == 'failed' ? 'red.500' : 'purple.500'}
              borderRadius="30px"
              style={{
                marginLeft: 5,
                marginRight: 5,
              }}
            >
              <Elements
                stripe={stripePromise}
                options={{
                  locale: language,
                  appearance: {
                    theme: 'none',
                    labels: 'floating',
                  },
                  fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Roboto' }],
                }}
              >
                <CardInput
                  watch={watch}
                  setValue={setValue}
                  isStripeOpen={isStripeOpen}
                  onOpenStripe={onOpenStripe}
                  inputError={errors.card?.owner?.message}
                  isEmailReady={isEmailReady}
                  getValues={getValues}
                  register={register}
                  token={token}
                />
              </Elements>
            </Box>
            <ShippingRates setValue={setValue} getValues={getValues} />
            <RadioGroup
              pl="2"
              pr="2"
              isDisabled={!isEmailReady}
              onChange={(value) => {
                if (parseInt(value, 10) === 1) {
                  setValue('billing_address', undefined);
                }
                onBillingChoiceChange(parseInt(value, 10));
              }}
              value={billingChoice}
            >
              <Stack spacing={2}>
                {billingAddressChoice.map((choice) => (
                  <Radio
                    colorScheme="purple"
                    key={choice.id}
                    value={choice.id}
                    size="lg"
                    maxW="100%"
                    _focus={{
                      border: '2px solid purple.500',
                    }}
                    border={choice.id !== billingChoice ? '2px solid purple' : undefined}
                  >
                    <Box display="flex" justifyContent="space-between" width="100%">
                      <Text fontSize="small" fontWeight="bold">
                        {choice.label}
                      </Text>
                    </Box>
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
            {billingChoice === 2 ? (
              <Address
                trigger={trigger}
                watch={watch}
                selector="billing_address"
                control={control}
                // @ts-expect-error field always defined
                errors={errors.billing_address}
                register={register}
                setValue={setValue}
                disabled={!isEmailReady}
                getValues={getValues}
              />
            ) : null}
          </Stack>
        </form>
      </Container>
      <Stack as="footer" bg="white" display="flex" flexBasis="50px" shadow="md" mt="2" width="100%" p="2">
        <Box mt="1">
          <Text
            className="terms"
            align="center"
            fontWeight="bold"
            fontSize="small"
            dangerouslySetInnerHTML={{
              __html: t('shopTerms', {
                shopName,
                shopTerms: policyData?.url,
              }),
            }}
          />
        </Box>

        <Box display="flex" mt={0} justifyContent="space-between" pb="2">
          <Button
            fontWeight="bold"
            fontSize="small"
            mr="1"
            bgColor="black"
            textColor="white"
            _hover={{
              bg: 'blackAlpha.800',
            }}
            borderRadius="20px"
            width="100%"
            disabled={isSubmitting}
            isLoading={isSubmitting}
            onClick={() => {
              return _postMessage({
                command_status: command_status,
                requestId: requestId as string,
                orderUrl: '',
                orderId: '',
                email: '',
                sessionKey: sessionKey as string,
                emitter: 'JUST_FORM',
                action: 'CLOSE',
              });
            }}
          >
            {cancelLabel}
          </Button>
          <Button
            fontWeight="bold"
            fontSize="small"
            width="100%"
            borderRadius="20px"
            ml="1"
            form="just-checkout"
            type={
              isSubmitting || canSubmit || !token || (billingChoice === 2 && !billingAddress)
                ? 'button'
                : 'submit'
            }
            colorScheme="purple"
            onClick={
              isSubmitting || canSubmit || !token || (billingChoice === 2 && !billingAddress)
                ? undefined
                : handleSubmit(onSubmit, onInvalid)
            }
            disabled={
              isSubmitting ||
              canSubmit ||
              !token ||
              (billingChoice === 2 && !billingAddress) ||
              !isShippingAvailable
            }
            isLoading={isSubmitting}
          >
            {confirmLabel}
          </Button>
        </Box>
      </Stack>
    </>
  );
};

export default Form;
