import {
  Box,
  Button,
  Collapse,
  Radio,
  HStack,
  Stack,
  useDisclosure,
  Text,
  Input,
  InputGroup,
  InputLeftAddon,
  Avatar,
} from '@chakra-ui/react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useIsFetching, useQueryClient } from 'react-query';
import { useTranslation } from 'next-i18next';
import { ArrowBackIcon, ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';

import { FormInput, FormSelectInput } from 'src/components/FormInput';
import { AutoComplete } from 'src/components/Autocomplete';
import { useOnClickOutside } from 'src/hooks/useOnClickOutside';
import { FormSchema } from 'src/hooks/useFormValidation';
import { reshapeCurrentUserData, ReshapedUserResponse, UserInfoResponse } from 'src/queries/useUserInfo';
import { useUserUpdate } from 'src/queries/useUserUpdate';
import { useUpdateCheckoutShippping } from 'src/queries/useUpdateCheckoutShippping';
import { MailingAddressInput } from 'src/types/create-checkout';
import { useStore } from 'src/business/store';
import { RegisterPayloadAddressEntry } from 'src/queries/useRegister';
import { useCountries } from 'src/queries/useCountries';
import { IconStatus } from 'src/components/LoginStatusInput';
import { COUNTRIES } from 'src/utils/countries';

type Props = {
  selector: 'address' | 'billing_address';
  getValues: UseFormGetValues<FormSchema>;
  setValue: UseFormSetValue<FormSchema>;
  errors: FieldErrors<FormSchema['address']>;
  disabled?: boolean;
  register: UseFormRegister<FormSchema>;
  control: Control<FormSchema>;
  watch: UseFormWatch<FormSchema>;
  trigger: UseFormTrigger<FormSchema>;
  billingChoice: number;
};

export const Address = ({
  getValues,
  setValue,
  selector,
  errors,
  disabled,
  register,
  billingChoice,
  control,
  watch,
  trigger,
}: Props) => {
  const { t } = useTranslation('common');
  const client = useQueryClient();
  const { data } = useCountries();
  const currentUser = client.getQueryData('USER_INFO') as UserInfoResponse | undefined;
  const isUserInfoFetching = useIsFetching('USER_INFO');
  const [selectedAddress, setSelectedAddress] = useState<
    ReshapedUserResponse['user'][typeof selector] | undefined
  >(undefined);
  const {
    checkoutSession,
    api_domain,
    api_token,
    isFormEdit,
    shouldRegister,
    shouldUpdateAddress,
    setState,
  } = useStore();
  const [reshapedUser, setReshapedUser] = useState<
    { addresses: ReshapedUserResponse['raw']['addresses']; current: ReshapedUserResponse['user'] } | undefined
  >(undefined);
  const [updateCheckoutShipping] = useUpdateCheckoutShippping();
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [isEditing, setIsEditing] = useState(false);
  const [updateProfile, { status }] = useUserUpdate();
  const ref = useRef<any>();
  const backButtonLabel = t('buttons.back');
  const saveButtonLabel = t('buttons.save');
  const shippingCommonLabel = t('commons.shipping').toUpperCase();
  const billingCommonLabel = t('commons.billing').toUpperCase();
  const address2Label = t('inputs.address2.label');
  const cityLabel = t('inputs.city.label');
  const countryLabel = t('inputs.country.label');
  const provinceLabel = t('inputs.province.label');
  const zipLabel = t('inputs.zip.label');
  const provincePlaceholder = t('inputs.province.placeholder');
  const address1Placeholder = t('inputs.address1.placeholder');
  const address2Placeholder = t('inputs.address2.placeholder');
  const cityPlaceholder = t('inputs.city.placeholder');
  const countryPlaceholder = t('inputs.country.placeholder');
  const zipPlaceholder = t('inputs.zip.placeholder');
  const billingAddress1Placeholder = t('inputs.billing_address1.placeholder');
  const addNewAddressLabel = t('commons.add_new_address');
  const selectedCountry = watch(`${selector}.country`);
  const user = watch('user');
  const watchedAddress = watch(selector) ?? {};

  async function updateAddress(restAddress: MailingAddressInput) {
    if (!user) return;

    await updateCheckoutShipping({
      variables: {
        shippingAddress: {
          ...restAddress,
          firstName: user.firstname,
          lastName: user.lastname,
          phone: user.phone_number,
        },
        checkoutId: checkoutSession?.checkout?.id as string,
      },
      domain: api_domain as string,
      token: api_token as string,
    });
  }

  const onAddressRadioChange = async (value: string) => {
    const address = reshapedUser?.addresses?.find((address) => address.id === value);

    ['address1', 'address2', 'city', 'country', 'zip', 'province', 'country_code'].forEach((key) => {
      // @ts-expect-error property is always defined
      setValue(`${selector}.${key}`, address[key]);
    });
    const {
      first_name,
      last_name,
      country_code,
      is_default_billing,
      id,
      is_default_shipping,
      ...addressUpdateCheckout
    } = address ?? {};
    if (selector === 'address') {
      updateAddress(addressUpdateCheckout);
    }
    setSelectedAddress(address);
    if (reshapedUser) {
      const nextAddresses = reshapedUser.addresses.map((addr) => {
        const { id, is_default_billing, is_default_shipping, ...newAddress } = addr;

        if (billingChoice === 2) {
          return {
            address: newAddress,
            is_default_billing: selector === 'billing_address' ? address?.id === addr.id : is_default_billing,
            is_default_shipping: selector === 'address' ? address?.id === addr.id : is_default_shipping,
          };
        } else {
          return {
            address: newAddress,
            is_default_billing: address?.id === addr.id,
            is_default_shipping: address?.id === addr.id,
          };
        }
      });

      await updateProfile({
        personal_informations: {
          first_name: reshapedUser?.current?.user?.firstname as string,
          last_name: reshapedUser?.current?.user?.lastname as string,
        },
        phone: user.phone_number,
        addresses: nextAddresses as RegisterPayloadAddressEntry[],
      });
    }
  };

  const onAddNewAddress = async () => {
    const addressToPut = getValues(`${selector}`);
    await trigger(selector);

    if (errors) {
      return;
    }
    if (currentUser) {
      const nextAddresses = currentUser.addresses.map((address) => ({
        address: address.address,
        is_default_billing: false,
        is_default_shipping: false,
      }));
      const userFromQuery = reshapeCurrentUserData(currentUser);

      // @ts-expect-error property is always defined
      const { id, is_default_billing, is_default_shipping, ...restOfAddressToPut } = addressToPut;
      await updateProfile({
        ...userFromQuery.raw,
        personal_informations: {
          first_name: user.firstname,
          last_name: user.lastname,
        },
        phone: user.phone_number,
        addresses: [
          ...nextAddresses,
          {
            address: {
              ...restOfAddressToPut,
              first_name: user.firstname,
              last_name: user.lastname,
              phone: user.phone_number,
            },
            is_default_billing: true,
            is_default_shipping: true,
          },
        ],
      });
    }

    onClose();
    setSelectedAddress(undefined);
    setIsEditing(false);
  };

  useEffect(() => {
    const watchedAddressValuesOnly = Object.values(watchedAddress ?? {}).filter(Boolean);
    if (watchedAddressValuesOnly?.length === 5 && watchedAddress && !isEditing) {
      // @ts-expect-error property is always defined
      const countryCode = COUNTRIES.find((item) => item.name === watchedAddress.country)?.code;

      if (countryCode) {
        setState({ shouldUpdateAddress: true });
        setValue(`${selector}.country_code`, countryCode as string);
      }
    }

    if (watchedAddressValuesOnly?.length === 6 && watchedAddress && !isEditing && shouldUpdateAddress) {
      if (!checkoutSession?.checkout?.shippingAddress && !selectedAddress) {
        setSelectedAddress(watchedAddress);
        setState({ shouldUpdateAddress: false });
        // @ts-expect-error property is always defined
        const { country_code, ...toSave } = watchedAddress;
        if (!shouldRegister) {
          onAddNewAddress();
          return;
        }
        updateAddress(toSave);
        return;
      } else {
        setState({ shouldUpdateAddress: false });
        const keysToCheck = ['address1', 'address2', 'city', 'country', 'zip'];
        const check = keysToCheck
          .filter((key) => {
            // @ts-expect-error property is always defined
            if (selectedAddress[key] !== checkoutSession?.checkout?.shippingAddress[key]) {
              return true;
            }
            return false;
          })
          .filter(Boolean);
        if (check.length >= 1) {
          // @ts-expect-error property is always defined
          const { country_code, ...toSave } = watchedAddress;
          setSelectedAddress(watchedAddress);
          if (!shouldRegister) {
            onAddNewAddress();
            return;
          }
          updateAddress(toSave);
          return;
        }
      }
    }
  }, [
    watchedAddress,
    Object.values(watchedAddress),
    checkoutSession,
    shouldRegister,
    selectedAddress,
    isEditing,
    shouldUpdateAddress,
  ]);

  useEffect(() => {
    if (
      !isUserInfoFetching &&
      currentUser &&
      reshapedUser?.addresses.length !== currentUser.addresses.length
    ) {
      const reshaped = reshapeCurrentUserData(currentUser);
      setReshapedUser({ addresses: reshaped.raw.addresses, current: reshaped.user });
      const defaultAddress = reshaped.user[selector];

      for (const key in defaultAddress) {
        if (key) {
          // @ts-expect-error property is always defined
          setValue(`${selector}.${key}`, defaultAddress[key]);
        }
      }
      setSelectedAddress(defaultAddress);
    }
  }, [isUserInfoFetching, currentUser, reshapedUser, selector]);

  useEffect(() => {
    if (
      !selectedAddress &&
      currentUser &&
      currentUser?.addresses &&
      !reshapedUser?.addresses &&
      !isUserInfoFetching
    ) {
      const reshaped = reshapeCurrentUserData(currentUser);
      const defaultAddress = reshaped.user[selector];
      for (const key in defaultAddress) {
        if (key) {
          // @ts-expect-error property is always defined
          setValue(`${selector}.${key}`, defaultAddress[key]);
        }
      }

      const {
        first_name,
        last_name,
        country_code,
        id,
        is_default_billing,
        is_default_shipping,
        ...addressUpdateCheckout
      } = defaultAddress;
      if (selector === 'address' && !isFormEdit) {
        updateAddress(addressUpdateCheckout);
      }
      setReshapedUser({ addresses: reshaped.raw.addresses, current: reshaped.user });
      setSelectedAddress({
        ...defaultAddress,
        first_name,
        last_name,
        country_code,
        id,
        is_default_billing,
        is_default_shipping,
      });
    }
  }, [reshapedUser, selectedAddress, selector, setValue, currentUser, isUserInfoFetching, isFormEdit]);

  useEffect(() => {
    if (
      !selectedAddress &&
      currentUser &&
      currentUser?.addresses &&
      currentUser?.addresses.length > (reshapedUser?.addresses?.length ?? 0) &&
      !isUserInfoFetching
    ) {
      const reshaped = reshapeCurrentUserData(currentUser);
      const defaultAddress = reshaped.user[selector];
      for (const key in defaultAddress) {
        if (key) {
          // @ts-expect-error property is always defined
          setValue(`${selector}.${key}`, defaultAddress[key]);
        }
      }

      setReshapedUser({ addresses: reshaped.raw.addresses, current: reshaped.user });
      setSelectedAddress(defaultAddress);
    }
  }, [reshapedUser, selectedAddress, selector, setValue, currentUser, isUserInfoFetching]);

  useOnClickOutside(ref, onToggle);

  const currentCountry = useMemo(
    () => data?.find((country) => country.label === selectedCountry),
    [data, selectedCountry],
  );

  if (!isEditing && reshapedUser?.addresses?.length) {
    const addressSelectedLabel = `${selectedAddress?.address1} - ${selectedAddress?.city ?? ''}`;
    const firstOne = reshapedUser.addresses[0];
    const firstOneAddressLabel = `${firstOne?.address1} - ${firstOne?.city ?? ''}`;

    return (
      <>
        <Stack
          spacing={2}
          bgColor="white"
          border={isOpen || Boolean(errors) ? '2px solid' : undefined}
          borderColor="purple.500"
          borderRadius="30px"
          style={{
            marginLeft: 5,
            marginRight: 5,
          }}
        >
          <Box ref={isOpen ? ref : undefined}>
            <InputGroup
              bgColor="transparent"
              borderRadius="30px"
              p="2"
              onClick={onToggle}
              alignItems="center"
            >
              <InputLeftAddon
                fontWeight="bold"
                fontSize="small"
                bgColor="transparent"
                border="none"
                minW={{
                  sm: '140px',
                  md: '160px',
                  base: '140px',
                }}
              >
                {!isOpen ? (
                  <Box width="80px" display="flex" justifyContent="space-between" alignItems="center">
                    <Text>{selector === 'address' ? shippingCommonLabel : billingCommonLabel}</Text>
                  </Box>
                ) : (
                  <Box display="flex" justifyContent="space-between" alignItems="center" width={'100%'}>
                    <Text>{selector === 'address' ? shippingCommonLabel : billingCommonLabel}</Text>
                    <Radio
                      onClick={() => {
                        if (firstOne?.id !== selectedAddress?.id) {
                          onAddressRadioChange(firstOne?.id as string);
                        }
                      }}
                      colorScheme="purple"
                      isChecked={firstOne?.id === selectedAddress?.id}
                    />
                  </Box>
                )}
              </InputLeftAddon>
              <Input
                fontWeight="bold"
                fontSize="small"
                variant="unstyled"
                disabled={!isOpen}
                cursor={isOpen ? 'pointer' : undefined}
                type="text"
                onClick={() => {
                  if (!isOpen) {
                    return;
                  }

                  if (firstOne?.id !== selectedAddress?.id) {
                    onAddressRadioChange(firstOne?.id as string);
                  }
                }}
                value={isOpen ? firstOneAddressLabel : addressSelectedLabel}
              />
              {!isOpen ? (
                <Avatar
                  size="sm"
                  bg={errors ? 'red.500' : 'purple.500'}
                  icon={<ChevronDownIcon fontSize="2em" color="white" />}
                />
              ) : (
                <Avatar
                  onClick={onClose}
                  position="absolute"
                  right="8px"
                  size="sm"
                  bg={errors ? 'red.500' : 'purple.500'}
                  icon={<ChevronUpIcon fontSize="2em" color="white" />}
                />
              )}
            </InputGroup>
            <Collapse in={isOpen} unmountOnExit={false}>
              <InputGroup bgColor="white" pl="2" pr="2" borderRadius="30px">
                <Stack spacing={2}>
                  {reshapedUser?.addresses?.length > 1 &&
                    reshapedUser?.addresses.map((address, index) => {
                      const mapAddressLabel = `${address?.address1} - ${address?.city ?? ''}`;
                      if (index === 0) return null;
                      return (
                        <Box
                          display="flex"
                          key={address.id}
                          borderRadius="30px"
                          mt={index === 0 ? '10px' : undefined}
                          backgroundColor="transparent"
                        >
                          <InputLeftAddon
                            fontWeight="bold"
                            fontSize="small"
                            backgroundColor="transparent"
                            border="none"
                            minW={{
                              sm: '140px',
                              md: '160px',
                              base: '140px',
                            }}
                            display="flex"
                            justifyContent="flex-end"
                          >
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              width={index === 0 ? '100%' : undefined}
                            >
                              {index === 0 && (
                                <Text>
                                  {selector === 'address' ? shippingCommonLabel : billingCommonLabel}
                                </Text>
                              )}
                              <Radio
                                onClick={() => {
                                  if (address?.id !== selectedAddress?.id) {
                                    onAddressRadioChange(address?.id as string);
                                  }
                                }}
                                colorScheme="purple"
                                isChecked={address?.id === selectedAddress?.id}
                              />
                            </Box>
                          </InputLeftAddon>
                          <Input
                            cursor="pointer"
                            onClick={() => {
                              if (address?.id !== selectedAddress?.id) {
                                onAddressRadioChange(address?.id as string);
                              }
                            }}
                            fontWeight="bold"
                            fontSize="small"
                            variant="unstyled"
                            textAlign="start"
                            type="button"
                            value={mapAddressLabel}
                          />
                          {index === 0 ? (
                            <Avatar
                              onClick={onClose}
                              position="absolute"
                              right="8px"
                              size="sm"
                              bg={errors ? 'red.500' : 'purple.500'}
                              icon={<ChevronUpIcon fontSize="2em" color="white" />}
                            />
                          ) : null}
                        </Box>
                      );
                    })}
                </Stack>
              </InputGroup>
              <InputGroup bgColor="white" borderRadius="30px" p="2" backgroundColor="transparent">
                <InputLeftAddon
                  fontWeight="bold"
                  fontSize="small"
                  bgColor="transparent"
                  border="none"
                  minW={{
                    sm: '140px',
                    md: '160px',
                    base: '140px',
                  }}
                />
                <Input
                  cursor="pointer"
                  onClick={() => {
                    ['address1', 'address2', 'city', 'country', 'zip', 'province', 'country_code'].forEach(
                      (key) => {
                        // @ts-expect-error property is always defined
                        setValue(`${selector}.${key}`, '');
                      },
                    );
                    onToggle();
                    setIsEditing(true);
                  }}
                  fontWeight="bold"
                  fontSize="small"
                  variant="unstyled"
                  textDecoration="underline"
                  textAlign="start"
                  type="button"
                  value={addNewAddressLabel}
                />
              </InputGroup>
            </Collapse>
          </Box>
        </Stack>
      </>
    );
  }

  if (reshapedUser?.addresses?.length && isEditing) {
    return (
      <div className="overlay show">
        <Button
          onClick={() => {
            ['address1', 'address2', 'city', 'country', 'zip', 'province', 'country_code'].forEach((key) => {
              // @ts-expect-error property is always defined
              setValue(`${selector}.${key}`, selectedAddress?.[key]);
            });
            setIsEditing(false);
          }}
          variant="ghost"
          position="absolute"
          top={2}
          left={2}
        >
          <ArrowBackIcon />
        </Button>
        <div className="overlay-content">
          <Stack ref={isOpen ? ref : undefined} spacing={4} p="2">
            <Box
              bgColor="white"
              border={isOpen ? '2px solid' : undefined}
              borderColor={isOpen ? 'purple.500' : undefined}
              borderRadius="30px"
            >
              <Controller
                control={control}
                name={`${selector}.address1`}
                render={({ field: { ref }, fieldState: { error, invalid } }) => (
                  <AutoComplete
                    isEditing={isEditing}
                    formControlProps={
                      !isOpen
                        ? {
                            border: '2px solid',
                            borderColor: 'purple.500',
                            borderRadius: '30px',
                          }
                        : undefined
                    }
                    selector={selector}
                    getValues={getValues}
                    trigger={trigger}
                    setValue={setValue}
                    placeholder={selector === 'address' ? address1Placeholder : billingAddress1Placeholder}
                    label={selector === 'address' ? shippingCommonLabel : billingCommonLabel}
                    error={error?.message}
                    invalid={invalid}
                    ref={ref}
                    disabled={disabled}
                    shouldUpdateCheckoutAddress={false}
                    onFocus={!isOpen ? onToggle : undefined}
                  />
                )}
              ></Controller>
              <Collapse in={isOpen} animateOpacity animate>
                <FormInput
                  type="text"
                  label={address2Label}
                  placeholder={address2Placeholder}
                  error={(errors?.address2 as { message: string })?.message}
                  inputRegister={{
                    ...register(`${selector}.address2`, { disabled: disabled }),
                  }}
                />
                <FormInput
                  type="text"
                  label={cityLabel}
                  placeholder={cityPlaceholder}
                  error={(errors?.city as { message: string })?.message}
                  inputRegister={{ ...register(`${selector}.city`, { disabled: disabled }) }}
                />
                <FormInput
                  type="text"
                  label={provinceLabel}
                  error={(errors?.province as { message: string })?.message}
                  inputRegister={{ ...register(`${selector}.province`, { disabled: disabled }) }}
                />
                <FormInput
                  label={zipLabel}
                  placeholder={zipPlaceholder}
                  error={(errors?.zip as { message: string })?.message}
                  inputRegister={{
                    ...register(`${selector}.zip`, {
                      disabled: disabled,
                      validate: {
                        match: (value) => {
                          if (currentCountry?.regex) {
                            return new RegExp(currentCountry?.regex ?? '').test(value);
                          }
                          return true;
                        },
                      },
                    }),
                  }}
                />
                <FormSelectInput
                  type="text"
                  options={data ?? undefined}
                  label={countryLabel}
                  placeholder={countryPlaceholder}
                  error={(errors?.country as { message: string })?.message}
                  inputRegister={{ ...register(`${selector}.country`, { disabled: disabled }) }}
                />
              </Collapse>
            </Box>
            <HStack display="flex" wrap="nowrap" spacing={2} pt={2}>
              <Button
                fontWeight="bold"
                fontSize="small"
                bgColor="black"
                textColor="white"
                _hover={{
                  bg: 'blackAlpha.800',
                }}
                borderRadius="20px"
                width="100%"
                disabled={status === 'loading'}
                isLoading={status === 'loading'}
                onClick={() => {
                  ['address1', 'address2', 'city', 'country', 'zip', 'province', 'country_code'].forEach(
                    (key) => {
                      // @ts-expect-error property is always defined
                      setValue(`${selector}.${key}`, selectedAddress?.[key]);
                    },
                  );
                  onClose();
                  setIsEditing(false);
                }}
              >
                {backButtonLabel}
              </Button>
              <Button
                fontWeight="bold"
                fontSize="small"
                disabled={status === 'loading'}
                isLoading={status === 'loading'}
                borderRadius="20px"
                colorScheme="purple"
                width="100%"
                onClick={onAddNewAddress}
              >
                {saveButtonLabel}
              </Button>
            </HStack>
          </Stack>
        </div>
      </div>
    );
  }

  return (
    <Stack
      spacing={2}
      ref={isOpen ? ref : undefined}
      border={isOpen || Boolean(errors) ? '2px solid' : undefined}
      borderColor="purple.500"
      bgColor="white"
      borderRadius="30px"
      style={{
        marginLeft: 5,
        marginRight: 5,
      }}
    >
      <Stack spacing={2}>
        {!isOpen && (
          <InputGroup
            bgColor="white"
            borderRadius="30px"
            p="2"
            onClick={!disabled ? onToggle : undefined}
            display="flex"
            alignItems="center"
          >
            <InputLeftAddon
              fontWeight="bold"
              fontSize="small"
              bgColor="transparent"
              border="none"
              minW={{
                sm: '140px',
                md: '160px',
                base: '140px',
              }}
              // eslint-disable-next-line react/no-children-prop
              children={
                <Box width="80px" display="flex" justifyContent="space-between" alignItems="center">
                  <Text>{selector === 'address' ? shippingCommonLabel : billingCommonLabel}</Text>
                </Box>
              }
            />
            <Input
              fontWeight="bold"
              fontSize="small"
              variant="unstyled"
              disabled
              type="text"
              placeholder={selector === 'address' ? address1Placeholder : billingAddress1Placeholder}
              value={getValues(`${selector}.address1`)}
            />
            {errors ? (
              <Avatar size="sm" bg="red.500" icon={<IconStatus status="error" />} />
            ) : getValues(`${selector}.address1`) ? (
              <Avatar size="sm" bg="purple.500" icon={<IconStatus status="success" />} />
            ) : null}
          </InputGroup>
        )}
        <Collapse in={isOpen} animateOpacity animate>
          <Controller
            control={control}
            name={`${selector}.address1`}
            render={({ field: { ref }, fieldState: { error, invalid } }) => (
              <AutoComplete
                isEditing={isEditing}
                trigger={trigger}
                selector={selector}
                getValues={getValues}
                setValue={setValue}
                placeholder={selector === 'address' ? address1Placeholder : billingAddress1Placeholder}
                label={selector === 'address' ? shippingCommonLabel : billingCommonLabel}
                error={error?.message}
                invalid={invalid}
                ref={ref}
                disabled={disabled}
                shouldUpdateCheckoutAddress={selector === 'address'}
                onFocus={!isOpen ? onToggle : undefined}
              />
            )}
          ></Controller>
          <Stack spacing={2}>
            <FormInput
              type="text"
              label={address2Label}
              placeholder={address2Placeholder}
              error={(errors?.address2 as { message: string })?.message}
              inputRegister={{
                ...register(`${selector}.address2`, { disabled: disabled }),
              }}
            />
            <FormInput
              type="text"
              label={cityLabel}
              placeholder={cityPlaceholder}
              error={(errors?.city as { message: string })?.message}
              inputRegister={{
                ...register(`${selector}.city`, {
                  disabled: disabled,
                  onChange: (event) => {
                    setValue(`${selector}.zip`, event.target.value);
                    if (!shouldUpdateAddress) {
                      setState({ shouldUpdateAddress: true });
                    }
                  },
                }),
              }}
            />
            <FormInput
              type="text"
              label={provinceLabel}
              placeholder={provincePlaceholder}
              error={(errors?.province as { message: string })?.message}
              inputRegister={{
                ...register(`${selector}.province`, {
                  disabled: disabled,
                  onChange: (event) => {
                    setValue(`${selector}.zip`, event.target.value);
                    if (!shouldUpdateAddress) {
                      setState({ shouldUpdateAddress: true });
                    }
                  },
                }),
              }}
            />
            <FormInput
              label={zipLabel}
              placeholder={zipPlaceholder}
              error={(errors?.zip as { message: string })?.message}
              inputRegister={{
                ...register(`${selector}.zip`, {
                  disabled: disabled,
                  onChange: (event) => {
                    setValue(`${selector}.zip`, event.target.value);
                    if (!shouldUpdateAddress) {
                      setState({ shouldUpdateAddress: true });
                    }
                  },
                  validate: {
                    match: (value) => {
                      if (currentCountry?.regex) {
                        return new RegExp(currentCountry?.regex ?? '').test(value);
                      }
                      return true;
                    },
                  },
                }),
              }}
            />
            <FormSelectInput
              type="text"
              options={data ?? undefined}
              label={countryLabel}
              placeholder={countryPlaceholder}
              error={(errors?.country as { message: string })?.message}
              inputRegister={{
                ...register(`${selector}.country`, {
                  disabled: disabled,
                  onChange: (event) => {
                    setValue(`${selector}.country`, event.target.value);
                    if (!shouldUpdateAddress) {
                      setState({ shouldUpdateAddress: true });
                    }
                  },
                }),
              }}
            />
          </Stack>
        </Collapse>
      </Stack>
    </Stack>
  );
};
