import { Box, Image, Stack, Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

export const CartProductMeta = (props: any) => {
  const { t } = useTranslation();
  const { image, name, description, quantity } = props;
  const quantityLabel = t('commons.quantity');

  return (
    <Stack display="flex" direction="row" spacing={2} width="full" align="center">
      {image ? (
        <Image
          rounded="lg"
          width="40px"
          height="40px"
          fit="cover"
          src={image}
          alt={name}
          draggable="false"
          loading="lazy"
        />
      ) : (
        <Box width="40px" height="40px" />
      )}
      <Box pt="4">
        <Stack spacing="0.5">
          <Text fontWeight="medium">{name}</Text>
          <Text color="white" fontSize="sm">
            {description}
          </Text>
          <Text fontWeight="bold" color="white" fontSize="sm">
            {quantityLabel}: {quantity}
          </Text>
        </Stack>
      </Box>
    </Stack>
  );
};
