import {
  Collapse,
  FormControl,
  FormErrorMessage,
  Stack,
  useDisclosure,
  Input,
  InputGroup,
  InputLeftAddon,
  Box,
  Avatar,
} from '@chakra-ui/react';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import { ChevronDownIcon } from '@chakra-ui/icons';

import { FormInput } from 'src/components/FormInput';
import { PhoneInput } from 'src/components/PhoneInput';
import { useOnClickOutside } from 'src/hooks/useOnClickOutside';
import { Translations } from 'src/types/translations';

import type {
  FieldErrors,
  UseFormRegister,
  UseFormSetFocus,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import type { FormSchema } from 'src/hooks/useFormValidation';
import { IconStatus } from 'src/components/LoginStatusInput';
import { useStore } from 'src/business/store';

type Props = {
  errors?: FieldErrors<Partial<FormSchema['user']>>;
  isEmailReady?: boolean;
  setValue: UseFormSetValue<FormSchema>;
  register: UseFormRegister<FormSchema>;
  setFocus: UseFormSetFocus<FormSchema>;
  watch: UseFormWatch<FormSchema>;
};

export const UserInput = ({ errors, isEmailReady, watch, setValue, register, setFocus }: Props) => {
  const { t } = useTranslation<'common', keyof Translations>('common');
  const { shouldRegister } = useStore();
  const { isOpen, onToggle, onClose, onOpen } = useDisclosure();
  const ref = useRef<any>();
  const firstNameLabel = t('inputs.firstname.label');
  const lastNameLabel = t('inputs.lastname.label');
  const phoneLabel = t('inputs.phone_number.label');
  const firstNamePlaceholder = t('inputs.firstname.placeholder');
  const lastNamePlaceholder = t('inputs.lastname.placeholder');
  const commonNameLabel = t('commons.name');
  const { firstname, lastname, phone_number: phone } = watch('user') ?? {};
  const isUserValid = useMemo(() => {
    if (firstname && lastname && phone && !errors) {
      return true;
    }
    return false;
  }, [errors, firstname, lastname, phone]);

  useOnClickOutside(
    ref,
    !errors
      ? onToggle
      : () => {
          console.log('');
        },
  );

  useEffect(() => {
    if (isOpen) {
      setFocus('user.firstname');
    }
  }, [isOpen, setFocus]);

  return (
    <Box
      style={{
        marginLeft: 5,
        marginRight: 5,
      }}
      border={isOpen || errors ? '2px solid' : undefined}
      borderColor={errors ? 'red.500' : 'purple.500'}
      bgColor="white"
      borderRadius="30px"
    >
      <Box ref={isOpen || Boolean(errors) ? ref : undefined}>
        {isOpen ? (
          <FormInput
            type="text"
            label={firstNameLabel}
            error={(errors?.firstname as { message: string })?.message}
            placeholder={firstNamePlaceholder}
            showUpIcon={!shouldRegister}
            upIconOnClick={onClose}
            inputRegister={{
              ...register('user.firstname', {
                disabled: !isEmailReady,
              }),
            }}
          />
        ) : (
          <FormControl
            onFocus={!isOpen && isEmailReady ? onToggle : undefined}
            isInvalid={Boolean((errors?.firstname as { message: string })?.message)}
          >
            <InputGroup bgColor="white" borderRadius="30px" p="2" display="flex" alignItems="center">
              <InputLeftAddon
                bgColor="transparent"
                border="none"
                minW={{
                  sm: '140px',
                  md: '160px',
                  base: '140px',
                }}
                fontWeight="bold"
                fontSize="small"
                // eslint-disable-next-line react/no-children-prop
                children={commonNameLabel.toUpperCase()}
              />
              <Input
                fontWeight="bold"
                fontSize="small"
                variant="unstyled"
                isReadOnly
                disabled={!isEmailReady}
                id={`input-name`}
                placeholder={firstNamePlaceholder}
                type="text"
                autoComplete="on"
                value={lastname ? `${firstname} ${lastname}` : firstname}
              />
              {isUserValid && shouldRegister ? (
                <Avatar size="sm" bg="purple.500" icon={<IconStatus status="success" />} />
              ) : isUserValid ? (
                <Avatar
                  size="sm"
                  bg={errors ? 'red.500' : 'purple.500'}
                  icon={<ChevronDownIcon onClick={onOpen} fontSize="2em" color="white" />}
                />
              ) : null}
              {errors ? <Avatar size="sm" bg="red.500" icon={<IconStatus status="error" />} /> : null}
            </InputGroup>
          </FormControl>
        )}
        <Collapse in={isOpen || Boolean(errors)} animateOpacity unmountOnExit={false}>
          <Stack spacing={2}>
            <FormInput
              type="text"
              label={lastNameLabel}
              placeholder={lastNamePlaceholder}
              error={(errors?.lastname as { message: string })?.message}
              inputRegister={{
                ...register('user.lastname', { disabled: !isEmailReady }),
              }}
            />
            <PhoneInput
              label={phoneLabel}
              value={phone}
              error={(errors?.phone_number as { message: string })?.message}
              setValue={setValue}
            />
          </Stack>
        </Collapse>
      </Box>
    </Box>
  );
};
