import { useMutation as useReactQueryMutation, useQueryClient, QueryClient, MutationKey } from 'react-query';

import { MutationRequest } from 'src/api/mutation';
import { MutationQueryState } from 'src/api/queryState';
import { getMutationFetcher } from 'src/api/fetcher';
import { QueryError } from 'src/api/queryError';
import { RetryValue } from 'react-query/types/core/retryer';

export type Mutate<TPayload = void, TResult = void> = (payload: TPayload) => Promise<TResult>;

export type Reset = () => void;

export type MutationState<TPayload = void, TResult = void, TError = unknown> = [
  Mutate<TPayload, TResult>,
  MutationQueryState<TResult, TError>,
  Reset,
];

export function useMutation<
  TPayload extends object & { endpointParams?: object },
  TResult = any,
  TRawResult = any,
  TError = any,
>(input: {
  request: MutationRequest<TPayload>;
  reshapeData(data: TRawResult): TResult;
  options?: {
    retry?: RetryValue<QueryError<TError>>;
    mutationKey?: MutationKey;
    throwOnError?: boolean;
    onMutate?(mutateBag: { payload: TPayload; client: QueryClient }): Promise<void>;
    onSuccess?(successBag: {
      rawData: TRawResult;
      data: TResult;
      client: QueryClient;
      payload: TPayload;
    }): void;
    onError?(errorBag: { error: QueryError<TError>; client: QueryClient; payload: TPayload }): void;
  };
}): MutationState<TPayload, TResult, TError>;

export function useMutation<
  TPayload extends object & { endpointParams?: object },
  TResult extends object,
  TRawResult extends object = any,
  TError extends object = any,
>({
  request,
  reshapeData,
  options,
}: {
  request: MutationRequest<TPayload>;
  reshapeData?(data: TRawResult): TResult;
  options?: {
    retry?: RetryValue<QueryError<TError>>;
    mutationKey?: MutationKey;
    throwOnError?: boolean;
    onMutate?(mutateBag: { payload: TPayload; client: QueryClient }): Promise<void>;
    onSuccess?(successBag: {
      rawData: TRawResult | void;
      data: TResult | void;
      client: QueryClient;
      payload: TPayload;
    }): void;
    onError?(errorBag: { error: QueryError<TError>; client: QueryClient; payload: TPayload }): void;
  };
}): MutationState<TPayload, TResult | void, TError> {
  const queryClient = useQueryClient();
  const mutationFetcher = getMutationFetcher<TRawResult, TPayload>({
    request,
  });
  const { mutateAsync, reset, status, data, error } = useReactQueryMutation<
    TRawResult,
    QueryError<TError>,
    TPayload
  >(mutationFetcher, {
    ...options,
    // @ts-expect-error property is always defined
    retry: (failureCount, error) => options?.retry?.(failureCount, error),
    mutationKey: options?.mutationKey,
    onMutate: (payload) =>
      options?.onMutate?.({
        payload,
        client: queryClient,
      }),
    onSuccess: (data, payload) =>
      options?.onSuccess?.({
        payload,
        rawData: data,
        data: wrapReshapeData(data, reshapeData),
        client: queryClient,
      }),
    onError: (error, payload) =>
      options?.onError?.({
        payload,
        error,
        client: queryClient,
      }),
  });

  const mutateAndReshape = async (payload: TPayload): Promise<TResult | void> => {
    const rawResult = await mutateAsync(payload);
    return wrapReshapeData(rawResult, reshapeData);
  };

  if (status === 'loading' || status === 'idle') {
    return [
      mutateAndReshape,
      {
        status,
        data: null,
      },
      reset,
    ];
  }

  if (status === 'error') {
    return [
      mutateAndReshape,
      {
        status: 'error',
        error: error!,
        data: null,
      },
      reset,
    ];
  }

  return [
    mutateAndReshape,
    {
      status: 'success',
      data: reshapeData && data ? reshapeData(data) : undefined,
    },
    reset,
  ];
}

function wrapReshapeData<TRawResult, TResult>(
  data: TRawResult | undefined,
  reshapeData: ((data: TRawResult) => TResult) | undefined,
): TResult | void {
  try {
    return reshapeData && data ? reshapeData(data) : undefined;
  } catch (error) {
    return undefined;
  }
}
