import { useToast } from '@chakra-ui/react';

import { MutationQueryState, reportQueryError } from 'src/api';
import { useStore } from 'src/business/store';
import { Reset, useMutation } from 'src/hooks/useMutation';
import { useQueryError } from 'src/components/QueryError/useQueryError';
import { setInternalApiAuthHeaders } from 'src/api/axios';
import { setStorage } from 'src/commons';
import { JustApiToken } from 'src/types';

export type RefreshTokenPayload = {
  user_id: string;
  refresh_token: string;
  client_secret: string;
  client_id: string;
};

export const useRefreshToken = (): [
  ({ user_id, refresh_token }: { user_id: string; refresh_token: string }) => Promise<JustApiToken>,
  MutationQueryState<JustApiToken, any>,
  Reset,
] => {
  const { setState, loginSession } = useStore();
  const toast = useToast();
  const queryError = useQueryError({});

  const [refresh, response, reset] = useMutation<RefreshTokenPayload, JustApiToken>({
    request: {
      type: 'rest',
      method: 'post',
      endpoint: '/v1/auth/refresh',
      target: 'just',
    },
    reshapeData: (data) => data,
    options: {
      mutationKey: 'MUTATION/REFRESH_TOKEN',
      throwOnError: true,
      // @ts-expect-error ts...
      onMutate: () => {
        setState({
          isFormDisabled: true,
        });
      },
      onError: ({ error }) => {
        const title = queryError(error);
        toast({
          title,
          isClosable: true,
          status: 'error',
          position: 'top',
        });
        reportQueryError(error);
        setState({
          isFormDisabled: false,
          challengeSession: undefined,
          setLoadingPayment: false,
        });
      },
      onSuccess: ({ data, client }) => {
        setStorage<JustApiToken>('token' as string, {
          ...data,
          user_id: loginSession?.user_id as string,
        });
        setInternalApiAuthHeaders(data.access_token);
        setState({
          challengeSession: data,
          canAutoFill: false,
          isFormDisabled: false,
          setLoadingPayment: true,
          shouldFetchUserInfo: true,
        });

        client.invalidateQueries('USER_INFO');
      },
    },
  });

  return [
    ({ user_id, refresh_token }: { user_id: string; refresh_token: string }) =>
      refresh({
        user_id,
        refresh_token,
        client_secret: process.env.NEXT_PUBLIC_API_CLIENT_SECRET as string,
        client_id: 'web',
      }),
    response,
    reset,
  ];
};

export const useBasicRefreshToken = (): [
  ({ user_id, refresh_token }: { user_id: string; refresh_token: string }) => Promise<JustApiToken>,
  MutationQueryState<JustApiToken, any>,
  Reset,
] => {
  const { setState, loginSession } = useStore();
  const toast = useToast();
  const queryError = useQueryError({});

  const [refresh, response, reset] = useMutation<RefreshTokenPayload, JustApiToken>({
    request: {
      type: 'rest',
      method: 'post',
      endpoint: '/v1/auth/refresh',
      target: 'just',
    },
    reshapeData: (data) => data,
    options: {
      mutationKey: 'MUTATION/REFRESH_TOKEN',
      throwOnError: true,
      onError: ({ error }) => {
        const title = queryError(error);
        toast({
          title,
          isClosable: true,
          status: 'error',
          position: 'top',
        });
        reportQueryError(error);
        setState({
          challengeSession: undefined,
        });
      },
      onSuccess: ({ data }) => {
        setStorage<JustApiToken>('token' as string, {
          ...data,
          user_id: loginSession?.user_id as string,
        });
        setInternalApiAuthHeaders(data.access_token);
      },
    },
  });

  return [
    ({ user_id, refresh_token }: { user_id: string; refresh_token: string }) =>
      refresh({
        user_id,
        refresh_token,
        client_secret: process.env.NEXT_PUBLIC_API_CLIENT_SECRET as string,
        client_id: 'web',
      }),
    response,
    reset,
  ];
};
