import { Box, Flex, Stack, Tag, TagLabel, Text } from '@chakra-ui/react';
import { useStore } from 'src/business/store';
import { MoneyV2 } from 'src/types/checkout';
import { formatPrice } from 'src/utils/money';
import { OrderSummaryItem } from 'src/components/Details/OrderSummaryItem';

export const CartOrderSummary = () => {
  const { checkoutSession } = useStore();

  const checkout = checkoutSession?.checkout;
  const discount = checkout?.discountApplications?.edges?.[0]?.node;

  const discountText =
    discount?.value?.__typename === 'PricingPercentageValue'
      ? `- ${discount?.value?.percentage} %`
      : `- ${formatPrice((discount?.value as MoneyV2)?.amount, {
          currency: (discount?.value as MoneyV2)?.currencyCode,
        })}`;

  return (
    <Stack
      spacing={4}
      // borderWidth="1px"
      rounded="lg"
      width="full"
      color="white"
    >
      <Stack spacing="6">
        <OrderSummaryItem
          label="Subtotal"
          value={formatPrice(checkout?.lineItemsSubtotalPrice.amount, {
            currency: checkout?.lineItemsSubtotalPrice?.currencyCode ?? '',
          })}
        />
        <OrderSummaryItem
          label="Shipping"
          value={formatPrice(checkout?.shippingLine?.priceV2?.amount ?? 0, {
            currency: checkout?.shippingLine?.priceV2?.currencyCode,
          })}
        ></OrderSummaryItem>
        {discount ? (
          <OrderSummaryItem label="Discount">
            <Box>
              {discount ? (
                <Tag borderRadius="full" size="sm" variant="solid" colorScheme="green" marginRight="5px">
                  <TagLabel>{discount?.code ?? discount?.title}</TagLabel>
                </Tag>
              ) : null}
              {discountText}
            </Box>
          </OrderSummaryItem>
        ) : null}

        <Flex justify="space-between">
          <Text fontSize="lg" fontWeight="semibold">
            Total
          </Text>
          <Text fontSize="xl" fontWeight="extrabold">
            {formatPrice(checkout?.totalPriceV2?.amount, {
              currency: checkout?.totalPriceV2?.currencyCode,
            })}
          </Text>
        </Flex>
      </Stack>
    </Stack>
  );
};
