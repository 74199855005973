import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { reportQueryError } from 'src/api';
import { useStore } from 'src/business/store';
import { DEFAULT_EMAIL_ADDRESS } from 'src/constants';
import { useMutation } from 'src/hooks/useMutation';
import { useUpdateCheckoutEmail } from 'src/queries/useUpdateCheckoutEmail';

export type CreateOrderPayload = {
  app_id: string;
  request_id: string;
  order: {
    [key: string]: any;
    payment_source_id: string;
  };
};

export const useCreateOrder = () => {
  const { setState, requestId, api_domain, api_token, checkoutSession } = useStore();
  const { t } = useTranslation('common');
  const toast = useToast();
  const [updateEmail] = useUpdateCheckoutEmail();

  return useMutation<CreateOrderPayload, any>({
    request: {
      type: 'rest',
      method: 'post',
      endpoint: '/v2/orders',
      target: 'just',
    },
    reshapeData: (data) => data,
    options: {
      throwOnError: true,
      onError: ({ error }) => {
        if (error.type === 'RequestError' && error?.status === 409) {
          setState({
            paymentStatus: {
              display: true,
              url: error.data.next_action,
            },
            orderId: error.data.orderId,
            shouldFetchOrderRequest: true,
          });
        } else {
          setState({ setLoadingPayment: false });
          toast({
            description: t('errors.commons.order_failed'),
            status: 'error',
            position: 'top',
            isClosable: true,
          });
        }

        reportQueryError(error);
      },
      onSuccess: async ({ data, client }) => {
        setState({
          order: data,
          orderId: data.id,
          shouldFetchOrderRequest: true,
        });

        client.invalidateQueries(['ORDER_REQUEST', requestId]);

        await updateEmail({
          variables: {
            checkoutId: checkoutSession?.checkout?.id as string,
            email: DEFAULT_EMAIL_ADDRESS as string,
          },
          // @ts-expect-error field always defined
          domain: api_domain,
          // @ts-expect-error field always defined
          token: api_token,
        });
      },
    },
  });
};
