import axios, { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { fromAxiosError, QueryError, reportQueryError } from 'src/api';

export type CountriesResponses = {
  name: {
    common: string;
  };
  postalCode: {
    format: string;
    regex: string;
  };
  cca2: string;
  ccn3: string;
  cca3: string;
  cioc: string;
  flag: string;
};

const countryFetcher = async (): Promise<CountriesResponses[]> => {
  const source = axios.CancelToken.source();

  const promise = axios.get('https://restcountries.com/v3.1/all', { cancelToken: source.token });
  // @ts-expect-error property always defined
  promise.cancel = source.cancel;

  return promise
    .then((response) => response.data)
    .catch(async (axiosError: AxiosError) => {
      throw await fromAxiosError(axiosError);
    });
};

export const useCountries = () => {
  const queryState = useQuery('QUERY/COUNTRIES', countryFetcher, {
    refetchOnWindowFocus: false,
    onError: (error: QueryError<unknown>) => {
      reportQueryError(error);
    },
  });

  if (queryState.status === 'loading' || queryState.status === 'idle') {
    return {
      status: 'loading',
      refetch: queryState.refetch,
      data: null,
    };
  }

  if (queryState.status === 'error') {
    return {
      status: 'error',
      error: queryState.error,
      refetch: queryState.refetch,
      data: null,
    };
  }

  return {
    status: 'success',
    data: queryState.data
      ?.map((country) => ({
        label: country.name.common,
        value: country.cca2,
        flag: country.flag,
        regex: country?.postalCode?.regex,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)),
    refetch: queryState.refetch,
  };
};
