import { v4 as uuid } from 'uuid';

import { reportQueryError } from 'src/api';
import { useStore } from 'src/business/store';
import { getStorage } from 'src/commons';
import { FormSchema } from 'src/hooks/useFormValidation';
import { useQuery } from 'src/hooks/useQuery';
import { useRefreshToken } from 'src/queries/useRefreshToken';
import { RegisterPayloadAddressEntry } from 'src/queries/useRegister';
import { JustApiToken } from 'src/types';
import { useUpdateCheckoutEmail } from 'src/queries/useUpdateCheckoutEmail';
import { useUpdateCheckoutShippping } from 'src/queries/useUpdateCheckoutShippping';
import { pick } from 'ramda';
import { DEFAULT_EMAIL_ADDRESS } from 'src/constants';

export type UserInfoResponse = {
  updated_at: string;
  created_at: string;
  personal_informations: {
    first_name: string;
    last_name: string;
  };
  addresses: RegisterPayloadAddressEntry[];
  customer_id: string;
  id: string;
  email: string;
  phone: string;
};

export type ReshapedUserResponse = {
  user: Partial<Omit<FormSchema, 'address' | 'billing_address'>> & {
    address: Partial<{
      id: string;
      address1: string;
      address2: string;
      city: string;
      country: string;
      zip: string;
      province: string;
      first_name: string;
      last_name: string;
      country_code: string;
      is_default_billing: boolean;
      is_default_shipping: boolean;
    }>;
    billing_address: Partial<{
      id: string;
      address1: string;
      address2: string;
      city: string;
      country: string;
      zip: string;
      province: string;
      first_name: string;
      last_name: string;
      country_code: string;
      is_default_billing: boolean;
      is_default_shipping: boolean;
    }>;
  };
  raw: Omit<UserInfoResponse, 'addresses'> & {
    addresses: Partial<{
      id: string;
      address1: string;
      address2: string;
      city: string;
      country: string;
      zip: string;
      province: string;
      first_name: string;
      last_name: string;
      country_code: string;
      is_default_billing: boolean;
      is_default_shipping: boolean;
    }>[];
  };
};

export const useUserInfo = () => {
  const {
    setState,
    shouldFetchUserInfo,
    loginSession,
    checkoutSession,
    api_domain,
    api_token,
    command_status,
    isFormEdit,
  } = useStore();
  const [refreshToken] = useRefreshToken();
  const [updateCheckoutEmail] = useUpdateCheckoutEmail();
  const [updateCheckoutShipping] = useUpdateCheckoutShippping();

  // TODO: TYPE RETURN TYPE
  return useQuery<ReshapedUserResponse, UserInfoResponse>({
    key: 'USER_INFO',
    request: {
      type: 'rest',
      endpoint: `/v1/auth/me`,
      target: 'just',
    },
    reshapeData: reshapeCurrentUserData,
    isEnabled: shouldFetchUserInfo && Boolean(checkoutSession?.checkout) && Boolean(command_status),
    options: {
      onSuccess: async ({ data }) => {
        if (
          checkoutSession?.checkout &&
          (!checkoutSession?.checkout?.email || checkoutSession?.checkout?.email === DEFAULT_EMAIL_ADDRESS)
        ) {
          await updateCheckoutEmail({
            variables: {
              checkoutId: checkoutSession?.checkout?.id,
              email: data.user.email as string,
            },
            domain: api_domain as string,
            token: api_token as string,
          });
        }
        if (checkoutSession?.checkout && !checkoutSession?.checkout?.shippingAddress) {
          const {
            first_name,
            last_name,
            id,
            is_default_billing,
            is_default_shipping,
            country_code,
            ...addressToPut
          } = data?.user?.address ?? {};
          if (addressToPut && !isFormEdit) {
            updateCheckoutShipping({
              variables: {
                shippingAddress: {
                  ...addressToPut,
                  firstName: data?.user?.user?.firstname as string,
                  lastName: data?.user?.user?.lastname as string,
                  phone: data?.user?.user?.phone_number as string,
                },
                checkoutId: checkoutSession?.checkout?.id as string,
              },
              domain: api_domain as string,
              token: api_token as string,
            });
          }
        }

        setState({
          shouldFetchUserInfo: false,
          canAutoFill: true,
          isFormDisabled: false,
        });
      },
      onError: ({ error }) => {
        if (error.type === 'RequestError' && error.status === 401) {
          const token = getStorage<JustApiToken>('token');
          refreshToken({
            user_id: loginSession?.user_id as string,
            refresh_token: token?.refresh_token as string,
          });
        } else {
          setState({
            shouldFetchUserInfo: false,
            setLoadingPayment: false,
            isFormDisabled: false,
          });
        }
        reportQueryError(error);
      },
    },
  });
};

export const reshapeCurrentUserData = (data: UserInfoResponse): ReshapedUserResponse => {
  const withId = data.addresses?.map((address) => ({ ...address, id: uuid() }));
  const address = withId.find((address) => address.is_default_shipping);
  const billing_address = withId.find((address) => address.is_default_billing) ?? address;

  return {
    user: {
      email: data.email,
      user: {
        lastname: data?.personal_informations?.last_name,
        firstname: data?.personal_informations?.first_name,
        phone_number: data?.phone,
      },
      address: formatAddress(address),
      billing_address: formatAddress(billing_address),
    },
    raw: {
      ...data,
      addresses: withId.map((rawAddress) => formatAddress(rawAddress)),
    },
  };
};

export const formatAddress = (
  rawAddress?: UserInfoResponse['addresses'][0] & { id?: string },
): Partial<{
  id: string;
  address1: string;
  address2: string;
  city: string;
  country: string;
  zip: string;
  province: string;
  first_name: string;
  last_name: string;
  country_code: string;
  is_default_billing: boolean;
  is_default_shipping: boolean;
}> => {
  if (!rawAddress) {
    return {};
  }

  const { is_default_billing, is_default_shipping, id, address } = rawAddress;
  const pickedValues = pick(
    ['address1', 'address2', 'city', 'country', 'zip', 'province', 'first_name', 'last_name', 'country_code'],
    address,
  );

  return {
    ...pickedValues,
    is_default_billing,
    is_default_shipping,
    id,
  };
};
