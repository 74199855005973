import { useEffect, useState } from 'react';
import { intervalToDuration } from 'date-fns';
import { useTranslation } from 'next-i18next';
import { useStore } from 'src/business/store';
import { _postMessage } from 'src/commons';
import { Text } from '@chakra-ui/react';

export const CountDown = ({ start, until }: { start: boolean; until: number }) => {
  const { t } = useTranslation('common');
  const [distance, setDistance] = useState('');
  const { orderUrl, requestId, orderId, sessionKey, email, checkoutSession, expiration } = useStore();

  const redirect = () => {
    // @ts-expect-error always defined
    window.top.location.replace(orderUrl);
  };

  useEffect(() => {
    if (start) {
      const dist = intervalToDuration({
        end: new Date().getTime() - new Date(until).getTime(),
        start: 0,
      });

      setDistance(
        t('info.updateOrderUntil', {
          min: dist.minutes,
          sec: dist.seconds?.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
          }),
        }),
      );
      const interval = setInterval(() => {
        const distance = new Date().getTime() - new Date(until).getTime();

        if (distance >= 0) {
          clearInterval(interval);
          redirect();
          _postMessage({
            command_status: 'CREATED',
            requestId: requestId as string,
            expiration,
            orderUrl: orderUrl as string,
            orderId: orderId as string,
            email: email as string,
            sessionKey: sessionKey as string,
            checkoutSessionId: checkoutSession?.checkout?.id as string,
            emitter: 'JUST_FORM',
          });
        }
        const dist = intervalToDuration({
          end: new Date().getTime() - new Date(until).getTime(),
          start: 0,
        });

        setDistance(
          t('info.updateOrderUntil', {
            min: dist.minutes,
            sec: dist.seconds?.toLocaleString('en-US', {
              minimumIntegerDigits: 2,
            }),
          }),
        );
      }, 1000);
    }
  }, [start]);
  return <Text fontWeight="bold">{distance}</Text>;
};
