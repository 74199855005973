import { QueryState, reportQueryError } from 'src/api';
import { useStore } from 'src/business/store';
import { useQuery } from 'src/hooks/useQuery';
import { NextSellerResponseData } from 'src/types/internal';
import { useCreateCheckout } from 'src/queries/useCreateCheckout';
import { useApplyDiscount } from 'src/queries/useApplyDiscount';

export const useSeller = (): QueryState<NextSellerResponseData> => {
  const { app_id, data: lineItems, setState, discountCode, email, command_status } = useStore();
  const [createCheckout] = useCreateCheckout();
  const [applyDiscount] = useApplyDiscount();

  return useQuery<NextSellerResponseData>({
    key: ['SELLER', app_id],
    request: {
      type: 'rest',
      endpoint: `/api/seller/${app_id}`,
      target: 'internal',
    },
    isEnabled: Boolean(app_id),
    reshapeData: (data) => data,
    options: {
      onError: ({ error }) => {
        reportQueryError(error);
      },
      onSuccess: async ({ data }) => {
        if (command_status !== 'CREATED') {
          const createCheckoutResponse = await createCheckout({
            domain: data.domain,
            token: data.storefrontToken,
            variables: {
              input: {
                email: email?.trim() ? email : undefined,
                lineItems: lineItems?.map((item) => ({
                  quantity: item.quantity,
                  variantId: Buffer.from(
                    `gid://shopify/ProductVariant/${item.variantId.toString()}`,
                  ).toString('base64'),
                })),
                allowPartialAddresses: true,
              },
            },
          });

          if (discountCode) {
            applyDiscount({
              domain: data.domain,
              token: data.storefrontToken,
              variables: {
                discountCode,
                checkoutId: Buffer.from(createCheckoutResponse?.checkout?.id ?? '').toString('base64'),
              },
            });
          }
          setState({ api_domain: data.domain, api_token: data.storefrontToken, shopName: data.name });
        } else {
          setState({
            api_domain: data.domain,
            api_token: data.storefrontToken,
            shouldFetchOrderRequest: true,
            shopName: data.name,
          });
        }
      },
    },
  });
};
