import { Modal, ModalOverlay, ModalContent, ModalBody, useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';

type Props = {
  url: string;
};

export const Overlay3DSecure = ({ url }: Props) => {
  // @ts-expect-error property is always defined
  const iframeRef: React.MutableRefObject<HTMLIFrameElement> = useRef<HTMLIFrameElement>();
  const { onClose } = useDisclosure();

  useEffect(() => {
    url && iframeRef?.current?.setAttribute('src', url);
  }, [url]);

  return (
    <>
      <Modal size="full" isOpen={true} onClose={onClose}>
        <ModalOverlay />
        <ModalContent margin={0} rounded="none" padding={0}>
          <ModalBody padding={0}>
            <iframe
              style={{ width: window.innerWidth, height: window.innerHeight }}
              id="just_iframe3ds"
              className="iframe3ds"
              ref={iframeRef}
              src={url}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
