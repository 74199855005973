import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { QueryState, reportQueryError } from 'src/api';
import { useStore } from 'src/business/store';
import { _postMessage } from 'src/commons';
import { useQuery } from 'src/hooks/useQuery';

type PaymentIntent = {
  status:
    | 'pending'
    | 'requires_action'
    | 'requires_payment_method'
    | 'succeeded'
    | 'requires_confirmation'
    | 'canceled'
    | 'requires_source_action'
    | 'processing'
    | 'requires_capture'
    | 'requires_destination_action'
    | 'failed';
  id: string;
  redirectUrl?: string;
  error?: string;
  [key: string]: unknown;
};

export type OrderResponsePayload = {
  order: any;
  paymentIntent: Partial<PaymentIntent>;
};

export const useOrderRequest = (): QueryState<OrderResponsePayload> => {
  const { app_id, setState, requestId, shouldFetchOrderRequest, email, command_status, sessionKey } =
    useStore();
  const { t } = useTranslation('common');

  const toast = useToast();
  return useQuery<OrderResponsePayload>({
    key: ['ORDER_REQUEST', requestId],
    request: {
      type: 'rest',
      endpoint: `/v1/order-request/${requestId}`,
      target: 'just',
    },
    isEnabled: Boolean(app_id) && Boolean(shouldFetchOrderRequest) && Boolean(email),
    reshapeData: (data) => data,
    options: {
      onError: ({ error }) => {
        reportQueryError(error);
        setState({
          shouldFetchOrderRequest: false,
          setLoadingPayment: false,
          command_status: 'ERROR',
          paymentStatus: {
            status: 'failed',
            display: false,
          },
        });
      },
      onSuccess: async ({ data, client }) => {
        if (data.paymentIntent.status === 'requires_payment_method') {
          setState({
            order: data.order,
            orderId: data.order.id,
            shouldFetchOrderRequest: false,
            shouldFetchOrderPage: false,
            setLoadingPayment: false,
            command_status: 'ERROR',
            orderUrl: undefined,
            paymentStatus: {
              status: 'failed',
              display: false,
            },
          });

          _postMessage({
            command_status: 'CANCELLED',
            requestId: requestId as string,
            orderUrl: '',
            orderId: '',
            email: '',
            sessionKey: sessionKey as string,
            emitter: 'JUST_FORM',
          });

          toast({
            description: t(`errors.stripe.${data?.paymentIntent?.error}`),
            title: t(`errors.commons.paymentError`),
            status: 'error',
            position: 'top',
            isClosable: true,
            duration: 10000,
          });
        } else {
          setState({
            order: data.order,
            orderId: data.order.id,
            shouldFetchOrderRequest: false,
            expiration: data.order?.until * 1000,
            shouldFetchOrderPage:
              data.paymentIntent.status === 'requires_capture' || command_status === 'CREATED',
          });
        }

        client.invalidateQueries(['ORDER_PAGE', requestId]);
      },
    },
  });
};
