import { Spinner as DefaultSpinner } from '@chakra-ui/react';
import React from 'react';

export const Spinner = () => (
  <div className="overlay show">
    <div className="overlay-content">
      <DefaultSpinner thickness="4px" speed="0.65s" emptyColor="purple.100" color="purple.500" size="xl" />
    </div>
  </div>
);
