import { ArrowBackIcon } from '@chakra-ui/icons';
import {
  Stack,
  Box,
  Button,
  Heading,
  CloseButton,
  Spinner,
  Stat,
  StatNumber,
  Tag,
  TagLabel,
} from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';

import { useStore } from 'src/business/store';
import { FormInput } from 'src/components/FormInput';
import { useForm } from 'react-hook-form';
import { useApplyDiscount } from 'src/queries/useApplyDiscount';
import { DiscountApplication } from 'src/types/create-checkout';
import { useRemoveDiscount } from 'src/queries/useRemoveDiscount';
import { getCurrencySymbol } from 'src/commons';

function useDebounce(value: string, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
}

export const DiscountCodeOverlay = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation('common');
  const applyButtonLabel = t('buttons.apply');
  const {
    register,
    formState: { errors },
    setFocus,
    setError,
    clearErrors,
    watch,
  } = useForm({
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const [applyDiscount, { status: applyLoading }] = useApplyDiscount();
  const [removeDiscount, { status: removeLoading }] = useRemoveDiscount();
  const { api_domain, api_token, checkoutSession, language } = useStore();
  const discountTitle = t('commons.discount_title');
  const discountTitleAuto = t('commons.discount_title_auto');
  const discountInputLabel = t('commons.discount_code');
  const [discount, setDiscount] = useState<DiscountApplication | null>(null);
  const code = watch('code');
  const debounced = useDebounce(code, 800);

  const fetchData = async () => {
    if (debounced && checkoutSession && api_domain && api_token && applyLoading !== 'loading') {
      const result = await applyDiscount({
        domain: api_domain,
        token: api_token,
        variables: {
          checkoutId: Buffer.from(checkoutSession?.checkout?.id ?? '').toString('base64'),
          discountCode: debounced,
        },
      });

      const error = result?.checkoutUserErrors?.[0];
      const discount = result?.checkout?.discountApplications?.edges?.[0]?.node;

      if (discount) {
        setDiscount(discount);
        clearErrors('code');
      }

      if (error) {
        setError('code', {
          message: error.message,
        });
      }
    }
  };

  useEffect(() => {
    fetchData();
    setFocus('code');
  }, [debounced]);

  useEffect(() => {
    const error = checkoutSession?.checkoutUserErrors?.[0];
    const discount = checkoutSession?.checkout?.discountApplications?.edges?.[0]?.node as DiscountApplication;

    if (error) {
      setDiscount(null);
      setError('code', {
        message: error.message,
      });
    }

    if (discount) {
      setDiscount(discount);
    }
  }, [checkoutSession]);

  const onRemove = async () => {
    if (checkoutSession && api_domain && api_token) {
      const result = await removeDiscount({
        domain: api_domain,
        token: api_token,
        variables: {
          checkoutId: Buffer.from(checkoutSession?.checkout?.id ?? '').toString('base64'),
        },
      });

      const discount = result?.checkout?.discountApplications?.edges?.[0]?.node;

      if (discount) {
        setDiscount(discount);
      } else {
        setDiscount(null);
      }
    }
  };

  const isAutoDiscount = discount?.__typename === 'AutomaticDiscountApplication';
  return (
    <div className="overlay show">
      <Button onClick={onClose} variant="ghost" position="absolute" top={2} left={2}>
        <ArrowBackIcon />
      </Button>
      <div className="overlay-content">
        <Stack spacing={6}>
          <Box display="flex" justifyContent="center">
            {isAutoDiscount ? (
              <Heading padding="0px 20px" size="l">
                {discountTitleAuto}
              </Heading>
            ) : (
              <Heading>{discountTitle}</Heading>
            )}
          </Box>
          {!isAutoDiscount ? (
            <Box display="flex" justifyContent="center">
              <Stack
                minW="260"
                spacing={2}
                display="flex"
                justifyContent="center"
                flexWrap="wrap"
                flexDirection="column"
              >
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                  name="discount-form"
                  id="discount-form"
                  noValidate
                >
                  <FormInput
                    border="2px solid"
                    borderColor={errors?.code?.message ? 'red.500' : 'purple.500'}
                    borderRadius="30px"
                    shadow="md"
                    type="text"
                    placeholder="XXXX"
                    label={discountInputLabel}
                    inputRegister={{ ...register('code') }}
                  />

                  {applyLoading === 'loading' ? (
                    <Stack
                      minW="260"
                      spacing={2}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      flexWrap="wrap"
                      flexDirection="column"
                      marginTop="20px"
                    >
                      <Spinner />
                    </Stack>
                  ) : (
                    <Button
                      fontWeight="bold"
                      fontSize="small"
                      width="100%"
                      marginTop="20px"
                      borderRadius="20px"
                      ml="1"
                      colorScheme="purple"
                      onClick={fetchData}
                    >
                      {applyButtonLabel}
                    </Button>
                  )}
                </form>
              </Stack>
            </Box>
          ) : null}
          <Box display="flex" justifyContent="center">
            <Stack
              minW="260"
              spacing={2}
              display="flex"
              justifyContent="center"
              flexWrap="wrap"
              flexDirection="column"
            >
              {discount && removeLoading !== 'loading' ? (
                <Stat>
                  {isAutoDiscount ? null : <CloseButton float="right" onClick={onRemove} />}
                  <Tag
                    mb="4"
                    borderRadius="full"
                    textAlign="center"
                    size="lg"
                    variant="solid"
                    colorScheme="green"
                  >
                    <TagLabel>{discount?.title}</TagLabel>
                  </Tag>
                  {discount.value.__typename === 'PricingPercentageValue' ? (
                    <StatNumber>- {discount?.value?.percentage} %</StatNumber>
                  ) : (
                    <StatNumber>
                      - {discount?.value?.amount}
                      {getCurrencySymbol(language as string, discount?.value?.currencyCode as string)}
                    </StatNumber>
                  )}
                </Stat>
              ) : null}

              {removeLoading === 'loading' ? <Spinner /> : null}
            </Stack>
          </Box>
        </Stack>
      </div>
    </div>
  );
};
