import { Text } from '@chakra-ui/react';

export const Price = (props: any) => {
  const { isOnSale, children, textProps } = props;
  const defaultColor = 'white';
  const onSaleColor = 'white';
  const color = isOnSale ? onSaleColor : defaultColor;
  return (
    <Text
      as="span"
      fontWeight="bold"
      color={color}
      textDecoration={isOnSale ? 'line-through' : 'none'}
      {...textProps}
    >
      {children}
    </Text>
  );
};
