import { reportQueryError } from 'src/api';
import { useStore } from 'src/business/store';
import { useMutation } from 'src/hooks/useMutation';

import type { CreateCheckoutPayload } from 'src/types/create-checkout';
import type { CheckoutCreateResponse } from 'src/types/checkout-response';
import { captureCheckoutAnalytics, getStorage } from 'src/commons';
import { JustApiToken } from 'src/types';

export const useCreateCheckout = () => {
  const { setState, api_domain } = useStore();

  return useMutation<CreateCheckoutPayload, CheckoutCreateResponse, CheckoutCreateResponse>({
    request: {
      type: 'rest',
      method: 'post',
      endpoint: '/api/create-checkout',
      target: 'internal',
    },
    reshapeData: (data) => data,
    options: {
      throwOnError: true,
      onError: ({ error }) => {
        reportQueryError(error);
      },
      onSuccess: ({ data }) => {
        const token = getStorage<JustApiToken>('token');
        captureCheckoutAnalytics({
          user: token ? token.user_id : undefined,
          registered: token ? false : true,
          checkoutSession: data,
          shop: api_domain as string,
          step: 'CHECKOUT_CREATE',
        });
        setState({ checkoutSession: data, checkoutSessionId: data?.checkout?.id });
      },
    },
  });
};
