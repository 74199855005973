import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  InputGroup,
  InputLeftAddon,
} from '@chakra-ui/react';
import React, { useEffect, useState, forwardRef } from 'react';
import {
  AutoComplete as ChakraAutocomplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from '@choc-ui/chakra-autocomplete';
import { v4 as uuid } from 'uuid';
import { isEmpty } from 'ramda';

import { fetchDetails, fetchPredictions } from 'src/queries/useLocation/fetcher';
import { UseFormGetValues, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { FormSchema } from 'src/hooks/useFormValidation';
import { useStore } from 'src/business/store';

type Props = {
  label: string;
  invalid?: boolean;
  error?: string;
  onFocus?: () => void;
  onChange?: (value: string) => void;
  disabled?: boolean;
  getValues: UseFormGetValues<FormSchema>;
  setValue: UseFormSetValue<FormSchema>;
  shouldUpdateCheckoutAddress?: boolean;
  selector: 'address' | 'billing_address';
  placeholder?: string;
  formControlProps?: FormControlProps;
  trigger: UseFormTrigger<FormSchema>;
  isEditing?: boolean;
};

export const AutoComplete = forwardRef(
  (
    {
      label,
      invalid,
      error,
      onFocus,
      shouldUpdateCheckoutAddress,
      disabled,
      getValues,
      setValue,
      selector,
      placeholder,
      formControlProps,
      trigger,
      isEditing,
    }: Props,
    ref: any,
  ) => {
    const { checkoutSession, setState } = useStore();
    const [searchWord, setSearchWord] = useState<string | null>(null);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [searchDetailId, setSearchDetailId] = useState<string | null>(null);
    const [predictions, setPredictions] = useState<
      | {
          id: string;
          label: string;
        }[]
      | null
    >([]);
    const sessionToken = uuid();

    useEffect(() => {
      async function getPredictions(searchWord: string) {
        const result = await fetchPredictions(sessionToken, searchWord);
        setPredictions(result?.concat({ label: searchWord, id: 'fakeid' }) ?? []);
      }
      if (searchWord) {
        getPredictions(searchWord);
      }
    }, [searchWord]);
    const addresValues = getValues(selector);

    useEffect(() => {
      async function getDetails() {
        const details = await fetchDetails(searchDetailId as string, sessionToken);
        if (details) {
          for (const key in details) {
            // @ts-expect-error property is always defined
            setValue(`${selector}.${key}`, details[key]);
          }
          trigger(selector);
        }
      }

      const { address1, ...rest } = addresValues ?? {};
      // @ts-expect-error property is always defined
      if (searchDetailId && searchDetailId !== 'fakeid' && isEmpty(rest?.country)) {
        getDetails();
      }
    }, [searchDetailId, addresValues, trigger, setValue, selector]);

    return (
      <FormControl {...formControlProps} isInvalid={invalid} width="100%">
        <ChakraAutocomplete
          onSelectOption={(event) => {
            if (event.selectMethod === 'keyboard') {
              ['address1', 'address2', 'city', 'country', 'zip', 'province', 'country_code'].forEach(
                (key) => {
                  // @ts-expect-error property is always defined
                  setValue(`${selector}.${key}`, '');
                },
              );
              const currentItem = predictions?.find((item) => item.label === event.item.value);
              if (currentItem?.id !== 'fakeid') {
                if (!isEditing) {
                  setState({ shouldUpdateAddress: true });
                }
                setSearchWord(currentItem?.label as string);
                setSearchDetailId(currentItem?.id as string);
                return;
              } else {
                setSearchWord(currentItem?.label as string);
                setValue(`${selector}.address1`, currentItem?.label as string);
                return;
              }
            }
          }}
        >
          <InputGroup bgColor="white" borderRadius="30px" p="2">
            <InputLeftAddon
              bgColor="transparent"
              border="none"
              minW={{
                sm: '140px',
                md: '160px',
                base: '140px',
              }}
              color={isFocused && !error ? 'purple.500' : error ? 'red.500' : undefined}
              fontWeight="bold"
              fontSize="small"
              // eslint-disable-next-line react/no-children-prop
              children={label.toUpperCase()}
            />
            <AutoCompleteInput
              onFocus={() => {
                setIsFocused(true);
                onFocus?.();
              }}
              fontWeight="bold"
              fontSize="small"
              onBlur={() => setIsFocused(false)}
              ref={ref}
              placeholder={placeholder}
              disabled={disabled}
              variant="unstyled"
              isInvalid={Boolean(error)}
              value={searchWord ?? ''}
              onChange={(event) => {
                event.preventDefault();
                if (!event.target.value) {
                  ['address1', 'address2', 'city', 'country', 'zip', 'province', 'country_code'].forEach(
                    (key) => {
                      // @ts-expect-error property is always defined
                      setValue(`${selector}.${key}`, '');
                    },
                  );
                }
                if (!event.target.value && shouldUpdateCheckoutAddress) {
                  setState({
                    checkoutSession: {
                      ...(checkoutSession ?? {}),
                      // @ts-expect-error property is always defined
                      checkout: {
                        ...(checkoutSession?.checkout ?? {}),
                        shippingLine: null,
                        availableShippingRates: null,
                      },
                    },
                  });
                }

                // setValue(`${selector}.address1`, event.target.value);
                setSearchWord(event.target.value);
              }}
            />
          </InputGroup>
          <AutoCompleteList mt="-2" width="100%">
            {predictions?.map?.((prediction) => (
              <AutoCompleteItem
                key={`option-${prediction?.id}`}
                onClick={() => {
                  ['address1', 'address2', 'city', 'country', 'zip', 'province', 'country_code'].forEach(
                    (key) => {
                      // @ts-expect-error property is always defined
                      setValue(`${selector}.${key}`, '');
                    },
                  );
                  if (prediction?.id === 'fakeid') {
                    setValue(`${selector}.address1`, prediction.label);
                    setSearchWord(prediction.label);
                    return;
                  }

                  if (!isEditing) {
                    setState({ shouldUpdateAddress: true });
                  }
                  setSearchWord(prediction.label);
                  setSearchDetailId(prediction.id);
                }}
                value={prediction.label}
                textTransform="capitalize"
              >
                {prediction.label}
              </AutoCompleteItem>
            ))}
          </AutoCompleteList>
        </ChakraAutocomplete>
        {Boolean(error) && (
          <FormErrorMessage fontWeight="bold" ml="20px">
            {error}
          </FormErrorMessage>
        )}
      </FormControl>
    );
  },
);

AutoComplete.displayName = 'JustAutoComplete';
