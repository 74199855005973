import { reportQueryError } from 'src/api';
import { useStore } from 'src/business/store';
import { useMutation } from 'src/hooks/useMutation';
import { ApplyDiscountPayload } from 'src/types/create-checkout';

export const useApplyDiscount = () => {
  const { setState, checkoutSession } = useStore();

  // TODO: TYPE CREATE CHECKOUT
  return useMutation<ApplyDiscountPayload, any>({
    request: {
      type: 'rest',
      method: 'post',
      endpoint: '/api/apply-discount',
      target: 'internal',
    },
    reshapeData: (data) => data,
    options: {
      throwOnError: true,
      onError: ({ error }) => {
        reportQueryError(error);
      },
      onSuccess: ({ data: { checkout } }) => {
        setState({
          checkoutSession: {
            ...checkoutSession,
            // @ts-expect-error property is always defined
            checkout: {
              ...(checkoutSession?.checkout ?? {}),
              lineItems: checkout.lineItems,
              discountApplications: checkout.discountApplications,
              availableShippingRates: checkout.availableShippingRates,
              lineItemsSubtotalPrice: checkout.lineItemsSubtotalPrice,
              subtotalPriceV2: checkout.subtotalPriceV2,
              totalPriceV2: checkout.totalPriceV2,
              totalTaxV2: checkout.totalTaxV2,
            },
          },
        });
      },
    },
  });
};
