import axios, { AxiosInstance } from 'axios';

import { fromAxiosError, reportQueryError } from 'src/api/queryError';

const isBrowser = typeof Window !== 'undefined';

const baseConfig = {
  baseURL: process.env.NEXT_PUBLIC_JUST_API_URL,
  timeout: 1 * 60 * 1000,
};

const buildInternalApi = (baseURL: string): AxiosInstance => {
  const internalApi = axios.create({
    ...baseConfig,
    baseURL,
  });

  internalApi.interceptors.response.use(
    (response) => response,
    async (error) => {
      const queryError = await fromAxiosError(error);
      reportQueryError(queryError);
      return Promise.reject(error);
    },
  );

  return internalApi;
};

const justAPI = axios.create(baseConfig);
const internalApi = axios.create({
  ...baseConfig,
  baseURL: isBrowser ? new URL(window.location.href).origin : '',
});

justAPI.interceptors.response.use(
  (response) => response,
  async (error) => {
    const queryError = await fromAxiosError(error);
    reportQueryError(queryError);
    return Promise.reject(error);
  },
);

internalApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    const queryError = await fromAxiosError(error);
    reportQueryError(queryError);
    return Promise.reject(error);
  },
);

export const setInternalApiAuthHeaders = (token: string) => {
  justAPI.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export { justAPI, internalApi, buildInternalApi };

export const getAxiosInstance = (target: 'just' | 'internal'): AxiosInstance => {
  switch (target) {
    case 'just':
      return justAPI;
    case 'internal':
      return internalApi;
    default:
      throw new Error(`Unknown target: ${target}`);
  }
};
