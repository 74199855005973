import { reportQueryError } from 'src/api';
import { useStore } from 'src/business/store';
import { useMutation } from 'src/hooks/useMutation';
import { useToast } from '@chakra-ui/react';
import { useQueryClient } from 'react-query';

import { useCreateCheckout } from 'src/queries/useCreateCheckout';
import { useApplyDiscount } from 'src/queries/useApplyDiscount';

import type { UpdateEmailCheckout } from 'src/types/update-email-checkout';
import type { CheckoutEmailUpdateV2Response } from 'src/types/checkout-response';
import { _postMessage } from 'src/commons';

export const useUpdateCheckoutEmail = () => {
  const {
    setState,
    checkoutSession,
    data: lineItems,
    api_domain,
    api_token,
    discountCode,
    command_status,
    requestId,
    orderUrl,
    orderId,
    sessionKey,
    expiration,
  } = useStore();
  const [createCheckout] = useCreateCheckout();
  const [applyDiscount] = useApplyDiscount();
  const client = useQueryClient();
  const toast = useToast();

  return useMutation<UpdateEmailCheckout, CheckoutEmailUpdateV2Response, CheckoutEmailUpdateV2Response>({
    request: {
      type: 'rest',
      method: 'post',
      endpoint: '/api/update-checkout-email',
      target: 'internal',
    },
    reshapeData: (data) => data,
    options: {
      mutationKey: 'MUTATION/UPDATE_CHECKOUT_EMAIL',
      throwOnError: true,
      onError: ({ error, payload }) => {
        /**
         * When changing email, shopify sessions gets invalidated.
         * So we create a new session based on the Error code from shopify.
         */
        async function updateCheckout() {
          const hasInvalidEmail = (error as any)?.data?.checkoutUserErrors?.find(
            (err: any) => err.code === 'BAD_DOMAIN',
          );
          const createCheckoutResponse = await createCheckout({
            domain: api_domain as string,
            token: api_token as string,
            variables: {
              input: {
                email: hasInvalidEmail ? undefined : payload.variables.email,
                lineItems: lineItems?.map((item) => ({
                  quantity: item.quantity,
                  variantId: Buffer.from(
                    `gid://shopify/ProductVariant/${item.variantId.toString()}`,
                  ).toString('base64'),
                })),
                allowPartialAddresses: true,
              },
            },
          });

          if (discountCode && createCheckoutResponse?.checkout?.id) {
            await applyDiscount({
              domain: api_domain as string,
              token: api_token as string,
              variables: {
                checkoutId: Buffer.from(createCheckoutResponse?.checkout?.id).toString('base64'),
                discountCode,
              },
            });
          }
          client.executeMutation({
            mutationKey: 'MUTATION/LOGIN',
            variables: {
              client_secret: process.env.NEXT_PUBLIC_API_CLIENT_SECRET as string,
              client_id: 'web',
              email: payload.variables.email as string,
              state: 'LOGIN_STATE',
              code_challenge: 'LOGIN_CHALLENGE',
              domain: api_domain as string,
              token: api_token as string,
            },
          });
        }

        if (error.type === 'RequestError') {
          if (error?.data?.checkoutUserErrors?.length) {
            error?.data?.checkoutUserErrors.map(async (error: any) => {
              if (error.code === 'BAD_DOMAIN') {
                setState({ isEmailDomainValid: false, setLoadingPayment: false });
                toast({
                  status: 'error',
                  description: error.message,
                  title: error.code,
                  position: 'top',
                  isClosable: true,
                });
              }

              if (error.code === 'INVALID') {
                updateCheckout();
              }
            });
          }
        }
        reportQueryError(error);
      },
      onSuccess: ({ data }) => {
        setState({
          isEmailDomainValid: true,
          checkoutSession: {
            ...(checkoutSession ?? {}),
            checkout: {
              ...(checkoutSession?.checkout ?? {}),
              email: data?.checkout?.email,
              // @ts-expect-error property is always defined
              id: data?.checkout?.id,
            },
          },
        });

        if (command_status === 'CREATED') {
          _postMessage({
            command_status: 'CREATED',
            requestId: requestId as string,
            expiration,
            orderUrl: orderUrl as string,
            orderId: orderId as string,
            email: data?.checkout?.email as string,
            sessionKey: sessionKey as string,
            checkoutSessionId: data?.checkout?.id as string,
            emitter: 'JUST_FORM',
          });
        }
      },
    },
  });
};
