import { useToast } from '@chakra-ui/react';

import { MutationQueryState, reportQueryError } from 'src/api';
import { useStore } from 'src/business/store';
import { Reset, useMutation } from 'src/hooks/useMutation';
import { useQueryError } from 'src/components/QueryError/useQueryError';
import { setInternalApiAuthHeaders } from 'src/api/axios';
import { setStorage } from 'src/commons';
import { JustApiToken } from 'src/types';

export type ChallengePayload = {
  client_secret: string;
  client_id: string;
  code: string;
  state: string;
  session_id: string;
  user_id: string;
};

export const useLoginChallenge = (): [
  (code: string) => Promise<JustApiToken>,
  MutationQueryState<JustApiToken, any>,
  Reset,
] => {
  const { setState, loginSession } = useStore();
  const toast = useToast();
  const queryError = useQueryError({});

  const [login, response, reset] = useMutation<ChallengePayload, JustApiToken>({
    request: {
      type: 'rest',
      method: 'post',
      endpoint: '/v1/auth/challenge',
      target: 'just',
    },
    reshapeData: (data) => data,
    options: {
      mutationKey: 'MUTATION/LOGIN_CHALLENGE',
      throwOnError: true,
      onError: ({ error }) => {
        const title = queryError(error);
        toast({
          description: title,
          isClosable: true,
          status: 'error',
          position: 'top',
          duration: 10000,
        });
        reportQueryError(error);
        setState({
          challengeSession: undefined,
          setLoadingPayment: false,
        });
      },
      onSuccess: ({ data }) => {
        setStorage<JustApiToken>('token', {
          ...data,
          user_id: loginSession?.user_id as string,
        });
        setInternalApiAuthHeaders(data.access_token);
        setState({
          challengeSession: data,
          requireLoginChallenge: false,
          canAutoFill: true,
          shouldFetchUserInfo: true,
          setLoadingPayment: true,
        });
      },
    },
  });

  return [
    (code: string) =>
      login({
        code: code.toString(),
        client_secret: process.env.NEXT_PUBLIC_API_CLIENT_SECRET as string,
        client_id: 'web',
        state: 'CHALLENGE_STATE',
        user_id: loginSession?.user_id as string,
        session_id: loginSession?.session_id as string,
      }),
    response,
    reset,
  ];
};
