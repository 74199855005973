import { Box, useBreakpoint } from '@chakra-ui/react';
import { CartProductMeta } from 'src/components/Details/CartProductMeta';
import { PriceTag } from 'src/components/Details/PriceTag';

export type CartItemProps = {
  currency: string;
  id: string;
  image: string;
  name: string;
  price: string;
  quantity: number;
  description: string;
};
export const CartItem = (props: CartItemProps) => {
  const { currency, image, name, price, quantity, description } = props;
  const breakpoints = useBreakpoint();
  const pricedQuantity = (quantity * parseFloat(price)).toFixed(1);

  return (
    <Box flexDirection="row" justifyContent="space-between" color="white" alignItems="center" display="flex">
      <CartProductMeta name={name} description={description} image={image} quantity={quantity} />

      {/* Desktop */}
      {breakpoints === 'md' && (
        <Box width="full" justifyContent="end" display={{ base: 'none', md: 'flex' }}>
          <PriceTag price={pricedQuantity} currency={currency} />
        </Box>
      )}

      {/* Mobile */}
      {breakpoints === 'sm' && (
        <Box mt="4" width="full" justifyContent="end" display={{ base: 'flex', md: 'none' }}>
          <PriceTag price={pricedQuantity} currency={currency} />
        </Box>
      )}
    </Box>
  );
};
