import { reportQueryError } from 'src/api';
import { useMutation } from 'src/hooks/useMutation';

export type UserUpdatePayload = {
  card_id: string;
  psp_type: string;
};

export const useUpdateSource = () => {
  return useMutation<UserUpdatePayload>({
    request: {
      type: 'rest',
      method: 'put',
      endpoint: '/v1/sources',
      target: 'just',
    },
    reshapeData: (data) => data,
    options: {
      mutationKey: 'MUTATION/UPDATE_SOURCE',
      throwOnError: true,
      onError: ({ error }) => {
        reportQueryError(error);
      },
    },
  });
};
