import { reportQueryError } from 'src/api';
import { useStore } from 'src/business/store';
import { useMutation } from 'src/hooks/useMutation';
import { UserInfoResponse, ReshapedUserResponse, reshapeCurrentUserData } from 'src/queries/useUserInfo';
import { RegisterPayloadAddressEntry } from 'src/queries/useRegister';
import { useUpdateCheckoutShippping } from 'src/queries/useUpdateCheckoutShippping';

export type UserUpdatePayload = {
  personal_informations: {
    first_name: string;
    last_name: string;
  };
  phone: string;
  addresses: RegisterPayloadAddressEntry[];
  shouldNotUpdateCheckoutAddress?: boolean;
};

export const useUserUpdate = () => {
  const { setState, checkoutSession, api_token, api_domain } = useStore();
  const [updateCheckoutShipping] = useUpdateCheckoutShippping();

  return useMutation<UserUpdatePayload, ReshapedUserResponse, UserInfoResponse>({
    request: {
      type: 'rest',
      method: 'put',
      endpoint: '/v1/profile',
      target: 'just',
    },
    reshapeData: reshapeCurrentUserData,
    options: {
      mutationKey: 'MUTATION/UPDATE_PROFILE',
      throwOnError: true,
      onError: ({ error }) => {
        reportQueryError(error);
      },
      onSuccess: ({ payload }) => {
        const { first_name, last_name, phone, country_code, ...restAddress } =
          payload?.addresses?.find((adrr) => adrr.is_default_shipping)?.address ?? {};

        if (!payload.shouldNotUpdateCheckoutAddress) {
          updateCheckoutShipping({
            variables: {
              shippingAddress: {
                ...restAddress,
                firstName: first_name as string,
                lastName: last_name as string,
                phone: phone as string,
              },
              checkoutId: checkoutSession?.checkout?.id as string,
            },
            domain: api_domain as string,
            token: api_token as string,
          });
        }
        setState({ shouldFetchUserInfo: true });
      },
    },
  });
};
