import {
  Box,
  Stack,
  Radio,
  Collapse,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  useDisclosure,
  Text,
  Skeleton,
} from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { useUpdateCheckoutShippingLine } from 'src/queries/useUpdateCheckoutShippingLine';
import { getCurrencySymbol, isFree } from 'src/commons';
import { useOnClickOutside } from 'src/hooks/useOnClickOutside';
import { useStore } from 'src/business/store';

import type { UseFormSetValue, UseFormGetValues } from 'react-hook-form';
import type { FormSchema } from 'src/hooks/useFormValidation';
import { pick } from 'ramda';
import { useIsMutating } from 'react-query';

type Props = {
  setValue: UseFormSetValue<FormSchema>;
  getValues: UseFormGetValues<FormSchema>;
};

export const ShippingRates = ({ setValue, getValues }: Props) => {
  const { t } = useTranslation('common');
  const { api_token, api_domain, checkoutSession, language } = useStore();
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [updateShippingLine, { status }] = useUpdateCheckoutShippingLine();
  const ref = useRef<any>(null);
  const shippingMethodLabel = t('inputs.shipping_method.label');
  const freeLabel = t('commons.free');
  const awaitingShippingLabel = t('commons.shipping_waiting');
  const shippingMethod = getValues('shipping_method');
  const validAddressValues = pick(
    ['address1', 'country', 'city', 'zip', 'province', 'country_code'],
    getValues('address') ?? {},
  );

  const isShippingLineLoading = useIsMutating('MUTATION/SHIPPING_LINE');
  const onShippingRateChange = useCallback(
    (value: string) => {
      setValue('shipping_method', value);
      onClose();

      updateShippingLine({
        variables: {
          shippingRateHandle: value,
          checkoutId: checkoutSession?.checkout?.id as string,
        },
        domain: api_domain as string,
        token: api_token as string,
      });
    },
    [api_domain, api_token, checkoutSession?.checkout?.id, setValue, updateShippingLine],
  );

  const shippingRates = useMemo(() => {
    const shipping = checkoutSession?.checkout?.availableShippingRates;
    if (shipping?.shippingRates) {
      return shipping?.shippingRates;
    }
    return [];
  }, [checkoutSession]);

  const storedShippingLine = useMemo(() => {
    const shippingLine = checkoutSession?.checkout?.shippingLine;
    if (shippingLine) {
      return shippingLine;
    }
    return null;
  }, [checkoutSession?.checkout?.shippingLine]);

  useOnClickOutside(ref, onToggle);

  useEffect(() => {
    if (!shippingMethod && storedShippingLine) {
      setValue('shipping_method', storedShippingLine.handle);
    }
  }, [setValue, shippingMethod, storedShippingLine]);

  if (Object.values(validAddressValues).length < 5 || !storedShippingLine?.handle || !shippingRates.length) {
    return (
      <Box
        bgColor="white"
        borderRadius="30px"
        style={{
          marginLeft: 5,
          marginRight: 5,
        }}
      >
        <InputGroup bgColor="white" borderRadius="30px" p="2">
          <InputLeftAddon
            fontWeight="bold"
            fontSize="small"
            bgColor="transparent"
            border="none"
            minW={{
              sm: '140px',
              md: '160px',
              base: '140px',
            }}
            // eslint-disable-next-line react/no-children-prop
            children={shippingMethodLabel.toUpperCase()}
          />
          <Input
            fontWeight="bold"
            fontSize="small"
            variant="unstyled"
            disabled
            type="text"
            placeholder={awaitingShippingLabel}
          />
        </InputGroup>
      </Box>
    );
  }

  const storedPrice = storedShippingLine?.priceV2.amount;
  const selectedSubLabel = isFree(storedPrice)
    ? freeLabel
    : `${storedShippingLine?.priceV2.amount} ${getCurrencySymbol(
        language as string,
        storedShippingLine?.priceV2.currencyCode,
      )}`;

  if (status === 'loading' || isShippingLineLoading || Object.values(validAddressValues).length < 5) {
    return (
      <Skeleton
        borderRadius="30px"
        p="4"
        style={{
          marginLeft: 5,
          marginRight: 5,
        }}
      >
        <div>Empty</div>
      </Skeleton>
    );
  }

  if (shippingRates?.length && shippingRates.length > 1) {
    return (
      <Stack
        bgColor="white"
        border={isOpen ? '2px solid' : undefined}
        borderColor="purple.500"
        borderRadius="30px"
        style={{
          marginLeft: 5,
          marginRight: 5,
        }}
      >
        <Box ref={isOpen ? ref : undefined}>
          {!isOpen && (
            <InputGroup bgColor="white" borderRadius="30px" p="2" onClick={onToggle}>
              <InputLeftAddon
                fontWeight="bold"
                fontSize="small"
                bgColor="transparent"
                border="none"
                minW={{
                  sm: '140px',
                  md: '160px',
                  base: '140px',
                }}
                // eslint-disable-next-line react/no-children-prop
                children={shippingMethodLabel.toUpperCase()}
              />
              <Input
                fontWeight="bold"
                fontSize="small"
                variant="unstyled"
                disabled
                type="text"
                value={`${shippingMethodLabel} ${storedShippingLine?.title}`}
              />
              <InputRightAddon
                fontWeight="bold"
                fontSize="small"
                bgColor="transparent"
                border="none"
                // eslint-disable-next-line react/no-children-prop
                children={
                  <Text fontSize="small" fontWeight="bold">
                    {selectedSubLabel}
                  </Text>
                }
              />
            </InputGroup>
          )}
          <Collapse in={isOpen} animate animateOpacity unmountOnExit={false}>
            <Stack spacing={2}>
              {shippingRates?.map((shippingRate, index) => {
                const mapAddressLabel = `${shippingMethodLabel} ${shippingRate?.title}`;
                const mapShippingRatePrice = shippingRate.priceV2.amount;
                const subLabel = `${mapShippingRatePrice} ${getCurrencySymbol(
                  language as string,
                  shippingRate.priceV2.currencyCode,
                )}`;

                return (
                  <InputGroup
                    bgColor="transparent"
                    pl="2"
                    pr="2"
                    borderRadius="30px"
                    display="flex"
                    key={shippingRate?.handle}
                  >
                    <InputLeftAddon
                      fontWeight="bold"
                      fontSize="small"
                      bgColor="transparent"
                      border="none"
                      minW="160px"
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <Box
                        width={index === 0 ? '100%' : undefined}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        {index === 0 && (
                          <Text
                            marginLeft={index === 0 ? '-3px' : undefined}
                            fontWeight="bold"
                            fontSize="small"
                          >
                            {shippingMethodLabel.toUpperCase()}
                          </Text>
                        )}
                        <Radio
                          isReadOnly
                          colorScheme="purple"
                          isChecked={shippingRate?.handle === storedShippingLine?.handle}
                        />
                      </Box>
                    </InputLeftAddon>
                    <Input
                      cursor="pointer"
                      onClick={() => {
                        if (shippingRate?.handle !== storedShippingLine?.handle) {
                          onShippingRateChange(shippingRate?.handle as string);
                        }
                      }}
                      fontWeight="bold"
                      fontSize="small"
                      variant="unstyled"
                      textAlign="start"
                      type="button"
                      value={mapAddressLabel}
                    />
                    <InputRightAddon
                      fontWeight="bold"
                      fontSize="small"
                      bgColor="transparent"
                      border="none"
                      // eslint-disable-next-line react/no-children-prop
                      children={
                        <Text fontSize="small" fontWeight="bold">
                          {isFree(mapShippingRatePrice) ? freeLabel : subLabel}
                        </Text>
                      }
                    />
                  </InputGroup>
                );
              })}
            </Stack>
          </Collapse>
        </Box>
      </Stack>
    );
  }

  return (
    <Stack
      borderRadius="30px"
      style={{
        marginLeft: 5,
        marginRight: 5,
      }}
    >
      <InputGroup bgColor="white" borderRadius="30px" p="2">
        <InputLeftAddon
          fontWeight="bold"
          fontSize="small"
          bgColor="transparent"
          border="none"
          minW={{
            sm: '140px',
            md: '160px',
            base: '140px',
          }}
          // eslint-disable-next-line react/no-children-prop
          children={shippingMethodLabel}
        />
        <Input
          fontWeight="bold"
          fontSize="small"
          variant="unstyled"
          disabled
          type="text"
          value={`${shippingMethodLabel} ${storedShippingLine?.title}`}
        />
        <InputRightAddon
          fontWeight="bold"
          fontSize="small"
          bgColor="transparent"
          border="none"
          // eslint-disable-next-line react/no-children-prop
          children={
            <Text fontSize="small" fontWeight="bold">
              {selectedSubLabel}
            </Text>
          }
        />
      </InputGroup>
    </Stack>
  );
};
