import { QueryKey, useQuery as useReactQuery, useQueryClient, QueryClient } from 'react-query';

import { QueryRequest } from 'src/api/query';
import { QueryState } from 'src/api/queryState';
import { getFetcher } from 'src/api/fetcher';
import { QueryError } from 'src/api/queryError';
import { RetryValue } from 'react-query/types/core/retryer';

export function useQuery<
  TResult extends object | undefined,
  TRawResult extends object | undefined = any,
  TError = any,
  TRawError = TError,
>({
  key,
  isEnabled = true,
  request,
  reshapeData,
  reshapeError = (error: QueryError<TRawError>): QueryError<TError> => error as QueryError<TError>,
  options,
}: {
  key: QueryKey;
  isEnabled?: boolean;
  request: QueryRequest;
  reshapeData(data: TRawResult): TResult;
  reshapeError?(error: QueryError<TRawError>): QueryError<TError>;
  options?: {
    retry?: RetryValue<QueryError<TError>>;
    onSuccess?(successBag: { rawData: TRawResult; data: TResult; client: QueryClient }): void;
    onError?(errorBag: { error: QueryError<TError>; client: QueryClient }): void;
    refetchInterval?: number | false;
    /** In milliseconds */
    cacheTime?: number;
    /** In milliseconds – default: 0 */
    staleTime?: number;
    initialData?(): TRawResult | undefined;
  };
}): QueryState<TResult, TError> {
  const queryClient = useQueryClient();
  const fetcher = getFetcher<TRawResult>({
    getRequest: () => ({
      ...request,
      method: 'get',
    }),
  });

  const queryState = useReactQuery<TRawResult, QueryError<TRawError>>(key, fetcher, {
    ...options,
    // @ts-expect-error property is always defined
    retry: (failureCount, error) => options?.retry?.(failureCount, reshapeError(error)),
    refetchOnWindowFocus: false,
    enabled: isEnabled,
    onSuccess: (data) =>
      options?.onSuccess?.({
        rawData: data,
        data: reshapeData(data),
        client: queryClient,
      }),
    onError: (error) =>
      options?.onError?.({
        error: reshapeError(error),
        client: queryClient,
      }),
  });

  if (queryState.status === 'loading' || queryState.status === 'idle' || !isEnabled) {
    return {
      status: 'loading',
      refetch: queryState.refetch,
      data: null,
    };
  }

  if (queryState.status === 'error') {
    return {
      status: 'error',
      error: reshapeError(queryState.error),
      refetch: queryState.refetch,
      data: null,
    };
  }

  return {
    status: 'success',
    data: reshapeData(queryState.data),
    refetch: queryState.refetch,
  };
}
