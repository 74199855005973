import { useToast } from '@chakra-ui/react';
import { Token } from '@stripe/stripe-js';
import { useTranslation } from 'next-i18next';
import { reportQueryError } from 'src/api';
import { useStore } from 'src/business/store';
import { useMutation } from 'src/hooks/useMutation';

import type { RegisterPayloadAddress } from 'src/queries/useRegister';

export type SourcesPayload = {
  psp_type: string;
  card: Token['card'] & { token_id: string };
  address?: RegisterPayloadAddress;
};

export const useAddSource = () => {
  const { setState } = useStore();
  const { t } = useTranslation('common');
  const toast = useToast();

  return useMutation<SourcesPayload, unknown>({
    request: {
      type: 'rest',
      method: 'post',
      endpoint: '/v1/sources',
      target: 'just',
    },
    reshapeData: (data) => data,
    options: {
      mutationKey: 'MUTATION/ADD_SOURCE',
      throwOnError: true,
      onError: ({ error }) => {
        reportQueryError(error);
        setState({
          shouldFetchOrderRequest: false,
          shouldFetchOrderPage: false,
          setLoadingPayment: false,
          paymentStatus: {
            status: 'failed',
            display: false,
          },
        });

        if (error.type === 'RequestError') {
          setState({
            paymentStatus: {
              status: 'failed',
              display: false,
            },
          });
          toast({
            description: t(`errors.stripe.${error.data?.code}`),
            status: 'error',
            position: 'top',
            isClosable: true,
            duration: 10000,
          });
        }
      },
      onSuccess: () => {
        setState({
          paymentStatus: undefined,
        });
      },
    },
  });
};
