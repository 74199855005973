import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useEffect } from 'react';
import { Box, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { is, isEmpty } from 'ramda';

import { captureFormAnalytics, getStorage } from 'src/commons';
import { Confirmation } from 'src/components/Confirmation';
import { DiscountCodeOverlay } from 'src/components/DiscountCode';
import { LoginValidation } from 'src/components/LoginValidation';
import { Overlay3DSecure } from 'src/components/Overlay3DSecure';
import { QueryError } from 'src/components/QueryError';
import { QuerySuspense } from 'src/components/QuerySuspense';
import { setInternalApiAuthHeaders } from 'src/api/axios';
import { Spinner } from 'src/components/Spinner';
import { useOrderPage } from 'src/queries/useOrderPage';
import { useOrderRequest } from 'src/queries/useOrderRequest';
import { useRefreshToken } from 'src/queries/useRefreshToken';
import { useSeller } from 'src/queries/useSeller';
import { useStore } from 'src/business/store';
import { useUserInfo } from 'src/queries/useUserInfo';
import Form from 'src/components/Form';
import FormEdit from 'src/components/FormEdit';

import type { NextPage } from 'next';
import type { FormEvent, JustApiToken } from 'src/types';

const CheckoutForm: NextPage = () => {
  const { i18n } = useTranslation();
  const {
    setState,
    showSuccessPage,
    isFormEdit,
    setLoadingPayment,
    isStateReady,
    isDiscountOpen: isDiscountOpenFromState,
    ...state
  } = useStore();
  const { isOpen: isDiscountOpen, onToggle: onDiscountToggle } = useDisclosure();
  const query = useSeller();
  const [refreshToken] = useRefreshToken();
  const { refetch } = useOrderRequest();
  useUserInfo();
  useOrderPage();

  useEffect(() => {
    const messageHandler = (event: MessageEvent<any>) => {
      const { data } = event;
      if (is(String, data)) {
        const match = event.data.match(/payment_intent_client_secret=([^&]+)/);

        if (match) {
          setState({
            paymentStatus: {
              display: false,
              url: undefined,
            },
          });
          refetch?.();
        }

        const parsed = !match ? (JSON.parse(data) as FormEvent) : null;
        if (parsed?.emitter === 'JUST_BUTTON') {
          setState({
            ...parsed,
            shouldFetchOrderRequest: parsed.command_status === 'CREATED',
            isFormEdit: parsed.command_status === 'CREATED',
            isStateReady: true,
            setLoadingPayment: true,
            isDiscountOpen,
          });
        }
      }
    };

    window.addEventListener('message', messageHandler, true);

    return () => window.removeEventListener('message', messageHandler);
  }, []);

  useEffect(() => {
    if (isDiscountOpenFromState !== isDiscountOpen) {
      setState({ isDiscountOpen });
    }
  }, [isDiscountOpenFromState, isDiscountOpen, setState]);

  useEffect(() => {
    const token = getStorage<JustApiToken>('token');
    if (token && isStateReady) {
      captureFormAnalytics({
        user: token.user_id,
        registered: true,
      });
      setInternalApiAuthHeaders(token.access_token);
      setState({ setLoadingPayment: true });
      refreshToken({
        user_id: token.user_id,
        refresh_token: token.refresh_token,
      });
    } else if (isStateReady) {
      setState({ setLoadingPayment: false });
    }
  }, [isStateReady]);

  useEffect(() => {
    window.parent.postMessage(
      JSON.stringify({
        emitter: 'JUST_FORM',
        ready: true,
      }),
      '*',
    );
  }, []);

  useEffect(() => {
    if (state.language && i18n.language !== state.language) {
      i18n.changeLanguage(state.language);
    }
  }, [state.language, i18n]);

  if (isEmpty(state)) {
    return <Spinner />;
  }

  return (
    <QuerySuspense
      queryState={query}
      loading={<Spinner />}
      fallback={(error) => <QueryError queryError={error} translations={{}} />}
    >
      {({ storefrontToken, domain }) => {
        return (
          <>
            {setLoadingPayment && <Spinner />}
            {showSuccessPage && <Confirmation />}
            {state.paymentStatus?.display && state.paymentStatus.url ? (
              <Overlay3DSecure url={state.paymentStatus.url} />
            ) : null}
            {state.requireLoginChallenge ? <LoginValidation /> : null}
            {isDiscountOpen ? <DiscountCodeOverlay onClose={onDiscountToggle} /> : null}
            <Box display="flex" flexDirection="column" height="100vh">
              {isFormEdit ? (
                <FormEdit toggleDiscount={onDiscountToggle} domain={domain} token={storefrontToken} />
              ) : (
                <Form toggleDiscount={onDiscountToggle} domain={domain} token={storefrontToken} />
              )}
            </Box>
          </>
        );
      }}
    </QuerySuspense>
  );
};

export const getStaticProps = async ({ locale }: { locale: string }) => ({
  props: {
    ...(await serverSideTranslations(locale, ['common'])),
  },
});

export default CheckoutForm;
