import { reportQueryError } from 'src/api';
import { useMutation } from 'src/hooks/useMutation';
import { useStore } from 'src/business/store';
import { useUpdateCheckoutShippingLine } from 'src/queries/useUpdateCheckoutShippingLine';

import type { UpdateCheckoutShipping } from 'src/types/update-shipping-checkout';
import type { CheckoutShippingAddressUpdateV2Response } from 'src/types/checkout-response';

export const useUpdateCheckoutShippping = () => {
  const { setState, checkoutSession, api_domain, api_token, email } = useStore();
  const [updateShippingLine] = useUpdateCheckoutShippingLine();

  return useMutation<
    UpdateCheckoutShipping,
    CheckoutShippingAddressUpdateV2Response,
    CheckoutShippingAddressUpdateV2Response
  >({
    request: {
      type: 'rest',
      method: 'post',
      endpoint: '/api/update-checkout-shipping',
      target: 'internal',
    },
    // @ts-expect-error property is always defined
    reshapeData: (data) => {
      const sorted = data?.checkout?.availableShippingRates?.shippingRates?.sort(
        (prev: any, next: any) => parseFloat(prev.priceV2.amount) - parseFloat(next.priceV2.amount),
      );

      return {
        ...(data ?? {}),
        checkout: {
          ...(data?.checkout ?? {}),
          availableShippingRates: {
            ...(data?.checkout?.availableShippingRates ?? {}),
            shippingRates: sorted,
          },
        },
      };
    },
    options: {
      throwOnError: true,
      onError: ({ error }) => {
        reportQueryError(error);
      },
      retry: (_failureCount, error) => {
        if (error.type === 'RequestError') {
          const checkoutErrors = error.data?.checkoutUserErrors as { code: string; message: string }[];
          const shippingRateError = checkoutErrors?.find((err) => err.code === 'SHIPPING_RATE_NOT_AVAILABLE');
          if (shippingRateError) {
            return true;
          }
        }
        return false;
      },
      onSuccess: ({ data }) => {
        if (data?.checkout?.availableShippingRates?.ready) {
          setState({
            // @ts-expect-error property is always defined
            checkoutSession: {
              ...data,
              ...checkoutSession,
              checkout: {
                ...data.checkout,
                ...(checkoutSession?.checkout ?? {}),
                email: checkoutSession?.checkout?.email ?? email,
                availableShippingRates: data.checkout.availableShippingRates,
                shippingAddress: data.checkout.shippingAddress,
              },
            },
          });

          updateShippingLine({
            variables: {
              shippingRateHandle: data.checkout?.availableShippingRates?.shippingRates?.[0]?.handle as string,
              checkoutId: checkoutSession?.checkout?.id as string,
            },
            domain: api_domain as string,
            token: api_token as string,
          });
        } else {
          throw { data, code: 'SHIPPING_RATE_NOT_AVAILABLE' };
        }
      },
    },
  });
};
