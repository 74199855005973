import posthog from 'posthog-js';

import type { PostMessageEvent } from 'src/types';
import { CheckoutCreateResponse } from 'src/types/checkout-response';

export const _postMessage = (message: Omit<PostMessageEvent, 'app_id' | 'domain'>) => {
  window.parent.postMessage(JSON.stringify(message), '*');
};

export function getCurrencySymbol(locale: string, currency: string) {
  return (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();
}

function storageAvailable() {
  try {
    const storage = window['localStorage'],
      x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (error) {
    return false;
  }
}

export function setStorage<T>(key: string, data: T) {
  if (!storageAvailable()) return;
  localStorage.setItem(key, JSON.stringify(data));
}

export function getStorage<T>(key: string): T | null {
  if (!storageAvailable()) return null;
  const data = localStorage.getItem(key);
  if (data) {
    return JSON.parse(data);
  }

  return null;
}

export const debounce = (callback: (args: any) => void, wait: number) => {
  let timeoutId: any = null;
  return (...args: any) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      // eslint-disable-next-line prefer-spread
      callback.apply(null, args);
    }, wait);
  };
};

export function clearStorage(key: string) {
  if (!storageAvailable()) return;
  localStorage.removeItem(key);
}

export const isFree = (value: string) => {
  if (typeof value === 'string') {
    const parsed = parseFloat(value);
    return parsed === 0;
  }
};

export const captureCheckoutAnalytics = (props: {
  checkoutSession: CheckoutCreateResponse;
  user?: string;
  registered?: boolean;
  shop: string;
  step: 'CHECKOUT_CREATE' | 'CHECKOUT_ADDRESS_UPDATE' | 'CHECKOUT_EMAIL_UPDATE';
}) => {
  posthog.capture('FORM/CHECKOUT_SHOPIFY', props);
};

export const captureFormAnalytics = (props: {
  user?: string;
  registered?: boolean;
  shop?: string;
  buttonClicked?: 'CONFIRM_ORDER' | 'CANCEL_ORDER' | 'EDIT_ORDER';
}) => {
  posthog.capture('FORM/ANALYTICS', props);
};
