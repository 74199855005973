import { ArrowBackIcon } from '@chakra-ui/icons';
import {
  HStack,
  PinInput,
  PinInputField,
  Stack,
  Box,
  Text,
  useInterval,
  Button,
  Spinner,
} from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import { useStore } from 'src/business/store';
import { clearStorage } from 'src/commons';
import { useLoginMutation } from 'src/queries/useLogin';
import { useLoginChallenge } from 'src/queries/useLoginChallenge';

export const LoginValidation = () => {
  const [count, setCount] = useState(30);
  const [delay] = useState(1000);
  const [isRunning, setIsRunning] = useState(true);
  const { onLogin } = useLoginMutation();
  const { t } = useTranslation('common');
  const [challenge, { status }] = useLoginChallenge();
  const { email, setState } = useStore();
  const noticeLabel = t('otp.notice');
  const noticeEmail = t('otp.notice_email', { email });
  const noticeNotReceivedLabel = t('otp.notice_not_received');
  const noticeResendLabel = t('otp.notice_resend', { count: count });
  const resendEmailLabel = t('otp.resend_email');
  const client = useQueryClient();

  const onLoginClick = () => {
    onLogin(email as string, false);
    setCount(30);
    setIsRunning(true);
  };
  const onBackClick = () => {
    clearStorage('token');
    client.clear();

    setState({
      requireLoginChallenge: false,
      email: ' ',
      shouldResetEmail: true,
      setLoadingPayment: false,
    });
  };
  useInterval(() => setCount(count - 1), isRunning ? delay : null);

  useEffect(() => {
    if (!count) {
      return setIsRunning(false);
    }
  }, [count]);

  return (
    <div className="overlay show">
      <Button onClick={onBackClick} variant="ghost" position="absolute" top={2} left={2}>
        <ArrowBackIcon />
      </Button>
      <div className="overlay-content">
        <Stack spacing={6}>
          <Box display="flex" justifyContent="center">
            <Image src="/images/just_logo.png" width={212} height={45} alt="Just Logo" />
          </Box>
          <Stack
            spacing={1}
            display="flex"
            justifyContent="center"
            flexWrap="wrap"
            flexDirection="column"
            p="4"
          >
            <Text textAlign="center">{noticeLabel}</Text>
            <Text fontWeight="bold" textAlign={'center'}>
              {noticeEmail}
            </Text>
          </Stack>
          {status === 'loading' ? (
            <Box justifyContent="center">
              <Spinner thickness="4px" speed="0.65s" emptyColor="purple.100" color="purple.500" size="xl" />
            </Box>
          ) : (
            <HStack justifyContent="center">
              <PinInput autoFocus size="lg" otp focusBorderColor="purple.500" onComplete={challenge}>
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
              </PinInput>
            </HStack>
          )}
          <Box display="flex" justifyContent="center">
            <Image src="/images/just_icon.png" width={76} height={76} alt="Just Icon" />
          </Box>
          <Stack spacing={1} display="flex" justifyContent="center" flexWrap="wrap" flexDirection="column">
            <Text textAlign={'center'}>{noticeNotReceivedLabel}</Text>
            {count ? (
              <Text fontWeight="bold" textAlign={'center'}>
                {noticeResendLabel}
              </Text>
            ) : (
              <Text as="ins" fontWeight="bold" textAlign={'center'} onClick={onLoginClick}>
                {resendEmailLabel}
              </Text>
            )}
          </Stack>
        </Stack>
      </div>
    </div>
  );
};
