import { isEmpty, isNil } from 'ramda';

import { reportQueryError } from 'src/api';
import { setInternalApiAuthHeaders } from 'src/api/axios';
import { useStore } from 'src/business/store';
import { setStorage } from 'src/commons';
import { FormSchema } from 'src/hooks/useFormValidation';
import { useMutation } from 'src/hooks/useMutation';
import { JustApiToken } from 'src/types';

export type RegisterPayloadAddress = {
  address1: string;
  address2?: string;
  city: string;
  company?: string;
  country: string;
  country_code: string;
  first_name: string;
  last_name: string;
  phone?: string;
  province: string;
  province_code?: string;
  zip: string;
  name?: string;
  latitude?: string;
  longitude?: string;
};

export type RegisterPayloadAddressEntry = {
  address: RegisterPayloadAddress;
  is_default_shipping?: boolean;
  is_default_billing?: boolean;
};

export type RegisterPayload = {
  client_secret: string;
  client_id: string;
  email: string;
  personal_informations: {
    first_name: string;
    last_name: string;
  };
  phone: string;
  addresses: RegisterPayloadAddressEntry[];
  state: string;
  code_challenge: string;
  card: string;
};

export const useRegister = () => {
  const { setState, loginSession } = useStore();
  // @ts-expect-error property always defined
  const [register, { data, status, error }] = useMutation<RegisterPayload, JustApiToken>({
    request: {
      type: 'rest',
      method: 'post',
      endpoint: '/v1/auth/register',
      target: 'just',
    },
    reshapeData: (data) => data,
    options: {
      throwOnError: true,
      onError: ({ error }) => {
        reportQueryError(error);
        setState({ setLoadingPayment: false });
      },
      onSuccess: ({ data }) => {
        setStorage<JustApiToken>('token' as string, {
          ...data,
          user_id: loginSession?.user_id as string,
        });
        setState({
          shouldRegister: false,
        });
        setInternalApiAuthHeaders(data.access_token);
      },
    },
  });

  const registerUser = async (payload: FormSchema) => register(reshapePayload(payload));

  return [registerUser, { data, status, error }];
};

const reshapePayload = (values: FormSchema): RegisterPayload => {
  const address = values.address;
  const billingAddress = values.billing_address;
  const hasBillingAddress = billingAddress && (!isEmpty(billingAddress) || !isNil(billingAddress));
  const addresses: RegisterPayloadAddressEntry[] = [
    {
      address: {
        ...address,
        first_name: values.user.firstname,
        last_name: values.user.lastname,
        country_code: address.country_code,
        phone: values.user.phone_number,
      },
      is_default_billing: !hasBillingAddress,
      is_default_shipping: true,
    },
  ];
  if (hasBillingAddress) {
    addresses.push({
      address: {
        ...billingAddress,
        first_name: values.user.firstname,
        last_name: values.user.lastname,
        country_code: address.country_code,
        phone: values.user.phone_number,
      },
      is_default_billing: true,
      is_default_shipping: false,
    });
  }

  return {
    client_secret: process.env.NEXT_PUBLIC_API_CLIENT_SECRET as string,
    client_id: 'web',
    email: values.email,
    state: 'LOGIN_STATE',
    code_challenge: 'LOGIN_CHALLENGE',
    personal_informations: {
      first_name: values.user.firstname,
      last_name: values.user.lastname,
    },
    card: values.card.token,
    phone: values.user.phone_number,
    addresses: addresses,
  };
};
