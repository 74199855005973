import {
  Box,
  Collapse,
  Skeleton,
  useDisclosure,
  Divider,
  Stack,
  Heading,
  Flex,
  Text,
  Avatar,
  AvatarBadge,
  IconButton,
  Container,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon, ArrowBackIcon } from '@chakra-ui/icons';
import { useCallback, useEffect, useRef, useState } from 'react';

import { _postMessage } from 'src/commons';
import { CartItem, CartItemProps } from 'src/components/Details/CartItem';
import { CartOrderSummary } from 'src/components/Details/CartOrderSummary';
import { PriceTag } from 'src/components/Details/PriceTag';
import { useStore } from 'src/business/store';
import { CountDown } from '../CountDown';
import { useTranslation } from 'next-i18next';

export const Details = () => {
  const { isOpen, onToggle } = useDisclosure();
  const { t } = useTranslation('common');
  const [heightToSet, setHeight] = useState<number | null>(null);
  const collpaseInfoOrderRef = useRef<any>();
  const {
    checkoutSession,
    shopName,
    sessionKey,
    email,
    order,
    orderId,
    orderUrl,
    requestId,
    command_status,
    isFormEdit,
    paymentStatus,
    expiration,
  } = useStore();

  const price = checkoutSession?.checkout?.totalPriceV2;
  const originalPrice = checkoutSession?.checkout?.paymentDueV2;
  const products = checkoutSession?.checkout?.lineItems?.edges?.map(
    (edge) =>
      ({
        name: edge.node.title,
        price: edge.node.variant?.priceV2.amount,
        currency: edge.node.variant?.priceV2.currencyCode,
        quantity: edge.node.quantity,
        id: edge.node.id,
        image: edge.node.variant?.image?.src,
        description: edge.node.variant?.title,
      } as CartItemProps),
  );

  const resizeObserver = new ResizeObserver((entries) => setHeight(entries[0].contentRect.height + 10));

  useEffect(() => {
    if (collpaseInfoOrderRef.current) {
      resizeObserver.observe(collpaseInfoOrderRef.current);
    }
  }, [collpaseInfoOrderRef.current]);

  const onBack = useCallback(() => {
    _postMessage({
      command_status: command_status,
      requestId: requestId as string,
      orderUrl: orderUrl ?? '',
      orderId: orderId ?? '',
      email: email ?? '',
      sessionKey: sessionKey as string,
      emitter: 'JUST_FORM',
      action: 'CLOSE',
    });
  }, [command_status, email, orderId, orderUrl, requestId, sessionKey]);

  if (!checkoutSession?.checkout) {
    return (
      <Skeleton>
        <div>Empty</div>
        <div>Empty</div>
      </Skeleton>
    );
  }

  const itemCount = products?.reduce((prev, product) => {
    prev += product.quantity;
    return prev;
  }, 0);

  return (
    <>
      <Collapse in={order && isFormEdit} style={{ overflow: 'inherit' }} animateOpacity unmountOnExit>
        <Box
          ref={order && isFormEdit ? collpaseInfoOrderRef : undefined}
          backgroundColor={paymentStatus?.status === 'failed' ? 'red.500' : 'purple.500'}
          padding="5px 15px"
          color="white"
          textAlign="center"
          position="absolute"
          top="0px"
          left="0px"
          right="0px"
          zIndex={999}
        >
          <Heading fontSize="l">
            {paymentStatus?.status === 'failed'
              ? t('notAuthed.statusBar.paymentError')
              : t('info.orderConfirmed')}
          </Heading>
          {paymentStatus?.status === 'failed' ? (
            <Text>{t('notAuthed.statusBar.reOrder')}</Text>
          ) : (
            <CountDown start={order && isFormEdit && expiration && orderUrl} until={expiration as number} />
          )}
        </Box>
      </Collapse>

      <Container
        p="0px"
        as="header"
        width="98%"
        borderRadius="30px"
        mb="10px"
        mt={order && isFormEdit ? `${heightToSet}px` : undefined}
        backgroundColor="black"
      >
        <Box
          position="relative"
          boxShadow="2xl"
          flexBasis="50px"
          borderRadius="30px"
          display="flex"
          flexDirection="column"
        >
          <Box
            zIndex={99}
            backgroundColor="black"
            color="white"
            width="100%"
            flex="1 1 auto"
            borderRadius="30px"
            boxShadow={!isOpen ? 'md' : undefined}
            display="flex"
            alignItems="center"
          >
            <IconButton
              onClick={onBack}
              ml="2px"
              variant="unstyled"
              rounded="full"
              colorScheme="whiteAlpha"
              aria-label="back"
              fontSize="1.2em"
              icon={<ArrowBackIcon />}
            />
            <Box
              cursor="pointer"
              onClick={onToggle}
              padding="16px 10px 16px 16px"
              overflow="auto"
              width="95%"
            >
              <Flex width="100%" align="center" justify="space-between">
                <Heading fontSize="md">{shopName}</Heading>
                <Box display="flex" flexWrap="nowrap" alignItems="center" justifyContent="end" width="125px">
                  <Box position="relative" marginRight="25px">
                    <Avatar background="transparent" size="xs" name="Card" src="/images/cart.png">
                      <AvatarBadge boxSize="1.5em" bg="black">
                        <Text color="white">{itemCount}</Text>
                      </AvatarBadge>
                    </Avatar>
                  </Box>
                  <Box>
                    {price?.amount !== originalPrice?.amount ? (
                      <PriceTag
                        price={originalPrice?.amount}
                        salePrice={price?.amount}
                        currency={price?.currencyCode}
                      />
                    ) : (
                      <PriceTag price={price?.amount} currency={price?.currencyCode} />
                    )}
                  </Box>
                  {isOpen ? (
                    <ChevronUpIcon fontSize="1.3em" ml="10px" />
                  ) : (
                    <ChevronDownIcon fontSize="1.3em" ml="10px" />
                  )}
                </Box>
              </Flex>
            </Box>
          </Box>
          <Collapse in={isOpen} animateOpacity unmountOnExit>
            <Box maxW={{ base: '3xl', lg: '7xl' }} mx="auto" padding="0px 16px 16px 16px">
              <Stack
                direction={{ base: 'column', lg: 'row' }}
                align={{ lg: 'flex-start' }}
                spacing={{ base: '8', md: '16' }}
              >
                <Stack spacing={{ base: '8', md: '10' }} flex="2">
                  <Stack spacing="4">
                    {products?.map((product) => (
                      <CartItem key={product.id} {...product} />
                    ))}
                    <Divider />
                  </Stack>
                </Stack>
                <Stack>
                  <CartOrderSummary />
                </Stack>
              </Stack>
            </Box>
          </Collapse>
        </Box>
      </Container>
    </>
  );
};
