import {
  Box,
  FormControl,
  Input,
  InputGroup,
  InputLeftAddon,
  Avatar,
  useOutsideClick,
} from '@chakra-ui/react';
import { useEffect, useMemo, useState, useRef } from 'react';
import {
  UseFormRegister,
  UseFormSetValue,
  UseFormGetValues,
  UseFormSetFocus,
  UseFormTrigger,
  UseFormSetError,
  UseFormClearErrors,
} from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'next-i18next';

import { clearStorage, debounce, getStorage } from 'src/commons';
import { IconStatus } from 'src/components/LoginStatusInput';
import { FormSchema } from 'src/hooks/useFormValidation';
import { useStore } from 'src/business/store';

type Props = {
  error?: string;
  isEmailReady?: boolean;
  setValue: UseFormSetValue<FormSchema>;
  getValues: UseFormGetValues<FormSchema>;
  register: UseFormRegister<FormSchema>;
  setFocus: UseFormSetFocus<FormSchema>;
  setCurrentMail: (value: string) => void;
  trigger: UseFormTrigger<FormSchema>;
  disabled?: boolean;
  setError?: UseFormSetError<FormSchema>;
  clearErrors?: UseFormClearErrors<FormSchema>;
};

export const Email = ({
  setFocus,
  getValues,
  setValue,
  register,
  error,
  setCurrentMail,
  trigger,
  disabled,
  setError,
  clearErrors,
}: Props) => {
  const { t } = useTranslation('common');
  const {
    checkoutSession,
    setState,
    email: freezedEmail,
    shouldResetEmail,
    isFormDisabled,
    isDiscountOpen,
  } = useStore();
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const client = useQueryClient();
  const emailLabel = t('inputs.email.label');
  const emailPlaceholder = t('inputs.email.placeholder');
  const ref = useRef<any>();
  const mutationCache = client.getMutationCache();
  const loginCache = mutationCache.find({ mutationKey: 'MUTATION/LOGIN' });
  const updateCheckoutEmailStatus = mutationCache.find({ mutationKey: 'MUTATION/UPDATE_CHECKOUT_EMAIL' });
  const loginStatus = loginCache?.state?.status;
  const updateCheckoutEmailStatusStatus = updateCheckoutEmailStatus?.state?.status;
  const isError = useMemo(() => {
    if (Boolean(error) || updateCheckoutEmailStatusStatus === 'error') {
      return true;
    }
    return false;
  }, [error, updateCheckoutEmailStatusStatus, checkoutSession]);

  useOutsideClick({
    ref,
    enabled: !isDiscountOpen,
    handler: () => {
      if (!email && !error) {
        setError?.('email', { message: 'Required', type: 'required' });
      }
    },
  });

  const email = getValues('email');

  useEffect(() => {
    if (isDiscountOpen && !email && error) {
      clearErrors?.('email');
    } else if (!isDiscountOpen && !email && !error) {
      setFocus('email');
      clearErrors?.('email');
    }
  }, [email, error, isDiscountOpen, clearErrors, setFocus]);

  useEffect(() => {
    const token = getStorage('token');
    if (!email && !token) {
      setFocus('email');
    }
  }, [email, setFocus]);

  useEffect(() => {
    if (isError) {
      setFocus('email');
    }
  }, [isError, setFocus]);

  useEffect(() => {
    if (email && !error && !freezedEmail && checkoutSession?.checkout) {
      setState({ email });
    } else if (error && freezedEmail) {
      setState({ email: undefined });
    }
  }, [email, error, freezedEmail, setState, checkoutSession]);

  useEffect(() => {
    if (shouldResetEmail) {
      setValue('email', '');
      setState({ shouldResetEmail: false });
      setFocus('email');
    }
  }, [shouldResetEmail, setValue, setState]);

  useEffect(() => {
    if (freezedEmail && !email) {
      setValue('email', freezedEmail);
    }
  }, [email, freezedEmail, setValue]);

  const onEmailChange = debounce(async (event: any) => {
    event.preventDefault();
    setCurrentMail('');
    setValue('email', event.target.value);
    if (event.target.value === '') {
      clearStorage('token');
      client.clear();

      setState({
        requireLoginChallenge: false,
        email: '',
        shouldResetEmail: true,
      });
      return;
    }
    await trigger('email');
  }, 1000);

  return (
    <Box pl="2" pr="2">
      <FormControl isInvalid={isError}>
        <InputGroup
          ref={!email && !isDiscountOpen ? ref : undefined}
          bgColor="white"
          borderRadius="30px"
          p="2"
          _focus={
            !isError
              ? {
                  border: '2px solid',
                  borderColor: 'purple.500',
                }
              : undefined
          }
          _active={
            !isError
              ? {
                  border: '2px solid',
                  borderColor: 'purple.500',
                }
              : undefined
          }
          _focusWithin={
            !isError
              ? {
                  border: '2px solid',
                  borderColor: 'purple.500',
                }
              : undefined
          }
          display="flex"
          alignItems="center"
          border={isError ? '2px solid' : undefined}
          borderColor={isError ? 'red.500' : undefined}
        >
          <InputLeftAddon
            bgColor="transparent"
            border="none"
            minW={{
              sm: '140px',
              md: '160px',
              base: '140px',
            }}
            fontWeight="bold"
            fontSize="small"
            pointerEvents="none"
            color={isFocused && !isError ? 'purple.500' : isError ? 'red.500' : undefined}
            // eslint-disable-next-line react/no-children-prop
            children={emailLabel.toUpperCase()}
          />
          <Input
            fontWeight="bold"
            fontSize="small"
            variant="unstyled"
            {...register('email')}
            onChange={onEmailChange}
            type="email"
            onBlur={() => setIsFocused(false)}
            onFocus={(event) => setIsFocused(Boolean(event))}
            disabled={disabled || isFormDisabled}
            placeholder={emailPlaceholder}
          />

          {loginStatus && loginStatus === 'loading' && !isError ? (
            <Avatar size="sm" bg="purple.500" icon={<IconStatus status="loading" />} />
          ) : !isError && Boolean(email?.trim()) ? (
            <Avatar size="sm" bg="purple.500" icon={<IconStatus status="success" />} />
          ) : isError ? (
            <Avatar size="sm" bg="red.500" icon={<IconStatus status="error" />} />
          ) : null}
        </InputGroup>
      </FormControl>
    </Box>
  );
};
