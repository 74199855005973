import { useToast } from '@chakra-ui/react';
import { addMinutes } from 'date-fns';
import { useTranslation } from 'next-i18next';

import { reportQueryError } from 'src/api';
import { useStore } from 'src/business/store';
import { MAX_FAILURE_COUNT } from 'src/constants';
import { useQuery } from 'src/hooks/useQuery';

export const useOrderPage = () => {
  const { setState, requestId, shouldFetchOrderPage, command_status } = useStore();
  const { t } = useTranslation();
  const toast = useToast();
  const warningProcessing = t('errors.warnings.order_processing');
  const failedOrder = t('errors.errors.order_processing_failed');

  return useQuery<any>({
    key: ['ORDER_PAGE', requestId],
    request: {
      type: 'rest',
      endpoint: `/v1/order/${requestId}/page`,
      target: 'just',
    },
    isEnabled: Boolean(requestId) && Boolean(shouldFetchOrderPage),
    reshapeData: (data) => data,
    options: {
      onSuccess: ({ data }) => {
        setState({
          orderUrl: data,
          shouldFetchOrderPage: false,
          showSuccessPage: command_status !== 'CREATED',
          setLoadingPayment: false,
        });
      },
      onError: ({ error }) => {
        setState({ setLoadingPayment: false });
        reportQueryError(error);
      },
      retry: (_failCount, error) => {
        if (error.type === 'RequestError' && error.status === 400 && _failCount < MAX_FAILURE_COUNT) {
          if (_failCount === 3) {
            toast({
              description: warningProcessing,
              status: 'info',
              position: 'top',
              duration: 5000,
              isClosable: true,
            });
          }
          return true;
        }

        setState({ setLoadingPayment: false });
        toast({
          description: failedOrder,
          status: 'warning',
          position: 'top',
          duration: 10000,
          isClosable: true,
        });
        return false;
      },
    },
  });
};
