import {
  Box,
  Button,
  Text,
  Stack,
  useDisclosure,
  useToast,
  RadioGroup,
  Radio,
  Container,
} from '@chakra-ui/react';
import { Elements } from '@stripe/react-stripe-js';
import { is, isEmpty } from 'ramda';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'next-i18next';
import { zodResolver } from '@hookform/resolvers/zod';

import { Address } from 'src/components/Address';
import { CardInput } from 'src/components/CardInput';
import { Email } from 'src/components/Email';
import { getStorage } from 'src/commons';
import { UserInfoResponse } from 'src/queries/useUserInfo';
import { ShippingRates } from 'src/components/ShippingRates';
import { useCancelOrder } from 'src/queries/useCancelOrder';
import { useFormValidation, FormSchema } from 'src/hooks/useFormValidation';
import { useOnClickOutside } from 'src/hooks/useOnClickOutside';
import { UserInput } from 'src/components/UserInput';
import { useStore } from 'src/business/store';
import { useUpdateOrder } from 'src/queries/useUpdateOrder';
import { useFetchSession } from 'src/queries/useFetchSession';
import { useCreateOrder } from 'src/queries/useCreateOrder';
import { Details } from '../Details/OrderDetail';

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_KEY as string);

const FormEdit = (props: { token: string; domain: string; toggleDiscount: () => void }) => {
  useFetchSession();
  const { schemaEdit, setCurrentMail } = useFormValidation();
  const { t } = useTranslation('common');
  const resolver = zodResolver(schemaEdit, { async: true });
  const [updateOrder] = useUpdateOrder();
  const [createOrder] = useCreateOrder();
  const [cancelOrder] = useCancelOrder();
  const { isOpen: isStripeOpen, onToggle: onOpenStripe } = useDisclosure();
  const [billingChoice, setBillingChoice] = useState<number>(1);
  const stripeRef = useRef<any>();
  const client = useQueryClient();
  const {
    requestId,
    language,
    checkoutSession,
    setState,
    email: freezedEmail,
    shouldResetEmail,
    canAutoFill,
    card_token,
    app_id,
    command_status,
    setLoadingPayment,
    paymentStatus,
    requireLoginChallenge,
  } = useStore();
  const orderRequest = client.getQueryData(['ORDER_REQUEST', requestId]) as any;
  const currentUser = client.getQueryData('USER_INFO') as UserInfoResponse | undefined;
  useOnClickOutside(stripeRef, onOpenStripe);
  const onBillingChoiceChange = (id: number) => setBillingChoice(id);
  const toast = useToast();
  const cancelLabel = t('buttons.cancel');
  const confirmLabel = t('buttons.edit');
  const sameDelivveryAddressLabel = t('info.same_delivery_address');
  const differentDeliveryAddress = t('info.different_delivery_address');
  const addDiscountButtonLabel = t('buttons.add_discount');
  const editDiscountButtonLabel = t('buttons.edit_discount');
  const tokenFromStorage = getStorage('token');

  const billingAddressChoice = useMemo(() => {
    return [
      {
        id: 1,
        label: sameDelivveryAddressLabel,
      },
      {
        id: 2,
        label: differentDeliveryAddress,
      },
    ];
  }, [differentDeliveryAddress, sameDelivveryAddressLabel]);
  const {
    handleSubmit,
    control,
    register,
    formState: { errors, isSubmitting },
    setFocus,
    setValue,
    trigger,
    getValues,
    watch,
  } = useForm<FormSchema>({
    resolver,
    reValidateMode: 'onChange',
    mode: 'onChange',
    shouldFocusError: true,
  });
  const email = watch('email');

  const billingAddress = getValues('billing_address.address1');
  const lastname = getValues('user.lastname');
  const firstname = getValues('user.firstname');
  const shippingMethod = getValues('shipping_method');
  const token = getValues('card.token');

  const storedShippingLine = useMemo(() => {
    const shippingLine = checkoutSession?.checkout?.shippingLine;
    if (shippingLine) {
      return shippingLine;
    }
    return null;
  }, [checkoutSession]);

  const canSubmit = useMemo(() => {
    const hasCheckoutEmail = checkoutSession?.checkout?.email;
    if (!hasCheckoutEmail || !storedShippingLine) {
      return true;
    }

    return false;
  }, [checkoutSession, storedShippingLine]);

  useEffect(() => {
    if (orderRequest && orderRequest?.order && canAutoFill && currentUser) {
      const order = orderRequest.order;

      const values = getValues();
      setValue('user.firstname', currentUser?.personal_informations?.first_name);
      setValue('user.lastname', currentUser?.personal_informations?.last_name);
      setValue('user.phone_number', currentUser?.phone);

      setValue('address.address1', values?.address?.address1 ?? order.shipping_address.address1);
      setValue('address.address2', values?.address?.address2 ?? order.shipping_address.address2);
      setValue('address.city', values?.address?.city ?? order.shipping_address.city);
      setValue('address.country', values?.address?.country ?? order.shipping_address.country);
      setValue('address.zip', values?.address?.zip ?? order.shipping_address.zip);
      setValue('address.country_code', values?.address?.country_code ?? order.shipping_address.country_code);
      setValue('address.province', values?.address?.province ?? order.shipping_address.province);

      setValue('billing_address.address1', values?.address?.address1 ?? order.billing_address.address1);
      setValue('billing_address.address2', values?.address?.address2 ?? order.billing_address.address2);
      setValue('billing_address.city', values?.address?.city ?? order.billing_address.city);
      setValue('billing_address.country', values?.address?.country ?? order.billing_address.country);
      setValue('billing_address.zip', values?.billing_address?.zip ?? order.billing_address.zip);
      setValue(
        'billing_address.country_code',
        values?.billing_address?.country_code ?? order.billing_address.country_code,
      );
      setValue(
        'billing_address.province',
        values?.billing_address?.province ?? order.billing_address.province,
      );
      setState({ canAutoFill: false });
    }
  }, [currentUser, setState, setValue, orderRequest, canAutoFill]);

  useEffect(() => {
    if (!shippingMethod && storedShippingLine) {
      setValue('shipping_method', storedShippingLine.handle);
    }
  }, [setValue, shippingMethod, storedShippingLine]);

  useEffect(() => {
    if (email && !errors.email && !freezedEmail && checkoutSession?.checkout) {
      setState({ email });
    } else if (errors.email && freezedEmail) {
      setState({ email: undefined });
    }
  }, [email, errors.email, freezedEmail, setState, checkoutSession, props]);

  useEffect(() => {
    if (shouldResetEmail) {
      setValue('email', '');
      setState({ shouldResetEmail: false });
    }
  }, [shouldResetEmail, setValue, setState]);

  const onSubmit = async (values: FormSchema) => {
    setState({ setLoadingPayment: true });

    if (command_status === 'CREATED') {
      await updateOrder({
        request_id: requestId as string,
        app_id: app_id as string,
        order: {
          payment_source_id: card_token?.id as string,
          checkoutId: checkoutSession?.checkout?.id,
          billing_address: values.billing_address ?? values.address,
          shipping_address: {
            ...values.address,
            first_name: firstname,
            last_name: lastname,
          },
        },
      });
    } else {
      await createOrder({
        request_id: requestId as string,
        app_id: app_id as string,
        order: {
          payment_source_id: card_token?.id as string,
          checkoutId: checkoutSession?.checkout?.id,
          billing_address: values.billing_address ?? values.address,
          shipping_address: {
            ...values.address,
            first_name: firstname,
            last_name: lastname,
          },
        },
      });
    }
  };

  const onInvalid = (errors: any) => {
    if (!isEmpty(errors)) {
      const missingFields = t('errors.commons.missing_fields');

      toast({
        description: (
          <Box display="flex" flexDirection="column">
            {Object.keys(errors)?.map((key) => {
              if (is(Object, errors[key])) {
                return Object.keys(errors[key]).map((subKey) => (
                  <Text key={subKey} fontSize="small">
                    {t(`inputs.${subKey}.label`)}
                  </Text>
                ));
              }

              return (
                <Text key={key} fontSize="small">
                  {t(`inputs.${key}.label`)}
                </Text>
              );
            })}
          </Box>
        ),
        title: missingFields,
        status: 'error',
        position: 'top',
        isClosable: true,
        duration: 10000,
      });
    }
  };

  useEffect(() => {
    if (currentUser?.email) {
      setValue('email', currentUser?.email);
    }
  }, [currentUser]);

  const onCancel = () => {
    cancelOrder({});
  };

  const isEmailReady = useMemo(() => {
    if (email) {
      return true;
    }
    return false;
  }, [email]);

  const discount = checkoutSession?.checkout?.discountApplications?.edges?.[0]?.node;

  return (
    <>
      <Container
        as="main"
        marginTop="10px"
        flex="1 1 auto"
        overflow="auto"
        paddingLeft="16px"
        display="flex"
        flexDirection="column"
      >
        {!requireLoginChallenge ? <Details /> : null}
        <form name="just-checkout" id="just-checkout" noValidate>
          <Stack spacing={4}>
            {discount ? (
              <Box display="flex" justifyContent="center">
                <Button
                  fontWeight="bold"
                  fontSize="small"
                  borderRadius="30px"
                  colorScheme="purple"
                  width="95%"
                  onClick={props.toggleDiscount}
                >
                  {editDiscountButtonLabel}
                </Button>
              </Box>
            ) : (
              <Box display="flex" justifyContent="center" width="100%">
                <Button
                  fontWeight="bold"
                  fontSize="small"
                  borderRadius="30px"
                  colorScheme="purple"
                  width="97%"
                  onClick={props.toggleDiscount}
                >
                  {addDiscountButtonLabel}
                </Button>
              </Box>
            )}
            <Email
              trigger={trigger}
              setCurrentMail={setCurrentMail}
              setFocus={setFocus}
              setValue={setValue}
              register={register}
              error={errors?.email?.message}
              getValues={getValues}
              disabled
            />
            <UserInput
              errors={errors.user}
              register={register}
              isEmailReady={isEmailReady}
              watch={watch}
              setValue={setValue}
              setFocus={setFocus}
            />
            <Address
              watch={watch}
              selector="address"
              trigger={trigger}
              control={control}
              billingChoice={billingChoice}
              // @ts-expect-error field always defined
              errors={errors.address}
              register={register}
              setValue={setValue}
              disabled={!isEmailReady}
              getValues={getValues}
            />
            <Box
              ref={isStripeOpen ? stripeRef : undefined}
              border={isStripeOpen || paymentStatus?.status == 'failed' ? '2px solid' : undefined}
              bgColor="white"
              borderColor={paymentStatus?.status == 'failed' ? 'red.500' : 'purple.500'}
              borderRadius="30px"
              style={{
                marginLeft: 5,
                marginRight: 5,
              }}
            >
              <Elements
                stripe={stripePromise}
                options={{
                  locale: language,
                  appearance: {
                    theme: 'none',
                    labels: 'floating',
                  },
                  fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Roboto' }],
                }}
              >
                <CardInput
                  watch={watch}
                  setValue={setValue}
                  isStripeOpen={isStripeOpen}
                  onOpenStripe={onOpenStripe}
                  inputError={errors.card?.owner?.message}
                  isEmailReady={isEmailReady}
                  getValues={getValues}
                  register={register}
                  token={token}
                />
              </Elements>
            </Box>
            <ShippingRates setValue={setValue} getValues={getValues} />
            <RadioGroup
              pl="2"
              pr="2"
              isDisabled={!isEmailReady}
              onChange={(value) => {
                if (parseInt(value, 10) === 1) {
                  setValue('billing_address', undefined);
                }
                onBillingChoiceChange(parseInt(value, 10));
              }}
              value={billingChoice}
            >
              <Stack spacing={2}>
                {billingAddressChoice.map((choice) => (
                  <Radio
                    colorScheme="purple"
                    key={choice.id}
                    value={choice.id}
                    size="lg"
                    maxW="100%"
                    _focus={{
                      border: '2px solid purple.500',
                    }}
                    border={choice.id !== billingChoice ? '2px solid purple' : undefined}
                  >
                    <Box display="flex" justifyContent="space-between" width="100%">
                      <Text fontSize="small" fontWeight="bold">
                        {choice.label}
                      </Text>
                    </Box>
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
            {billingChoice === 2 ? (
              <Address
                watch={watch}
                selector="billing_address"
                trigger={trigger}
                control={control}
                // @ts-expect-error field always defined
                errors={errors.billing_address}
                register={register}
                setValue={setValue}
                disabled={!isEmailReady}
                getValues={getValues}
              />
            ) : null}
          </Stack>
        </form>
      </Container>
      <Stack as="footer" bg="white" display="flex" flexBasis="50px" shadow="md" mt="2" width="100%" p="2">
        {!tokenFromStorage && (
          <Box mt="4" mb="4">
            <Text
              className="terms"
              align="center"
              fontWeight="bold"
              fontSize="small"
              dangerouslySetInnerHTML={{
                __html: t('terms'),
              }}
            />
          </Box>
        )}
        <Box display="flex" justifyContent="space-between" pb="2" pt={tokenFromStorage ? 4 : undefined}>
          <Button
            fontWeight="bold"
            fontSize="small"
            mr="1"
            bgColor="black"
            textColor="white"
            _hover={{
              bg: 'blackAlpha.800',
            }}
            borderRadius="20px"
            width="100%"
            disabled={isSubmitting}
            isLoading={isSubmitting}
            onClick={onCancel}
          >
            {cancelLabel}
          </Button>
          <Button
            fontWeight="bold"
            fontSize="small"
            width="100%"
            borderRadius="20px"
            ml="1"
            form="just-checkout"
            type={
              isSubmitting || canSubmit || !token || (billingChoice === 2 && !billingAddress)
                ? 'button'
                : 'submit'
            }
            colorScheme="purple"
            onClick={
              isSubmitting || canSubmit || !token || (billingChoice === 2 && !billingAddress)
                ? undefined
                : handleSubmit(onSubmit, onInvalid)
            }
            disabled={
              setLoadingPayment ||
              isSubmitting ||
              canSubmit ||
              !token ||
              (billingChoice === 2 && !billingAddress)
            }
            isLoading={isSubmitting}
          >
            {confirmLabel}
          </Button>
        </Box>
      </Stack>
    </>
  );
};

export default FormEdit;
