import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { Spinner } from '@chakra-ui/react';

export type Props = {
  status: 'idle' | 'loading' | 'success' | 'error';
};

export const IconStatus = ({ status }: Props) => {
  if (status === 'loading') {
    return <Spinner thickness="4px" speed="0.65s" emptyColor="purple.100" color="purple.500" />;
  }

  if (status === 'success') {
    return <CheckIcon color="white" />;
  }

  if (status === 'error') {
    return <CloseIcon color="white" />;
  }

  return null;
};
