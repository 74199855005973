import { QueryState, reportQueryError } from 'src/api';
import { useStore } from 'src/business/store';
import { useQuery } from 'src/hooks/useQuery';

export const usePolicy = (): QueryState<{ url: string }> => {
  const { api_token, api_domain, app_id } = useStore();

  return useQuery<{ url: string }>({
    key: ['POLICY'],
    request: {
      type: 'rest',
      endpoint: `/v1/seller/${app_id}/tos`,
      target: 'just',
    },
    isEnabled: Boolean(api_token),
    reshapeData: (data) => data,
    options: {
      onError: ({ error }) => {
        reportQueryError(error);
      },
    },
  });
};
