import { useToast } from '@chakra-ui/react';
import { useEffect } from 'react';

import { HttpStatusCode, QueryError as QueryErrorType } from 'src/api';
import { useQueryError } from 'src/components/QueryError/useQueryError';

type QueryErrorProps<TError> = {
  queryError: QueryErrorType<TError>;
  translations: {
    serverError?: string;
    networkError?: string;
    requestError?: (reason: TError, httpStatusCode: HttpStatusCode) => string | undefined;
  };
};

export const QueryError = <TError,>({ queryError, translations }: QueryErrorProps<TError>) => {
  const toast = useToast();
  const errorTitle = useQueryError<TError>(translations)(queryError);

  useEffect(() => {
    if (errorTitle) {
      toast({
        description: errorTitle,
        status: 'error',
        isClosable: true,
        position: 'top',
        duration: 3000,
      });
    }
  }, [errorTitle, toast]);

  return null;
};
