import { isAfter } from 'date-fns';

import { QueryRequestError, reportQueryError } from 'src/api';
import { useStore } from 'src/business/store';
import { clearStorage, getStorage } from 'src/commons';
import { useMutation } from 'src/hooks/useMutation';
import { useUpdateCheckoutEmail } from 'src/queries/useUpdateCheckoutEmail';
import { JustApiToken } from 'src/types';
import { setInternalApiAuthHeaders } from 'src/api/axios';
import { useRefreshToken } from 'src/queries/useRefreshToken';

export type LoginPayload = {
  client_secret: string;
  client_id: string;
  email: string;
  state: string;
  code_challenge: string;
  domain: string;
  token: string;
  updateCheckoutEmail?: boolean;
};

export type LoginResponse = {
  expires_at: string;
  session_id: string;
  user_id: string;
};

export const useLogin = () => {
  const { setState, checkoutSession } = useStore();
  const [updateCheckoutEmail] = useUpdateCheckoutEmail();
  const [refreshToken] = useRefreshToken();

  return useMutation<LoginPayload, LoginResponse>({
    request: {
      type: 'rest',
      method: 'post',
      endpoint: '/v1/auth/login',
      target: 'just',
    },
    reshapeData: (data) => data,
    options: {
      mutationKey: 'MUTATION/LOGIN',
      throwOnError: true,
      onError: ({ payload, error }) => {
        clearStorage('token');
        setInternalApiAuthHeaders('');
        if ((error as QueryRequestError<unknown>).status === 404) {
          setState({
            shouldRegister: true,
            loginSession: undefined,
            requireLoginChallenge: false,
            setLoadingPayment: false,
            isFormDisabled: false,
          });

          if (payload.updateCheckoutEmail) {
            return updateCheckoutEmail({
              variables: {
                email: payload.email,
                checkoutId: checkoutSession?.checkout?.id as string,
              },
              domain: payload.domain,
              token: payload.token,
            });
          }
        } else {
          setState({ isFormDisabled: false });
        }
        reportQueryError(error);
      },
      // @ts-expect-error property is always defined
      onMutate: ({ payload }) => {
        clearStorage('token');
        setInternalApiAuthHeaders('');
        setState({
          shouldResetCard: true,
          isFormDisabled: true,
          checkoutSession: {
            ...(checkoutSession ?? {}),
            // @ts-expect-error property is always defined
            checkout: {
              ...(checkoutSession?.checkout ?? {}),
              shippingLine: undefined,
              availableShippingRates: undefined,
            },
          },
        });
      },
      onSuccess: ({ payload, data }) => {
        const tokenFromStorage = getStorage<JustApiToken>('token');
        const isValid = tokenFromStorage?.expires_at;
        const validToken = isValid ? isAfter(new Date(data.expires_at), new Date()) : 0;

        setState({
          isFormDisabled: false,
          shouldRegister: false,
          loginSession: data,
          requireLoginChallenge: !tokenFromStorage,
          canAutoFill: Boolean(tokenFromStorage) && Boolean(validToken),
          shouldFetchUserInfo: Boolean(tokenFromStorage) && Boolean(validToken),
        });

        if (validToken) {
          setInternalApiAuthHeaders(tokenFromStorage?.access_token as string);
        }

        if (!validToken && tokenFromStorage) {
          refreshToken({
            user_id: data.user_id,
            refresh_token: tokenFromStorage?.refresh_token as string,
          });
        }
        if (payload.updateCheckoutEmail) {
          return updateCheckoutEmail({
            variables: {
              email: payload.email,
              checkoutId: checkoutSession?.checkout?.id as string,
            },
            domain: payload.domain,
            token: payload.token,
          });
        }
      },
    },
  });
};

export const useLoginMutation = () => {
  const [login, response, reset] = useLogin();
  const { api_domain, api_token } = useStore();

  const onLogin = (email: string, updateCheckoutEmail = true) => {
    login({
      client_secret: process.env.NEXT_PUBLIC_API_CLIENT_SECRET as string,
      client_id: 'web',
      email: email as string,
      state: 'LOGIN_STATE',
      code_challenge: 'LOGIN_CHALLENGE',
      domain: api_domain as string,
      token: api_token as string,
      updateCheckoutEmail,
    });
  };

  return {
    onLogin,
    ...response,
    reset,
  };
};
