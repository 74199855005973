import { reportQueryError } from 'src/api';
import { useStore } from 'src/business/store';
import { useMutation } from 'src/hooks/useMutation';

import { useUpdateCheckoutEmail } from 'src/queries/useUpdateCheckoutEmail';
import { MoneyV2 } from 'src/types/checkout';

import type { UpdateCheckoutShippingLine } from 'src/types/update-shipping-line-checkout';
import type { CheckoutShippingLineUpdateResponse } from 'src/types/checkout-response';

export const useUpdateCheckoutShippingLine = () => {
  const { setState, checkoutSession, email } = useStore();
  const [updateEmail] = useUpdateCheckoutEmail();
  return useMutation<
    UpdateCheckoutShippingLine,
    CheckoutShippingLineUpdateResponse,
    CheckoutShippingLineUpdateResponse
  >({
    request: {
      type: 'rest',
      method: 'post',
      endpoint: '/api/update-checkout-shipping-lines',
      target: 'internal',
    },
    reshapeData: (data) => data,
    options: {
      mutationKey: 'MUTATION/SHIPPING_LINE',
      throwOnError: true,
      onError: ({ error }) => {
        reportQueryError(error);
      },
      onSuccess: ({ data, payload }) => {
        setState({
          checkoutSession: {
            ...checkoutSession,
            // @ts-expect-error property is always defined
            checkout: {
              ...(checkoutSession?.checkout ?? {}),
              email: checkoutSession?.checkout?.email ?? email,
              shippingLine: data?.checkout?.shippingLine,
              totalPriceV2: data?.checkout?.totalPriceV2 as MoneyV2,
              paymentDueV2: data?.checkout?.paymentDueV2 as MoneyV2,
            },
          },
        });

        if (!checkoutSession?.checkout?.email || !data?.checkout?.email) {
          updateEmail({
            variables: {
              checkoutId: checkoutSession?.checkout?.id as string,
              email: email as string,
            },
            domain: payload?.domain,
            token: payload?.token,
          });
        }
      },
    },
  });
};
